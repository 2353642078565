import { usePatchApiAdminUserManagerChangeUserPassword } from "@/API";
import { Button, Group, Modal, TextInput } from "@mantine/core"
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

export function ChangeUserPassword(props: { userId: string }) {
    const [loading, setLoading] = useState(false)
    const [opened, { open, close: close }] = useDisclosure(false);
    const ChangePassword_Mutation = usePatchApiAdminUserManagerChangeUserPassword()
    const form = useForm<{ password: string }>({
        initialValues: {
            password: ""
        },
    });
    return <>
        <Button onClick={open} w={150} >修改密碼</Button>
        <Modal opened={opened} onClose={close} title="修改密碼">
            <form onSubmit={form.onSubmit(async (values) => {
                setLoading(true)
                try {
                    await ChangePassword_Mutation.mutateAsync({
                        data: {
                            userId: props.userId,
                            password: values.password
                        }
                    })
                    notifications.show({ message: "修改成功" })
                    close()
                } catch (e) {
                    notifications.show({ message: "修改失敗", color: "red" })
                }
                setLoading(false)
            })}>
                <TextInput
                    label="新密碼"
                    {...form.getInputProps('password')}
                />
                <Group justify="flex-end" mt="md">
                    <Button loading={loading} type="submit">修改</Button>
                </Group>
            </form>
        </Modal>
    </>
}