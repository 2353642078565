import { useGetApiAdminAppKeyList, useDeleteApiAdminAppKeyDelete } from "@/API"
import { useDisclosure } from "@mantine/hooks";
import { ActionIcon, Box, Button, CopyButton, Group, Text, Tooltip } from "@mantine/core";
import { DataTable } from "mantine-datatable";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { useQueryClient } from "@tanstack/react-query";
import { useOs } from '@mantine/hooks';
import { useState } from "react";
import { AdminCreateAppKeyDialog } from "./AdminCreateAppKeyDialog";
import { AdminEditAppKeyDialog } from "./AdminEditAppKeyDialog";

export function AdminAppKeyManager(props: { userId: string }) {
    const queryClient = useQueryClient()
    const os = useOs();
    const [createKey_opened, { open: createKey_open, close: createKey_close }] = useDisclosure(false);
    const [editKey_opened, { open: editKey_open, close: editKey_close }] = useDisclosure(false);
    const [selectedKeyId, setSelectedKeyId] = useState("")
    var list = useGetApiAdminAppKeyList({ userId: props.userId })
    var deleteKey_Mutation = useDeleteApiAdminAppKeyDelete()

    return <>
        <Button w={150} onClick={() => createKey_open()}>
            新增授權金鑰
        </Button>
        {list.isSuccess && list.data.data.length > 0 && <>
            <DataTable
                withTableBorder
                columns={[
                    {
                        accessor: 'value',
                        title: "授權金鑰",
                        render: (data) => (
                            <Group>
                                {data.keyId}
                                <CopyButton value={data.keyId!}>
                                    {({ copied, copy }) => (
                                        <Button size="compact-xs" onClick={() => {
                                            copy()
                                            notifications.show({ message: "已複製到剪貼簿", autoClose: 2000 })
                                        }}>
                                            {copied ? '已複製' : '複製'}
                                        </Button>
                                    )}
                                </CopyButton>
                                {(os == "windows" || os == "android") && <Button size="compact-xs" onClick={() => {
                                    window.location.assign(`cakescribe://?appkey=${data.keyId!}`)
                                }}>
                                    開啟APP
                                </Button>}
                            </Group>)
                    },
                    {
                        accessor: "comment", title: "註記", render: (data) => <Tooltip style={{ whiteSpace: "pre-wrap" }} label={data.comment} multiline><Text variant="text" w={100} fw={300} truncate="end">{data.comment}</Text></Tooltip>
                    },
                    {
                        accessor: 'actions',
                        title: <Box mr={6}>操作</Box>,
                        textAlign: 'right',
                        render: (data) => (
                            <Group gap={4} justify="right" wrap="nowrap">
                                <ActionIcon
                                    size="sm"
                                    variant="subtle"
                                    onClick={() => {
                                        if (data.keyId) {
                                            setSelectedKeyId(data.keyId)
                                            editKey_open()
                                        }
                                    }}
                                >
                                    <IconEdit size={16} />
                                </ActionIcon>
                                <ActionIcon
                                    size="sm"
                                    variant="subtle"
                                    color="red"
                                    onClick={() => {
                                        modals.openConfirmModal({
                                            title: '刪除金鑰',
                                            children: (
                                                <Text size="sm">
                                                    是否要刪除金鑰?
                                                </Text>
                                            ),
                                            labels: { confirm: '刪除', cancel: '取消' },
                                            confirmProps: { color: 'red' },
                                            onConfirm: async () => {
                                                try {
                                                    if (data.keyId) {
                                                        await deleteKey_Mutation.mutateAsync({ params: { userId: props.userId, keyId: data.keyId } })
                                                        queryClient.invalidateQueries({ queryKey: list.queryKey })
                                                        notifications.show({ message: "刪除成功" })
                                                    }
                                                } catch (e) {
                                                    notifications.show({ message: "刪除失敗", color: "red" })
                                                }
                                            },
                                        });
                                    }}
                                >
                                    <IconTrash size={16} />
                                </ActionIcon>
                            </Group>
                        ),
                    },
                ]}
                records={list.data.data.map(x => { return { id: x.keyId, ...x } })}
            />
        </>}
        <AdminCreateAppKeyDialog userId={props.userId} opened={createKey_opened} onClose={() => createKey_close()} />
        {selectedKeyId && <AdminEditAppKeyDialog userId={props.userId} keyId={selectedKeyId} opened={editKey_opened} onClose={() => editKey_close()} />}
    </>
}
