import * as R from "remeda"
import { AudioToTextRecordAvergeViewModel } from "@/API";
import { Table } from "@mantine/core";

export function AudioToTextRecordAvergeViewer(props: { data: AudioToTextRecordAvergeViewModel }) {
    return <>
        <Table>
            <Table.Thead>
                <Table.Tr>
                    <Table.Th>平均音檔時長度(秒)</Table.Th>
                    <Table.Th>平均回應時間(秒)</Table.Th>
                    <Table.Th>平均每秒音檔的處理時間(秒)</Table.Th>
                    <Table.Th>平均錯誤率(%)</Table.Th>
                </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
                <Table.Tr >
                    <Table.Td>{R.round(props.data.averageDuration ?? -1, 1)}</Table.Td>
                    <Table.Td>{R.round(props.data.averageReponseTime ?? -1, 1)}</Table.Td>
                    <Table.Td>{R.round(props.data.averageReponseTimePerDurationSecond ?? -1, 1)}</Table.Td>
                    <Table.Td>{R.round(props.data.averageInvalidCountPercentage ?? -1 * 100, 1)}%</Table.Td>
                </Table.Tr>
            </Table.Tbody>
        </Table>
    </>
}