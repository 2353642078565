import { SttUsageAdminIndex } from '@/Admin/SttUsage'
import { createFileRoute } from '@tanstack/react-router'

type TTSUsageSearch = {
  userId?: string
}
export const Route = createFileRoute('/admin/TTSUsage')({
  component: SttUsageAdminIndex,
  validateSearch: (search: Record<string, unknown>): TTSUsageSearch => {
    return {
      userId: search.userId as string
    }
  },
})