import * as R from "remeda";
import { PostApiAdminServiceMetricSearchParams, ServiceMetric, useGetApiAdminServiceMetricListSource, usePostApiAdminServiceMetricSearch } from "@/API";
import { BarChart } from "@mantine/charts";
import { Box, Button, Group, Loader, Select, Text } from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import dayjs from "dayjs";
import { useState } from "react";
import { ServiceMetricListViewer } from "./ServiceMetricListViewer";


export function ServiceMetricPage() {
    var [loading, setLoading] = useState(false)
    const [data, setData] = useState<Record<string, any>[]>([])
    const sources = useGetApiAdminServiceMetricListSource()
    const search_Mutation = usePostApiAdminServiceMetricSearch()
    const form = useForm<PostApiAdminServiceMetricSearchParams>({
        initialValues: {
            startTime: dayjs().add(-1, "hour").toISOString(),
            endTime: dayjs().toISOString(),
        },
    });
    return <Box>
        {sources.isSuccess && sources.data?.data ?
            <form>
                <Select
                    label="名稱"
                    data={[...R.unique(sources.data?.data.map(x => x!)) ?? ""]}
                    value={form.getValues().source}
                    onChange={x => form.setFieldValue("source", x!)}
                />
                <DateTimePicker
                    label="開始時間"
                    locale="zh-tw"
                    valueFormat="YYYY/MM/DD HH:mm"
                    value={dayjs(form.getValues().startTime).toDate()}
                    onChange={x => form.setFieldValue("startTime", x?.toISOString())}
                />
                <DateTimePicker
                    rightSectionWidth={100}
                    rightSectionProps={{ style: { justifyContent: "flex-end" } }}
                    rightSection={<Button onClick={() => form.setFieldValue("endTime", new Date().toISOString())}>當前時間</Button>}
                    label="結束時間"
                    locale="zh-tw"
                    valueFormat="YYYY/MM/DD HH:mm"
                    value={dayjs(form.getValues().endTime).toDate()}
                    onChange={x => form.setFieldValue("endTime", x?.toISOString())}
                />
                <Group justify="flex-start" mt="md">
                    <Button loading={loading} onClick={async () => {
                        setLoading(true)
                        try {
                            var result = await search_Mutation.mutateAsync({
                                params: {
                                    ...form.getValues()
                                }
                            })
                            setData(result.data?.map(x => {
                                return {
                                    time: dayjs(x.timestamp).toDate().toLocaleString(),
                                    [x.metadata?.source!]: x.remainingRequests
                                }
                            }) ?? [])
                        } catch (e) {
                            notifications.show({ message: "資料錯誤" })
                        }
                        setLoading(false)
                    }}>搜尋</Button>
                    <Button loading={loading} onClick={async () => {
                        setLoading(true)
                        try {
                            var result = await search_Mutation.mutateAsync({
                                params: {
                                    ...form.getValues()
                                }
                            })
                            modals.open({
                                title: form.getValues().source,
                                size: "lg",
                                children: (
                                    <ServiceMetricListViewer data={result.data} />
                                ),
                            });
                        } catch (e) {
                            notifications.show({ message: "資料錯誤" })
                        }
                        setLoading(false)
                    }}>搜尋詳細資料</Button>
                </Group>
            </form>
            : <Loader m={10} />}
        {data.length > 0 &&
            <BarChart
                h={300}
                data={data}
                dataKey="time"
                series={[
                    { name: form.getValues().source!, color: 'violet.6' },
                ]}
                tickLine="y"
            />}
    </Box>
}