import { Button } from '@mantine/core';
import { NodeViewWrapper, NodeViewProps } from '@tiptap/react';
import { useAudioRecordStateStore } from '../../AudioRecordStateStore';
import { SecondToString } from '@/Helper/string';

const MyComponent = (props: NodeViewProps) => {
    const audioRecordStateStore = useAudioRecordStateStore()

    return (
        <NodeViewWrapper className="AudioRecordTime">
            <Button size='compact-sm' variant="light" onClick={() => {
                audioRecordStateStore.SetTargetTime(props.node.attrs.time)
            }}>
                {SecondToString(props.node.attrs.time)}
            </Button>
        </NodeViewWrapper>
    );
};

export default MyComponent;
