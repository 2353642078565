import { getApiAppKeyInfo, getGetApiAdminAppKeyListQueryKey, usePutApiAdminAppKeyEdit } from "@/API";
import { Button, Group, Loader, Modal, Textarea } from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";

export function AdminEditAppKeyDialog(props: { userId: string, keyId: string, opened: boolean, onClose: () => void }) {
    const queryClient = useQueryClient()
    const [isloaded, setIsloaded] = useState(false)
    const EditKey_Mutation = usePutApiAdminAppKeyEdit()
    const form = useForm<{ comment: string }>({
        initialValues: {
            comment: '',
        },
    });
    useEffect(() => {
        if (props.opened) {
            setIsloaded(false)
            form.reset()
            getApiAppKeyInfo({ keyId: props.keyId }).then(x => {
                form.setValues({ comment: x.data.comment! })
                setIsloaded(true)
            })
        }
    }, [props.opened])
    return <>
        <Modal opened={props.opened} onClose={props.onClose} title="修改App金鑰資訊">
            {isloaded ?
                <form onSubmit={form.onSubmit(async (values) => {
                    try {
                        await EditKey_Mutation.mutateAsync({ params: { userId: props.userId, keyId: props.keyId }, data: { comment: values.comment } })
                        queryClient.invalidateQueries({ queryKey: getGetApiAdminAppKeyListQueryKey() })
                        notifications.show({ message: "修改成功" })
                        props.onClose()
                    } catch (e) {
                        notifications.show({ message: "修改失敗", color: "red" })
                    }
                })}>
                    <Textarea
                        label="說明"
                        rows={5}
                        placeholder="notes"
                        {...form.getInputProps('comment')}
                    />
                    <Group justify="flex-end" mt="md">
                        <Button type="submit">修改</Button>
                    </Group>
                </form> : <Loader />}
        </Modal>
    </>
}