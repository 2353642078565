import { AudioRecord } from "@/API";
import { Link } from '@tanstack/react-router'
import { Box, Divider, Group, Text } from "@mantine/core";
import { SecondToString } from "@/Helper/string";
import { HighlightText } from "../../Components/HighlightText";

export function AudioRecordSearchCard(props: { audioRecord: AudioRecord }) {
    const textColor = "#868e96"
    return <Box>
        <Link style={{ fontSize: 20 }} to="/workspace/$workspaceId/audioRecord/$audioRecordId" params={{ workspaceId: props.audioRecord.workspaceId ?? "", audioRecordId: props.audioRecord.id ?? "" }}>
            <HighlightText text={props.audioRecord.name ?? ""} />
        </Link>
        {props.audioRecord.recordEntries?.filter(x => x.text?.includes("<em>") || x.speakers?.join()?.includes("<em>")).slice(0, 5).map((recordEntry, idx) => {
            return <Box key={`recordEntry_${idx}`}>
                <Group align="center" justify="start" gap={0} >
                    <Text c={textColor}>
                        {SecondToString(Math.max(recordEntry.time ?? 0, 0))}~{SecondToString((recordEntry.time ?? 0) + (recordEntry.duration ?? 0))}
                    </Text>
                    <span style={{ marginLeft: '5px' }}>
                        <HighlightText text={recordEntry.speakers?.join(', ') ?? ""} style={{ color: textColor, fontSize: "14px" }} />
                    </span>
                </Group>
                <Group gap={10} ml="2.5px">
                    <Divider orientation="vertical" size="3px" pos="relative" left={3} />
                    <Group gap={0} flex={1}>
                        <HighlightText text={recordEntry.text ?? ""} style={{ color: textColor, fontSize: "14px" }} />
                    </Group>
                </Group>

                {/* <p>{entry.text}</p><br /> */}
            </Box>
        })}
    </Box>
}