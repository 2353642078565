import * as R from "remeda";
import { v4 as uuidv4 } from 'uuid';
import { useImmer } from "use-immer";
import { AudioRecordEntry, DataUpdateMeta, useDeleteApiAudioRecordClearAllEntries, useDeleteApiAudioRecordDeleteAudio, useDeleteApiAudioRecordDeleteEntry, useGetApiAudioRecordGet, useGetApiAudioRecordGetReference, useGetApiAudioRecordJobGetTranscriptionJob, useGetApiAudioRecordJobGetTranslationJob, usePostApiAudioRecordJobCreateTranscriptionJob, usePutApiAudioRecordUpdateEntry } from "@/API"
import * as signalR from "@microsoft/signalr";
import { useParams, useSearch } from "@tanstack/react-router"
import { RecordEntryCard } from "./RecordEntryCard"
import { useCallback, useMemo, useRef, useState } from "react";
import { ActionIcon, Box, Button, Text, Center, Flex, LoadingOverlay, Menu, Modal, rem, Loader, Tooltip, Transition, Group } from "@mantine/core";
import { VirtualList, VirtualListHandle } from "@/Components/VirtualList";
import { AudioPlayer, AudioPlayerHandle } from "@/Components/AudioPlayer";
import { useDisclosure, useDocumentVisibility, useThrottledCallback } from "@mantine/hooks";
import { EditRecordEntryForm } from "./EditRecordEntryForm";
import { EditRecordEntrySpeakerForm } from "./EditRecordEntrySpeakerForm";
import { useQueryClient } from "@tanstack/react-query";
import { notifications } from "@mantine/notifications";
import { useEffect } from "react";
import { UseSignalrState } from "@/Helper/UseSignalrState";
import { SignalrStateBadge } from "@/Components/SignalrStateBadge";
import { UploadAudioRecordFileButton } from "./UploadAudioRecordFileButton";
import { CreateAudioTranscriptionJobButton, CreateAudioTranscriptionJobForm } from "./AudioTranscriptionJob/CreateAudioTranscriptionJobButton";
import { IconArrowBarToDown, IconDots, IconDownload, IconLanguageHiragana, IconRepeat, IconTransform, IconTrash } from "@tabler/icons-react";
import { AudioTranscriptionJobInfo } from "./AudioTranscriptionJob/AudioTranscriptionJobInfo";
import { modals } from "@mantine/modals";
import { UseWorkspaceAuth } from "@/Workspaces/UseWorkspaceAuth";
import { TextSaveToFile, UrlSaveToFile } from "@/Helper/SaveToFile";
import { AudioRecordToSrt } from "@/Helper/AudioRecordHelper";
import { GetAudioRecordAudioUrl } from "@/APIURL";
import { EditRecordAllSpeakersForm } from "./EditRecordAllSpeakersForm";
import { CreateAudioTranslationJobForm } from "./AudioTranslationJob/CreateAudioTranslationJobButton";
import { AudioTranslationJobInfo } from "./AudioTranslationJob/AudioTranslationJobInfo";
import { TranslatedLanguageSelect } from "./TranslatedLanguageSelect";
import { useAudioRecordStateStore } from "../AudioRecordStateStore";
import { MergeAudioRecordEntry } from "./AudioRecordEntryHelper";

export function AudioRecordItem() {
    const { workspaceId, audioRecordId } = useParams({ from: "/workspace/$workspaceId/audioRecord/$audioRecordId" })
    const { time } = useSearch({ from: "/workspace/$workspaceId/audioRecord/$audioRecordId" })
    const audioRecordStateStore = useAudioRecordStateStore()
    const throttledSetStorePlayerTime = useThrottledCallback((value: number) => audioRecordStateStore.SetPlayerTime(value), 2000);
    const queryClient = useQueryClient()
    const workspaceEditorAuth = UseWorkspaceAuth(workspaceId, "Editor")
    const virtualListRef = useRef<VirtualListHandle>(null)
    const [showToBottom, setShowToBottom] = useState(true)
    const [translatedLanguage, setTranslatedLanguage] = useState<string | null>(null)
    const audioRecordRef = useGetApiAudioRecordGetReference({ workspaceId: workspaceId, audioRecordId: audioRecordId })
    const audioRecord = useGetApiAudioRecordGet({ workspaceId: workspaceId, audioRecordId: audioRecordId })
    const clearAudioRecord_Mutation = useDeleteApiAudioRecordClearAllEntries()
    const [clearAudioRecordLoading, setClearAudioRecordLoading] = useState(false)
    const deleteAudio_Mutation = useDeleteApiAudioRecordDeleteAudio()
    const [deleteAudioLoading, setDeleteAudioLoading] = useState(false)
    const transcriptionJob = useGetApiAudioRecordJobGetTranscriptionJob({ workspaceId: workspaceId, audioRecordId: audioRecordId })
    const translationJob = useGetApiAudioRecordJobGetTranslationJob({ workspaceId: workspaceId, audioRecordId: audioRecordId })
    const recordEntries = useMemo(() => {
        return audioRecord.data?.data.recordEntries?.sort((a, b) => (a.time ?? 0) - (b.time ?? 0)) ?? []
    }, [audioRecord.data?.data])
    var translatedTexts = useMemo(() => {
        return R.unique(R.flatMap(recordEntries, x => x.translatedTexts).map(x => x?.language)).filter(x => !!x)
    }, [recordEntries])
    var speakers = useMemo(() => {
        return R.unique(R.flatMap(audioRecord.data?.data.recordEntries ?? [], x => x.speakers).map(x => x ?? "")).filter(x => !!x)
    }, [audioRecord.data?.data.recordEntries])
    useEffect(() => {
        if (!showToBottom) virtualListRef.current?.ScrollToBottom();
    }, [recordEntries.length])
    const [currentPlayingTime, setCurrentPlayingTime] = useState(0)
    const audioPlayer = useRef<AudioPlayerHandle>(null)

    const [selectedRecordEntry, setSelectedRecordEntry] = useState<AudioRecordEntry>()
    const updateEntry_Mutation = usePutApiAudioRecordUpdateEntry()
    const [updateEntryLoading, setUpdateEntryLoading] = useImmer<Set<string>>(new Set([]))
    const deleteEntry_Mutation = useDeleteApiAudioRecordDeleteEntry()
    const [hubConnection, setHubConnection] = useState<signalR.HubConnection | null>(null);
    const { SetConnection, connectionState } = UseSignalrState()

    const [updateEntry_opened, { open: updateEntry_open, close: updateEntry_close }] = useDisclosure(false);
    const [addEntry_opened, { open: addEntry_open, close: addEntry_close }] = useDisclosure(false);
    const [updateEntrySpeakers_opened, { open: updateEntrySpeakers_open, close: updateEntrySpeakers_close }] = useDisclosure(false);
    const [transcriptionJobInfo_opened, { open: transcriptionJobInfo_open, close: transcriptionJobInfo_close }] = useDisclosure(false);
    const [translationJobInfo_opened, { open: translationJobInfo_open, close: translationJobInfo_close }] = useDisclosure(false);
    const [createTranscriptionJobLoading, setCreateTranscriptionJobLoading] = useState(false);
    const [createTranscriptionJob_opened, { open: createTranscriptionJob_open, close: createTranscriptionJob_close }] = useDisclosure(false);
    const [createTranslationJobLoading, setCreateTranslationJobLoading] = useState(false);
    const [createTranslationJob_opened, { open: createTranslationJob_open, close: createTranslationJob_close }] = useDisclosure(false);

    useEffect(() => {
        if (audioRecordStateStore.targetTime >= 0) {
            toTime(audioRecordStateStore.targetTime)
            audioRecordStateStore.SetTargetTime(-1)
        }
    }, [audioRecordStateStore.targetTime])
    useEffect(() => {
        if (time != undefined) toTime(time)
    }, [time, recordEntries.length])
    const toTime = useCallback((t: number) => {
        if (recordEntries.length > 0) {
            for (var i = 0; i < recordEntries.length; i++) {
                if ((recordEntries[i].time ?? 0) + (recordEntries[i].duration ?? 0) > t) {
                    virtualListRef.current?.ScrollToItem(i, "center")
                    break;
                }
            }
            audioPlayer.current?.Seek(t)
        }
    }, [virtualListRef.current, audioPlayer.current, recordEntries])
    useEffect(() => {
        const connection = new signalR.HubConnectionBuilder()
            .withUrl("/audioRecordHub")
            .withAutomaticReconnect()
            .withStatefulReconnect()
            .build();
        SetConnection(connection)
        connection.on("OnUpdateEntry", async (meta: DataUpdateMeta, entry: AudioRecordEntry) => {
            await queryClient.invalidateQueries({ queryKey: audioRecord.queryKey })
        })
        connection.on("OnDeleteEntry", (meta: DataUpdateMeta, entryId: string) => {
            queryClient.invalidateQueries({ queryKey: audioRecord.queryKey })
        })
        connection.on("OnUpdate", (meta: DataUpdateMeta) => {
            queryClient.invalidateQueries({ queryKey: audioRecord.queryKey })
        })
        connection
            .start()
            .then(async () => {
                console.log('SignalR Connected!');
                var success = await connection.invoke<boolean>("ListenAudioRecordEvent", workspaceId, audioRecordId)
                if (success)
                    console.log("ListenAudioRecordEvent success")
                else
                    console.warn("ListenAudioRecordEvent fail")
            })
            .catch((err) => {
                console.log('SignalR Connection Error: ', err);
            });
        setHubConnection(connection)
        return () => {
            if (connection) {
                connection
                    .stop()
                    .then(() => {
                        console.log('SignalR Disconnected!');
                    })
                    .catch((err) => {
                        console.log('SignalR Disconnection Error: ', err);
                    });
            }
        };
    }, [])
    const transcriptionJobExist = useMemo(() => {
        return !!transcriptionJob.data?.data
    }, [transcriptionJob.data, transcriptionJob.status])
    const translationJobExist = useMemo(() => {
        return !!translationJob.data?.data
    }, [translationJob.data, translationJob.status])
    useEffect(() => {
        if (!transcriptionJobExist) transcriptionJobInfo_close();
    }, [transcriptionJobExist])
    useEffect(() => {
        if (!translationJobExist) translationJobInfo_close();
    }, [translationJobExist])
    const recordExist = useMemo(() => {
        return recordEntries.length > 0
    }, [audioRecord.data])
    const audioExist = useMemo(() => {
        return audioRecordRef.data?.data.audioIds && audioRecordRef.data.data.audioIds.length > 0;
    }, [audioRecordRef.data])
    useEffect(() => {
        if (recordEntries.length > 0) {
            if (transcriptionJob.data?.data)
                queryClient.invalidateQueries({ queryKey: transcriptionJob.queryKey })
            if (translationJob.data?.data)
                queryClient.invalidateQueries({ queryKey: translationJob.queryKey })
        }
    }, [recordEntries])

    return <Box h="100%" pos="relative">
        <LoadingOverlay
            visible={audioRecord.isFetching}
            zIndex={1000}
            loaderProps={{ type: 'bars' }}
        />
        <SignalrStateBadge style={{ zIndex: 1 }} state={connectionState} pos="absolute" right={20} top={0} />
        <Flex align="center">
            {workspaceEditorAuth &&
                <Menu withinPortal position="bottom-start" shadow="sm">
                    <Menu.Target>
                        <ActionIcon variant="subtle" color="gray">
                            <IconDots style={{ width: rem(16), height: rem(16) }} />
                        </ActionIcon>
                    </Menu.Target>
                    <Menu.Dropdown>
                        <Menu.Item
                            disabled={!transcriptionJobExist}
                            leftSection={<IconTransform style={{ width: rem(14), height: rem(14) }} />}
                            color="var(--mantine-primary-color-filled)"
                            onClick={transcriptionJobInfo_open}
                        >
                            轉錄資訊
                        </Menu.Item>
                        <Menu.Item
                            disabled={!translationJobExist}
                            leftSection={<IconLanguageHiragana style={{ width: rem(14), height: rem(14) }} />}
                            color="var(--mantine-primary-color-filled)"
                            onClick={translationJobInfo_open}
                        >
                            翻譯資訊
                        </Menu.Item>
                        <Menu.Item
                            disabled={transcriptionJobExist || !recordExist || !audioExist}
                            leftSection={<IconRepeat style={{ width: rem(14), height: rem(14) }} />}
                            color="green"
                            onClick={() => {
                                createTranscriptionJob_open()
                            }}
                        >
                            重新轉錄
                        </Menu.Item>
                        <Menu.Item
                            disabled={transcriptionJobExist || !recordExist}
                            leftSection={<IconTrash style={{ width: rem(14), height: rem(14) }} />}
                            color="red"
                            onClick={() => {
                                modals.openConfirmModal({
                                    title: '清空所有轉錄紀錄',
                                    centered: true,
                                    children: (
                                        <Text size="sm">
                                            是否要清空所有轉錄紀錄
                                        </Text>
                                    ),
                                    labels: { confirm: '清空', cancel: "取消" },
                                    confirmProps: { color: 'red', loading: clearAudioRecordLoading },
                                    onConfirm: async () => {
                                        setClearAudioRecordLoading(true)
                                        try {
                                            await clearAudioRecord_Mutation.mutateAsync({
                                                params: {
                                                    workspaceId: workspaceId, audioRecordId: audioRecordId
                                                }
                                            })
                                            queryClient.invalidateQueries({ queryKey: audioRecord.queryKey })
                                            notifications.show({ message: "清空成功" })
                                        } catch (e) {
                                            notifications.show({ message: "清空失敗", color: "red" })
                                        }
                                        setClearAudioRecordLoading(false)
                                    },
                                });
                            }}
                        >
                            清空所有轉錄紀錄
                        </Menu.Item>
                        <Menu.Item
                            disabled={transcriptionJobExist || !audioExist}
                            leftSection={<IconTrash style={{ width: rem(14), height: rem(14) }} />}
                            color="red"
                            onClick={() => {
                                modals.openConfirmModal({
                                    title: '刪除音檔',
                                    centered: true,
                                    children: (
                                        <Text size="sm">
                                            是否要刪除音檔
                                        </Text>
                                    ),
                                    labels: { confirm: '刪除', cancel: "取消" },
                                    confirmProps: { color: 'red', loading: deleteAudioLoading },
                                    onConfirm: async () => {
                                        setDeleteAudioLoading(true)
                                        try {
                                            audioPlayer.current?.SetPlaying(false)
                                            await deleteAudio_Mutation.mutateAsync({
                                                params: {
                                                    workspaceId: workspaceId, audioRecordId: audioRecordId
                                                }
                                            })
                                            queryClient.invalidateQueries({ queryKey: audioRecordRef.queryKey })
                                            notifications.show({ message: "刪除成功" })
                                        } catch (e) {
                                            notifications.show({ message: "刪除失敗", color: "red" })
                                        }
                                        setDeleteAudioLoading(false)
                                    },
                                });
                            }}
                        >
                            刪除音檔
                        </Menu.Item>
                    </Menu.Dropdown>
                </Menu>
            }
            {transcriptionJobExist && <Tooltip label="轉錄中..." color="gray">
                <Loader type="dots" size="sm" />
            </Tooltip>}
            {translationJobExist && <Tooltip label="翻譯中..." color="gray">
                <Loader type="dots" size="sm" />
            </Tooltip>}
            {audioExist &&
                <Button radius="xl" size="compact-xs" onClick={() => {
                    UrlSaveToFile(GetAudioRecordAudioUrl(workspaceId, audioRecordId), audioRecordRef.data?.data.audioIds?.at(0) ?? "Audio.wav")
                }}>音檔<IconDownload size={14} style={{ marginLeft: "3px" }} /></Button>}
            {recordExist &&
                <Button radius="xl" size="compact-xs" onClick={() => {
                    var srt = AudioRecordToSrt(audioRecord.data?.data!)
                    if (srt) TextSaveToFile(`${audioRecord.data?.data.name}.txt`, srt)
                }}>文字<IconDownload size={14} style={{ marginLeft: "3px" }} /></Button>}
            {audioRecord.data?.data && <EditRecordAllSpeakersForm readOnly={!workspaceEditorAuth} audioRecord={audioRecord.data.data} />}
        </Flex>
        <Group gap={0} mb={5}>
            {workspaceEditorAuth && !transcriptionJobExist && !translationJobExist && recordExist &&
                <Button size="compact-md" color="green" mr={10} onClick={() => {
                    createTranslationJob_open()
                }}>
                    翻譯
                </Button>
            }
            {audioRecord.data?.data && translatedTexts.length > 0 && <TranslatedLanguageSelect
                selectProps={{ size: "xs", withCheckIcon: false, w: "90px", placeholder: "選擇語言", allowDeselect: false }}
                deleteButtonProps={{ size: "compact-xs" }}
                audioRecord={audioRecord.data.data} onSelectLanguage={l => {
                    setTranslatedLanguage(l)
                }} />}
        </Group>
        <Box h="calc(100% - 190px)">
            {audioExist && !recordExist && !transcriptionJobExist &&
                <Flex h="100%" align="center" justify="center">
                    <CreateAudioTranscriptionJobButton workspaceId={workspaceId} audioRecordId={audioRecordId} buttonProps={{ variant: "gradient", size: "xl" }} >
                        開始轉錄
                    </CreateAudioTranscriptionJobButton>
                </Flex>}
            {clearAudioRecordLoading ? <Center><Loader size="xl" /></Center> :
                <>
                    {recordEntries.length == 0 ? <Center h="100%">
                        <Text fw={500} size="40px" opacity={0.4} c="gray">上傳音檔以分析逐字稿</Text>
                    </Center> : <>
                        <Transition
                            mounted={showToBottom}
                            transition="fade-up"
                            duration={200}
                            timingFunction="ease"
                        >
                            {(styles) => <ActionIcon
                                style={{ zIndex: 10, position: "absolute", right: "20px", bottom: "150px", ...styles }}
                                variant="light"
                                radius="xl"
                                size="xl"
                                onClick={() => {
                                    virtualListRef.current?.ScrollToBottom()
                                }}
                            >
                                <IconArrowBarToDown />
                            </ActionIcon>}
                        </Transition>
                        <VirtualList ref={virtualListRef} data={recordEntries} onScrollToBottom={v => {
                            setShowToBottom(v > 10)
                        }} components={(data, index) => <RecordEntryCard key={`RecordEntryCard_${index}`} loading={updateEntryLoading.has(data[index].id!)} isEditable={workspaceEditorAuth} hasNext={index + 1 < data.length} enablePlayButton={!!audioExist} recordEntry={data[index]} translatedLanguages={[translatedLanguage]}
                            isSelected={currentPlayingTime >= (data[index].time ?? 0) && currentPlayingTime < (data[index].time ?? 0) + (data[index].duration ?? 0)}
                            onPlay={() => {
                                if (data[index].time != undefined) {
                                    audioPlayer.current?.Seek(data[index].time + 0.01)
                                    audioPlayer.current?.SetPlaying(true)
                                }

                            }}
                            onEdit={() => {
                                setSelectedRecordEntry(data[index])
                                updateEntry_open();
                            }}
                            onDelete={() => {
                                modals.openConfirmModal({
                                    title: '刪除',
                                    centered: true,
                                    children: (
                                        <Text size="sm">
                                            是否要刪除該紀錄?
                                        </Text>
                                    ),
                                    labels: { confirm: '刪除', cancel: "取消" },
                                    confirmProps: { color: 'red' },
                                    onConfirm: async () => {
                                        const entry_id = data[index].id
                                        if (!entry_id) return;
                                        setUpdateEntryLoading(x => { x.add(entry_id) })
                                        try {
                                            await deleteEntry_Mutation.mutateAsync({ params: { workspaceId: workspaceId, audioRecordId: audioRecordId, entryId: entry_id } })
                                            queryClient.invalidateQueries({ queryKey: audioRecord.queryKey })
                                            notifications.show({ message: "刪除成功" })
                                        } catch (e) {
                                            notifications.show({ message: "刪除失敗", color: "red" })
                                        }
                                        setUpdateEntryLoading(x => { x.delete(entry_id) })
                                    },
                                });
                            }}
                            onAddNext={() => {
                                var target: AudioRecordEntry = {}
                                target.time = (data[index].time ?? 0) + 1
                                target.duration = (data[index].duration ?? 0)
                                setSelectedRecordEntry(target)
                                addEntry_open();
                            }}
                            onMergeNext={() => {
                                if (index + 1 < data.length) {
                                    modals.openConfirmModal({
                                        title: '向下合併',
                                        centered: true,
                                        children: (
                                            <Text size="sm">
                                                是否要合併紀錄?
                                            </Text>
                                        ),
                                        labels: { confirm: '合併', cancel: "取消" },
                                        onConfirm: async () => {
                                            const source_id = data[index].id
                                            const target_id = data[index + 1].id
                                            if (!source_id || !target_id) return;
                                            var merge = MergeAudioRecordEntry(data[index], data[index + 1]);
                                            setUpdateEntryLoading(x => { x.add(source_id) })
                                            setUpdateEntryLoading(x => { x.add(target_id) })
                                            try {
                                                await updateEntry_Mutation.mutateAsync({ params: { workspaceId: workspaceId, audioRecordId: audioRecordId }, data: merge })
                                                await deleteEntry_Mutation.mutateAsync({ params: { workspaceId: workspaceId, audioRecordId: audioRecordId, entryId: target_id } })
                                                queryClient.invalidateQueries({ queryKey: audioRecord.queryKey })
                                                notifications.show({ message: "合併成功" })
                                            } catch (e) {
                                                notifications.show({ message: "合併失敗", color: "red" })
                                            }
                                            setUpdateEntryLoading(x => { x.delete(source_id) })
                                            setUpdateEntryLoading(x => { x.delete(target_id) })
                                        },
                                    });
                                }
                            }}
                            onEditSpeakers={() => {
                                setSelectedRecordEntry(data[index])
                                updateEntrySpeakers_open();
                            }}
                        />} heightOffset={0} />
                    </>}
                </>
            }
        </Box>

        {workspaceEditorAuth && !audioExist && <UploadAudioRecordFileButton workspaceId={workspaceId} audioRecordId={audioRecordId} />}
        {deleteAudioLoading ? <Center><Loader size="xl" /></Center> :
            audioExist && <AudioPlayer key={`AudioPlayer_${audioRecordRef.data?.data.audioIds?.at(0)}`} ref={audioPlayer} url=
                {GetAudioRecordAudioUrl(workspaceId, audioRecordId, audioRecordRef.data?.data.audioIds?.at(0))}
                onSecondProgress={v => {
                    setCurrentPlayingTime(v)
                    throttledSetStorePlayerTime(v)
                }}
            />
        }
        <Modal opened={addEntry_opened} onClose={addEntry_close} title="新增">
            <EditRecordEntryForm initValue={selectedRecordEntry ?? {}} selectLanguage={translatedLanguage!} onSubmit={async entry => {
                addEntry_close();
                var target = R.clone(entry)
                target.id = uuidv4()
                try {
                    await updateEntry_Mutation.mutateAsync({ params: { workspaceId: workspaceId, audioRecordId: audioRecordId }, data: target })
                    queryClient.invalidateQueries({ queryKey: audioRecord.queryKey })
                    notifications.show({ message: "新增成功" })
                } catch (e) {
                    notifications.show({ message: "新增失敗", color: "red" })
                }
            }} />
        </Modal>
        <Modal opened={updateEntry_opened} onClose={updateEntry_close} title="編輯">
            <EditRecordEntryForm initValue={selectedRecordEntry ?? {}} selectLanguage={translatedLanguage!} onSubmit={async entry => {
                updateEntry_close();
                setUpdateEntryLoading(x => { x.add(entry.id!) })
                try {
                    await updateEntry_Mutation.mutateAsync({ params: { workspaceId: workspaceId, audioRecordId: audioRecordId }, data: entry })
                    queryClient.invalidateQueries({ queryKey: audioRecord.queryKey })
                    notifications.show({ message: "更新成功" })
                } catch (e) {
                    notifications.show({ message: "更新失敗", color: "red" })
                }
                setUpdateEntryLoading(x => { x.delete(entry.id!) })
            }} />
        </Modal>
        <Modal opened={updateEntrySpeakers_opened} onClose={updateEntrySpeakers_close} title="編輯語者">
            <EditRecordEntrySpeakerForm initValue={selectedRecordEntry ?? {}} options={speakers} onSubmit={async entry => {
                updateEntrySpeakers_close();
                setUpdateEntryLoading(x => { x.add(entry.id!) })
                try {
                    await updateEntry_Mutation.mutateAsync({ params: { workspaceId: workspaceId, audioRecordId: audioRecordId }, data: entry })
                    queryClient.invalidateQueries({ queryKey: audioRecord.queryKey })
                    notifications.show({ message: "更新成功" })
                } catch (e) {
                    notifications.show({ message: "更新失敗", color: "red" })
                }
                setUpdateEntryLoading(x => { x.delete(entry.id!) })
            }} />
        </Modal>
        <Modal opened={transcriptionJobInfo_opened} onClose={transcriptionJobInfo_close} title="轉錄資訊">
            <AudioTranscriptionJobInfo workspaceId={workspaceId} audioRecordId={audioRecordId} onDelete={() => {
                transcriptionJobInfo_close()
                queryClient.invalidateQueries({ queryKey: transcriptionJob.queryKey })
            }} />
        </Modal>
        <Modal opened={createTranscriptionJob_opened} onClose={createTranscriptionJob_close} closeOnClickOutside={!createTranscriptionJobLoading} closeOnEscape={!createTranscriptionJobLoading} title="新增轉錄任務">
            <CreateAudioTranscriptionJobForm workspaceId={workspaceId} audioRecordId={audioRecordId} cleanOld={true} onCreateStart={() => setCreateTranscriptionJobLoading(true)} onCreateEnd={(success) => {
                setCreateTranscriptionJobLoading(false)
                if (success) {
                    queryClient.invalidateQueries({ queryKey: audioRecord.queryKey })
                    queryClient.invalidateQueries({ queryKey: transcriptionJob.queryKey })
                    createTranscriptionJob_close();
                }
            }} />
        </Modal>
        <Modal opened={translationJobInfo_opened} onClose={translationJobInfo_close} title="翻譯資訊">
            <AudioTranslationJobInfo workspaceId={workspaceId} audioRecordId={audioRecordId} onDelete={() => {
                translationJobInfo_close()
                queryClient.invalidateQueries({ queryKey: translationJob.queryKey })
            }} />
        </Modal>
        <Modal opened={createTranslationJob_opened} onClose={createTranslationJob_close} closeOnClickOutside={!createTranslationJobLoading} closeOnEscape={!createTranslationJobLoading} title="新增翻譯任務">
            <CreateAudioTranslationJobForm workspaceId={workspaceId} audioRecordId={audioRecordId} onCreateStart={() => setCreateTranslationJobLoading(true)} onCreateEnd={(success) => {
                setCreateTranslationJobLoading(false)
                if (success) {
                    queryClient.invalidateQueries({ queryKey: audioRecord.queryKey })
                    queryClient.invalidateQueries({ queryKey: translationJob.queryKey })
                    createTranslationJob_close();
                }
            }} />
        </Modal>
    </Box>
}
