import { Button, Group, Modal, NumberInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { RichTextEditor, useRichTextEditorContext } from "@mantine/tiptap";
import { IconClockPlay } from "@tabler/icons-react";
import { useAudioRecordStateStore } from "../../AudioRecordStateStore";

export function AudioRecordTimeControl() {
    const { editor } = useRichTextEditorContext();
    const audioRecordStateStore = useAudioRecordStateStore()
    const [opened, { open, close }] = useDisclosure(false);
    const form = useForm({
        mode: 'uncontrolled',
        initialValues: {
            hours: 0, minutes: 0, seconds: 0
        },

    });
    return (
        <>
            <RichTextEditor.Control
                onClick={() => {
                    var seconds = audioRecordStateStore.playerTime;
                    seconds=Math.round(seconds)
                    const hours = Math.floor(seconds / 3600);
                    seconds %= 3600;
                    const minutes = Math.floor(seconds / 60);
                    seconds %= 60;
                    form.reset()
                    form.setValues({
                        hours: hours,
                        minutes: minutes,
                        seconds: seconds,
                    })
                    console.log(form.values)
                    open()
                }}
                title="插入時間點"
            >
                <IconClockPlay stroke={1.5} size="1rem" />
            </RichTextEditor.Control>
            <Modal opened={opened} onClose={close} title="選擇時間">
                <form onSubmit={form.onSubmit((values) => {
                    var t = values.hours * 3600 + values.minutes * 60 + values.seconds
                    editor?.commands.insertContent(`<AudioRecordTime time=${t} />`)
                    close()
                })}>
                    <Group>
                        <NumberInput label="時" w={100} min={0} max={99}  {...form.getInputProps('hours')} />
                        <NumberInput label="分" w={100} min={0} max={59}  {...form.getInputProps('minutes')} />
                        <NumberInput label="秒" w={100} min={0} max={59}  {...form.getInputProps('seconds')} />
                    </Group>
                    <Button mt={10} type="submit">確認</Button>
                </form>
            </Modal>
        </>
    );
}