import * as R from "remeda"
import { SttType, useGetApiAdminAudioToTextBalancerListCurrentWeight } from "@/API";
import { ActionIcon, Button, Card, Group, Loader, ScrollArea, Table, Text, Tooltip } from "@mantine/core";
import { useEffect, useState } from "react";
import { QueryClient } from "@tanstack/react-query";
import { IconRefresh } from "@tabler/icons-react";

export function WeightList() {
    const queryClient = new QueryClient()
    var realtime_weight = useGetApiAdminAudioToTextBalancerListCurrentWeight({ sttType: "realtime" })
    const [realtimeSum, setRealtimeSum] = useState(0)
    var correction_weight = useGetApiAdminAudioToTextBalancerListCurrentWeight({ sttType: "correction" })
    const [correctionSum, setCorrectionSum] = useState(0)
    var file_weight = useGetApiAdminAudioToTextBalancerListCurrentWeight({ sttType: "file" })
    const [fileSum, setFileSum] = useState(0)
    useEffect(() => {
        var sum = R.sum(realtime_weight.data?.data.map(x => x.weight ?? 0) ?? [])
        setRealtimeSum(sum)
    }, [realtime_weight.data?.data])
    useEffect(() => {
        var sum = R.sum(correction_weight.data?.data.map(x => x.weight ?? 0) ?? [])
        setCorrectionSum(sum)
    }, [correction_weight.data?.data])
    useEffect(() => {
        var sum = R.sum(file_weight.data?.data.map(x => x.weight ?? 0) ?? [])
        setFileSum(sum)
    }, [file_weight.data?.data])
    return <>
        <WeightListCard sttType="realtime" />
        <WeightListCard sttType="correction" />
        <WeightListCard sttType="file" />
    </>
}
function WeightListCard(props: { sttType: SttType }) {
    var weight = useGetApiAdminAudioToTextBalancerListCurrentWeight({ sttType: props.sttType })
    const [sum, setSum] = useState(0)
    useEffect(() => {
        var sum = R.sum(weight.data?.data.map(x => x.weight ?? 0) ?? [])
        setSum(sum)
    }, [weight.data?.data])
    return <Card shadow="sm" padding="xs" radius="md" withBorder>
        <ScrollArea>
            <Group gap={0}>
                <Text m="xs">
                    {props.sttType}
                </Text>
                <ActionIcon variant="subtle" onClick={() => weight.refetch()} >
                    <IconRefresh />
                </ActionIcon>
            </Group>
            {weight.isSuccess ? <Table>
                <Table.Thead>
                    <Table.Tr>
                        <Table.Th>名稱</Table.Th>
                        <Table.Th>
                            <Tooltip label="權重=預設權重*平均每秒可以處理音檔長度(標準值=1,Timeout=0.01)">
                                <span>權重</span>
                            </Tooltip>
                        </Table.Th>
                    </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                    {weight.data?.data.map((x, idx) => <Table.Tr key={`Weight_${idx}`} >
                        <Table.Td>{x.name}</Table.Td>
                        <Table.Td>{R.round((x.weight ?? 0) / sum * 100, 1)}%({R.round(x.weight ?? -1, 1)})</Table.Td>
                    </Table.Tr>)}
                </Table.Tbody>
            </Table> : <Loader m={10} />}
        </ScrollArea>
    </Card>
}