export function GetTextDocumentImageUrl(workspaceId: string, audioRecordId: string, fileName: string) {
    var url = `/api/AudioRecordTextDocumentImage/Get?workspaceId=${workspaceId}&audioRecordId=${audioRecordId}&fileName=${fileName}`
    return url
}
export function GetAudioRecordAudioUrl(workspaceId: string, audioRecordId: string, audioId: string = "") {
    var url = `/api/AudioRecord/GetAudio?workspaceId=${workspaceId}&audioRecordId=${audioRecordId}&audioId=${audioId}`
    return url
}
export function GetAudioRecordUrl(workspaceId: string, audioRecordId: string) {
    var url = `/workspace/${workspaceId}/audioRecord/${audioRecordId}`
    return url
}
export function GetWorkspaceUrl(workspaceId: string) {
    var url = `/workspace/${workspaceId}`
    return url
}