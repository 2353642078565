import { Button, Card, Group, Text, Stack } from "@mantine/core"
import { useNavigate, useParams } from "@tanstack/react-router"
import { CreditKeyList } from "./CreditKey";
import { PeriodCreditKeyList } from "./PeriodCreditKey";
import { ChangeUserPassword } from "./ChangeUserPassword";
import { AdminAppKeyManager } from "./AppKey";

export function UserInfo() {
    const navigate = useNavigate()
    const { userId } = useParams({ from: "/admin/UserManager/$userId" })
    return <>
        <Card withBorder p={10} mb={10}>
            <Card.Section withBorder inheritPadding py="xs">
                <Text fw={500}>資料查詢</Text>
            </Card.Section>
            <Card.Section withBorder inheritPadding py="xs">
                <Group>
                    <Button variant="gradient" onClick={() => {
                        navigate({
                            to: "/admin/TTSUsage",
                            search: { userId: userId }
                        })
                    }}>使用狀況查詢</Button>
                    {/* <Button variant="gradient" onClick={() => {
                        navigate({
                            to: "/admin/LinePay",
                            search: { userId: userId }
                        })
                    }}>LinePay查詢</Button> */}
                </Group>
            </Card.Section>
        </Card >
        <Stack>
            <ChangeUserPassword userId={userId} />
            <PeriodCreditKeyList userId={userId} />
            <CreditKeyList userId={userId} />
            <AdminAppKeyManager userId={userId} />
        </Stack>
    </>
}