import { useGetApiWorkspaceList, useGetApiWorkspaceMediaSize } from "@/API"
import { BytesToString } from "@/Helper/string"
import { Loader, Paper, Text } from "@mantine/core"
import { useParams } from "@tanstack/react-router"

export function WorkspaceInfo() {
    const { workspaceId } = useParams({ from: "/workspace/$workspaceId/settings/info" })
    var size = useGetApiWorkspaceMediaSize({ id: workspaceId })
    return <>
        <Paper withBorder shadow="xs" p="md">
            <Text>媒體大小</Text>
            <Text>
                {size.data?.data != undefined ? BytesToString(size.data?.data) : <Loader />}
            </Text>
        </Paper>
    </>
}