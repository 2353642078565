import { AuthState, SystemInfo } from '@/API'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

export interface SystemInfoStoreState {
    info: SystemInfo
    Update: (info: SystemInfo) => void
}
export const useSystemInfoStore = create<SystemInfoStoreState>()(
    devtools(
        immer((set) => ({
            info: {},
            Update: (info: SystemInfo) =>
                set((state) => {
                    state.info = info
                })
        }))),
)