function rand(min: number, max: number) {
    return min + Math.random() * (max - min);
}
export function get_random_color() {
    var h = rand(1, 360);
    var s = rand(0, 100);
    var l = rand(0, 100);
    return hslToHex(h, s, l)
}
export function get_random_hue_color(s: number, l: number) {
    var h = rand(1, 360);
    return hslToHex(h, s, l)
}
export function get_random_hue_color_from_string(s: number, l: number, str: string) {
    var h = hashStr(str) % 360;
    return hslToHex(h, s, l)
}
function hslToHex(h: number, s: number, l: number) {
    l /= 100;
    const a = s * Math.min(l, 1 - l) / 100;
    const f = (n: number) => {
        const k = (n + h / 30) % 12;
        const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
        return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
    };
    return `#${f(0)}${f(8)}${f(4)}`;
}
function hashStr(str: string) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        var charCode = str.charCodeAt(i);
        hash += charCode;
    }
    return hash;
}