import { WorkspaceUserRole } from "@/API";
import { Badge } from "@mantine/core";
import { useCallback } from "react";

export function WorkspaceUserRoleBadge({ role }: { role: WorkspaceUserRole }) {
    const badge = useCallback(() => {
        switch (role) {
            case 'Owner': return <Badge color="yellow">{WorkspaceUserRoleToText(role)}</Badge>
            case 'Manager': return <Badge color="red">{WorkspaceUserRoleToText(role)}</Badge>
            case 'Editor': return <Badge color="blue">{WorkspaceUserRoleToText(role)}</Badge>
            case 'Viewer': return <Badge color="green">{WorkspaceUserRoleToText(role)}</Badge>
            default: return <Badge color="gray">{WorkspaceUserRoleToText(role)}</Badge>;
        }
    }, [role]);
    return <>{badge()}</>
}
export function WorkspaceUserRoleToText(role: WorkspaceUserRole) {
    switch (role) {
        case 'Owner': return "擁有者"
        case 'Manager': return "管理者"
        case 'Editor': return "編輯者"
        case 'Viewer': return "查看"
        default: return "無效"
    }
}