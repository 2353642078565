import { MultiSourceSearchResult, usePostApiSearchSearch } from "@/API";
import { useEffect, useState } from "react";
import { Divider, Loader, Modal, Pagination, TextInput, Text, Box, LoadingOverlay } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { IconSearch } from "@tabler/icons-react";
import { AudioRecordSearchCard } from "./AudioRecordSearchCard";
import { TextDocumentSearchCard } from "./TextDocumentSearchCard";
const pageSize = 5
export function WorkspaceSearch(props: { workspaceIds: string[] }) {
    const [opened, { open, close }] = useDisclosure(false);
    const [query, setQuery] = useState("")
    const form = useForm({
        mode: 'uncontrolled',
        initialValues: {
            query: '',
        },
    });
    return <>
        <form onSubmit={form.onSubmit(async (values) => {
            setQuery(values.query)
            open()
        }
        )}>
            <TextInput
                leftSectionPointerEvents="none"
                leftSection={<IconSearch />}
                placeholder="搜尋"
                {...form.getInputProps('query')}
            />
        </form>
        <Modal opened={opened} onClose={close} title="">
            <WorkspaceSearchResultPage workspaceIds={props.workspaceIds} query={query} />
        </Modal>
    </>
}
export function WorkspaceSearchResultPage(props: { workspaceIds: string[], query: string }) {
    const [searching, setSearching] = useState(false);
    const [activePage, setPage] = useState(1);
    const [result, setResult] = useState<MultiSourceSearchResult>();
    const search_Mutation = usePostApiSearchSearch()
    useEffect(() => {
        setSearching(true)
        search_Mutation.mutateAsync({
            data: {
                workspaceIds: props.workspaceIds,
                cropLength: 10,
                query: props.query,
                skip: (activePage - 1) * pageSize,
                limit: pageSize,
            }
        }).then(data => {
            setResult(data.data)
        }).finally(() => {
            setSearching(false)
        })
    }, [activePage])
    return <Box pos="relative">
        <LoadingOverlay
            visible={searching}
            zIndex={1000}
            loaderProps={{ type: 'bars' }}
        />
        {!result || (result?.audioRecordsTotal == 0 && result?.textDocumentsTotal == 0) ? <>
            <Text c="gray" ta="center" size="xl">
                找不到資料...
            </Text>
        </> : <>
            {result && <Pagination total={Math.ceil((Math.max(result?.audioRecordsTotal ?? 0, result?.textDocumentsTotal ?? 0)) / pageSize)} value={activePage} onChange={setPage} siblings={1} defaultValue={10} />}
            {result?.audioRecords?.map((x, idx) => <div key={`AudioRecordSearchCard_${idx}`}>
                <AudioRecordSearchCard audioRecord={x} />
                {idx + 1 < result.audioRecords?.length! && <Divider my="md" />}
            </div>)}

            {result?.audioRecordsTotal != 0 && result?.textDocumentsTotal != 0 &&
                <Divider size="md" my="md" />}

            {result?.textDocuments?.map((x, idx) => <div key={`TextDocumentSearchCard_${idx}`}>
                <TextDocumentSearchCard textDocument={x} />
                {idx + 1 < result.textDocuments?.length! && <Divider my="md" />}
            </div>)}
        </>}
    </Box>
}

