import { getGetApiAdminCreditKeyListQueryKey, usePatchApiAdminCreditKeyIncreaseBalance } from "@/API";
import { Button, Group, Modal, NumberInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import 'dayjs/locale/zh-tw';

export function IncreaseCreditKeyDialog(props: { userId: string, keyId: string, opened: boolean, onClose: () => void }) {
    const queryClient = useQueryClient()
    const IncreaseKey_Mutation = usePatchApiAdminCreditKeyIncreaseBalance()
    const form = useForm<{ increaseBalance: number }>({
        initialValues: {
            increaseBalance: 0,
        },
    });
    useEffect(() => {
        form.reset()
    }, [props.opened])
    return <>
        <Modal opened={props.opened} onClose={props.onClose} title="修改儲值金鑰">
            {`UserId:${props.userId}`}
            <br />
            {`keyId:${props.keyId}`}
            <form onSubmit={form.onSubmit(async (values) => {
                try {
                    await IncreaseKey_Mutation.mutateAsync({
                        params: {
                            userId: props.userId,
                            keyId: props.keyId,
                            increaseBalance: values.increaseBalance * 3600
                        }
                    })
                    queryClient.invalidateQueries({ queryKey: getGetApiAdminCreditKeyListQueryKey() })
                    notifications.show({ message: "增加成功" })
                    props.onClose()
                } catch (e) {
                    notifications.show({ message: "增加失敗", color: "red" })
                }
            })}>
                <NumberInput
                    label="增加量(小時)"
                    {...form.getInputProps('increaseBalance')}
                />
                <Group justify="flex-end" mt="md">
                    <Button type="submit">增加</Button>
                </Group>
            </form>
        </Modal>
    </>
}