import { useGetApiWorkspaceUserGetRole, WorkspaceUserRole } from "@/API";

export function UseWorkspaceAuth(workspaceId: string, role: WorkspaceUserRole) {
    if (!workspaceId) return false
    if (role == "None") return false
    const _role = useGetApiWorkspaceUserGetRole({ workspaceId: workspaceId })
    if (!_role.data?.data) return false
    if (role == "Viewer") return ["Owner", "Manager", "Editor", "Viewer"].includes(_role.data?.data)
    if (role == "Editor") return ["Owner", "Manager", "Editor"].includes(_role.data?.data)
    if (role == "Manager") return ["Owner", "Manager"].includes(_role.data?.data)
    if (role == "Owner") return ["Owner"].includes(_role.data?.data)
    return false
}