import { AudioRecordIndex } from '@/Workspaces/AudioRecord'
import { createFileRoute } from '@tanstack/react-router'
import { z } from 'zod'
const textDocumentSearchSchema = z.object({
  docId: z.string().optional().catch(''),
  time: z.number().optional().catch(0)
})

export const Route = createFileRoute(
  '/workspace/$workspaceId/audioRecord/$audioRecordId',
)({
  component: () => <AudioRecordIndex />,
  validateSearch: (search) => textDocumentSearchSchema.parse(search),
})
