import { AudioRecordEntry } from "@/API";
import { SecondToString } from "@/Helper/string";
import { Text, useMantineTheme, ActionIcon, Group, Divider, Stack, Flex, LoadingOverlay, Tooltip } from "@mantine/core";
import { useHover } from "@mantine/hooks";
import { IconArrowDown, IconArrowMoveDown, IconEdit, IconFold, IconPlayerPlayFilled, IconPlus, IconTrash, IconUserFilled } from "@tabler/icons-react";

export function RecordEntryCard({ loading = false, isEditable, hasNext, recordEntry, isSelected, enablePlayButton = true, onPlay, onEdit, onDelete, onAddNext, onMergeNext, onEditSpeakers, translatedLanguages = [] }: { loading?: boolean, isEditable: boolean, hasNext: boolean, recordEntry: AudioRecordEntry, isSelected: boolean, enablePlayButton?: boolean, onPlay?: () => void, onEdit?: () => void, onDelete?: () => void, onAddNext?: () => void, onMergeNext?: () => void, onEditSpeakers?: () => void, translatedLanguages?: (string | null)[] }) {
    const theme = useMantineTheme();
    const activeColor = theme.colors[theme.primaryColor][5]
    const inactiveColor = "gray"
    const { hovered, ref } = useHover();
    return <>
        <LoadingOverlay
            visible={loading}
            zIndex={1000}
            loaderProps={{ type: 'bars' }}
        />
        <Group align="center" justify="start" gap={0} >
            {
                enablePlayButton &&
                <ActionIcon
                    variant="subtle"
                    radius="lg"
                    style={{ zIndex: 10, left: '-6px', padding: '0px' }}
                    onClick={onPlay}
                >
                    <IconPlayerPlayFilled
                        color={isSelected ? activeColor : inactiveColor}
                        size={20}
                    />
                </ActionIcon >
            }
            {isEditable &&
                <ActionIcon
                    variant="subtle"
                    radius="lg"
                    style={{ zIndex: 10, left: '-6px', padding: '0px' }}
                    onClick={onEdit}
                >
                    <IconEdit
                        color={isSelected ? activeColor : inactiveColor}
                        size={20}
                    />
                </ActionIcon >
            }
            {isEditable &&
                <ActionIcon
                    variant="subtle"
                    radius="lg"
                    style={{ zIndex: 10, left: '-6px', padding: '0px' }}
                    onClick={onDelete}
                >
                    <IconTrash
                        color={isSelected ? activeColor : inactiveColor}
                        size={20}
                    />
                </ActionIcon >
            }
            <Text
                onClick={onPlay}
                c={isSelected ? activeColor : inactiveColor}
            >
                {SecondToString(Math.max(recordEntry.time ?? 0, 0))}~{SecondToString((recordEntry.time ?? 0) + (recordEntry.duration ?? 0))}

            </Text>
            <Text
                style={{ marginLeft: '5px' }}
                c={isSelected ? activeColor : inactiveColor}
            >
                {recordEntry.speakers?.join(', ')}
            </Text>
            {isEditable &&
                <ActionIcon
                    variant="subtle"
                    radius="lg"
                    style={{ zIndex: 10, left: '5px', padding: '0px' }}
                    onClick={onEditSpeakers}
                >
                    <IconUserFilled
                        color={isSelected ? activeColor : inactiveColor}
                        size={20}
                    />
                </ActionIcon>
            }
        </Group>

        <Group pos="relative" gap={0} ml="2.5px">
            <Divider orientation="vertical" size="3px" pos="relative" left={3} color={isSelected ? activeColor : inactiveColor} />
            <Flex dir="row" flex={1}>
                <Text
                    flex={1}
                    style={{
                        marginLeft: '15px',
                        marginBottom: '0px',
                        color: isSelected ? activeColor : inactiveColor,
                        whiteSpace: "pre-wrap"
                    }}
                    size="sm"
                >
                    {recordEntry.text}
                </Text>
                {recordEntry.translatedTexts?.filter(x => translatedLanguages.includes(x.language ?? "")).map((text, idx) => {
                    return <Text
                        flex={1}
                        key={`translated_${idx}`}
                        style={{
                            marginLeft: '15px',
                            marginBottom: '0px',
                            color: isSelected ? activeColor : inactiveColor,
                            whiteSpace: "pre-wrap"
                        }}
                        size="sm"
                    >
                        {text.text}
                    </Text>
                })}
            </Flex>
            {isEditable &&
                <Divider
                    pos="absolute"
                    bottom={10}
                    right={0}
                    left={0}
                    my={0}
                    h={0}
                    style={{ zIndex: 1 }}
                    ref={ref}
                    opacity={hovered ? 1 : 0}
                    label={
                        <>
                            <div >
                                <Tooltip label="插入" color="gray">
                                    <ActionIcon variant="subtle" onClick={onAddNext}>
                                        <IconPlus style={{ width: '70%', height: '70%' }} stroke={1.5} />
                                    </ActionIcon>
                                </Tooltip>
                                {hasNext &&
                                    <Tooltip label="向下合併" color="gray">
                                        <ActionIcon variant="subtle" onClick={onMergeNext}>
                                            <IconArrowDown style={{ width: '70%', height: '70%' }} stroke={1.5} />
                                        </ActionIcon>
                                    </Tooltip>
                                }
                            </div>
                        </>
                    }
                />
            }
        </Group>
    </>
}