import { useDeleteApiAdminLicenseDeleteLicense, useGetApiAdminLicenseGetLicense, useGetApiAdminLicenseLicenseStatus, usePutApiAdminLicenseUpdateLicense } from "@/API"
import { Alert, Box, Button, Card, FileButton, Loader, Table, Text } from "@mantine/core"
import { modals } from "@mantine/modals"
import { notifications } from "@mantine/notifications"
import { IconInfoCircle } from "@tabler/icons-react"
import { useQueryClient } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { useRef } from "react"

export function LicensePage() {
    const queryClient = useQueryClient()
    const resetRef = useRef<() => void>(null);
    const license = useGetApiAdminLicenseGetLicense()
    const statusLicense = useGetApiAdminLicenseLicenseStatus()
    const updateLicense_Mutation = usePutApiAdminLicenseUpdateLicense()
    const deleteLicense_Mutation = useDeleteApiAdminLicenseDeleteLicense()
    return <>
        <FileButton resetRef={resetRef} onChange={async file => {
            const json = await file?.text()
            if (json) {
                try {
                    const result = await updateLicense_Mutation.mutateAsync({ data: JSON.parse(json) })
                    notifications.show({ message: "更新憑證成功" })
                    queryClient.invalidateQueries({ queryKey: license.queryKey })
                    queryClient.invalidateQueries({ queryKey: statusLicense.queryKey })
                } catch (e) {
                    const error = e as AxiosError
                    const msg = error.response?.data
                    if (typeof msg === "string")
                        notifications.show({ message: `更新憑證失敗:${msg}`, color: "red" })
                    else
                        notifications.show({ message: `更新憑證失敗`, color: "red" })
                }
            }
            resetRef.current?.()
        }} accept="application/json">
            {(props) => <Button {...props}>更新憑證</Button>}
        </FileButton>
        {license.data?.data && <><Box mt={10}>
            <Card shadow="sm" padding="xs" radius="md" withBorder>
                {license.isSuccess ?
                    <Table >
                        <Table.Thead>
                            <Table.Tr>
                                <Table.Th>頒發者</Table.Th>
                                <Table.Th>憑證擁有者</Table.Th>
                                <Table.Th>開始有效日期</Table.Th>
                                <Table.Th>到期日期</Table.Th>
                                <Table.Th>額外資訊</Table.Th>
                            </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody>
                            <Table.Tr >
                                <Table.Td>{license.data.data.issuer}</Table.Td>
                                <Table.Td>{license.data.data.subject}</Table.Td>
                                <Table.Td>{new Date((license.data.data.validFrom ?? 0) * 1000).toLocaleString()}</Table.Td>
                                <Table.Td>{new Date((license.data.data.validTo ?? 0) * 1000).toLocaleString()}</Table.Td>
                                <Table.Td>{license.data.data.extensions}</Table.Td>
                            </Table.Tr>
                        </Table.Tbody>
                    </Table> : <Loader m={10} />}
            </Card>
            <Button color="red" onClick={() => {
                modals.openConfirmModal({
                    title: '刪除憑證',
                    centered: true,
                    children: (
                        <Text size="sm">
                            是否要刪除憑證，刪除憑證將會失去系統授權
                        </Text>
                    ),
                    labels: { confirm: '刪除', cancel: "取消" },
                    confirmProps: { color: 'red' },
                    onConfirm: async () => {
                        try {
                            const result = await deleteLicense_Mutation.mutateAsync()
                            notifications.show({ message: "刪除憑證成功" })
                            queryClient.invalidateQueries({ queryKey: license.queryKey })
                            queryClient.invalidateQueries({ queryKey: statusLicense.queryKey })
                        } catch (e) {
                            const error = e as AxiosError
                            notifications.show({ message: `刪除憑證失敗`, color: "red" })
                        }
                    },
                });
            }}>
                刪除憑證
            </Button>
        </Box>
            {statusLicense.data?.data && !statusLicense.data?.data.isValid &&
                <Alert mt={10} variant="light" color="red" title="憑證無效" icon={<IconInfoCircle />}>
                    憑證驗證失敗
                </Alert>}
            {statusLicense.data?.data && !statusLicense.data?.data.isNotExpiration &&
                <Alert mt={10} variant="light" color="red" title="憑證到期" icon={<IconInfoCircle />}>
                    憑證已經過期或還未到生效時間
                </Alert>}
        </>
        }
        {statusLicense.data?.data && !statusLicense.data?.data.isExist &&
            <Alert mt={10} variant="light" color="yellow" title="憑證" icon={<IconInfoCircle />}>
                請使用上方"更新憑證"按鈕上傳憑證
            </Alert>}
    </>
}