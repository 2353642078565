import { getGetApiWorkspaceListQueryKey, useDeleteApiWorkspaceDelete, usePutApiWorkspaceRename, usePutApiWorkspaceUpdatePublicPermission, Workspace } from "@/API";
import { Text, Card, Group, Button, Menu, ActionIcon, rem, LoadingOverlay, Modal, Box } from "@mantine/core";
import { WorkspaceUserRoleBadge } from "../WorkspaceUserRoleText";
import { IconDots, IconEdit, IconEye, IconTrash } from "@tabler/icons-react";
import { useMemo, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import { useAuthStore } from "@/Stores/AuthStore";
import { EditWorkspaceNameForm } from "./EditWorkspaceNameForm";
import { useDisclosure } from "@mantine/hooks";
import { UseWorkspaceAuth } from "../UseWorkspaceAuth";
import { AudioRecordPublicPermssionForm } from "../AudioRecordPublicPermssionForm";
import { ShareLink } from "@/Components/ShareLink";
import { AudioRecordPublicPermissionBadge } from "../AudioRecordPublicPermissionText";
import { GetWorkspaceUrl } from "@/APIURL";

export function WorkspaceListCard({ workspace, onEnter }: { workspace: Workspace, onEnter: () => void }) {
    const queryClient = useQueryClient()
    const auth = useAuthStore();
    const workspaceOwnerAuth = UseWorkspaceAuth(workspace.id!, "Owner")
    const deleteWorkspace_Mutation = useDeleteApiWorkspaceDelete()
    const [deleteWorkspaceLoading, setDeleteWorkspaceLoading] = useState(false)
    var user = useMemo(() => workspace.users?.find(x => x.userName == auth.data.userName), [workspace.users])
    const [editName_opened, { open: editName_open, close: editName_close }] = useDisclosure(false);
    const rename_Mutation = usePutApiWorkspaceRename()
    const [updateLoading, setUpdateLoading] = useState(false)
    const editPermission_Mutation = usePutApiWorkspaceUpdatePublicPermission()
    const [editPermission_opened, { open: editPermission_open, close: editPermission_close }] = useDisclosure(false);
    return <>
        <Card w={400} shadow="sm" padding="lg" pt={5} radius="md" withBorder>
            <LoadingOverlay
                visible={deleteWorkspaceLoading || updateLoading}
                zIndex={1000}
                loaderProps={{ type: 'bars' }}
            />
            <Group>
                <Group mt="md" mb="xs" align="center" gap={5}>
                    <Text fw={500}>{workspace.name}</Text>
                    <WorkspaceUserRoleBadge role={user?.role ?? "None"} />
                    {workspace.publicPermission && workspace.publicPermission != "None" &&
                        <ShareLink url={window.origin + GetWorkspaceUrl(workspace.id!)} >
                            <AudioRecordPublicPermissionBadge permission={workspace.publicPermission!} badgeProps={{ style: { cursor: "pointer" } }} />
                        </ShareLink>
                    }
                </Group>
                {workspaceOwnerAuth &&
                    <Box pos="absolute" right={10} top={10}>
                        <Menu withinPortal position="bottom-end" shadow="sm">
                            <Menu.Target>
                                <ActionIcon variant="subtle" color="gray">
                                    <IconDots style={{ width: rem(16), height: rem(16) }} />
                                </ActionIcon>
                            </Menu.Target>
                            <Menu.Dropdown>
                                <Menu.Item
                                    leftSection={<IconEdit style={{ width: rem(14), height: rem(14) }} />}
                                    color="var(--mantine-primary-color-filled)"
                                    onClick={() => {
                                        editName_open()
                                    }}
                                >
                                    修改名稱
                                </Menu.Item>
                                <Menu.Item
                                    leftSection={<IconEye style={{ width: rem(14), height: rem(14) }} />}
                                    color="var(--mantine-primary-color-filled)"
                                    onClick={editPermission_open}
                                >
                                    修改公開權限
                                </Menu.Item>
                                <Menu.Item
                                    leftSection={<IconTrash style={{ width: rem(14), height: rem(14) }} />}
                                    color="red"
                                    onClick={() => {
                                        if (workspace.id) {
                                            modals.openContextModal({
                                                modal: "ConfirmCountDown",
                                                title: "刪除工作區域",
                                                innerProps: {
                                                    countDownSecond: 3,
                                                    children: (
                                                        <Text size="sm">
                                                            是否要刪除工作區域{workspace.name}?
                                                        </Text>
                                                    ),
                                                    labels: { confirm: '刪除', cancel: '取消' },
                                                    confirmProps: { color: 'red', loading: deleteWorkspaceLoading },
                                                    onConfirm: async () => {
                                                        setDeleteWorkspaceLoading(true)
                                                        try {
                                                            if (workspace.id)
                                                                await deleteWorkspace_Mutation.mutateAsync({
                                                                    params: {
                                                                        id: workspace.id
                                                                    }
                                                                })
                                                            notifications.show({ message: "刪除成功" })
                                                            queryClient.invalidateQueries({ queryKey: getGetApiWorkspaceListQueryKey() })
                                                        }
                                                        catch (e) {
                                                            notifications.show({ message: "刪除失敗", color: "red" })
                                                        }
                                                        setDeleteWorkspaceLoading(false)
                                                    }
                                                }
                                            })
                                        }
                                    }}
                                >
                                    刪除
                                </Menu.Item>
                            </Menu.Dropdown>
                        </Menu>
                    </Box>
                }
            </Group>



            {/* <Text size="sm" c="dimmed">
                
                With Fjord Tours you can explore more of the magical fjord landscapes with tours and
                activities on and around the fjords of Norway
            </Text> */}

            <Button color="blue" fullWidth mt="md" radius="md" onClick={() => {
                onEnter()
            }}>
                開啟
            </Button>
            <Modal title="修改名稱" opened={editName_opened} onClose={editName_close}>
                <EditWorkspaceNameForm initValue={workspace.name ?? ""} onSubmit={async v => {
                    editName_close()
                    setUpdateLoading(true)
                    if (workspace.id) {
                        try {
                            await rename_Mutation.mutateAsync({ params: { id: workspace.id, name: v } })
                            notifications.show({ message: "修改成功" })
                            queryClient.invalidateQueries({ queryKey: getGetApiWorkspaceListQueryKey() })
                        } catch (e) {
                            notifications.show({ message: "修改失敗", color: "red" })
                        }
                    }
                    setUpdateLoading(false)
                }} />
            </Modal>
            <Modal opened={editPermission_opened} onClose={editPermission_close} title="修改公開權限" centered>
                <AudioRecordPublicPermssionForm initValue={workspace.publicPermission ?? "None"} options={["None", "View"]} onConfirm={async (p) => {
                    editPermission_close();
                    setUpdateLoading(true)
                    try {
                        try {
                            await editPermission_Mutation.mutateAsync({ params: { id: workspace.id!, permission: p } })
                            notifications.show({ message: "修改成功" })
                            queryClient.invalidateQueries({ queryKey: getGetApiWorkspaceListQueryKey() })
                        } catch (e) {
                            notifications.show({ message: "修改失敗", color: "red" })
                        }
                    }
                    catch (e) {

                    }
                    setUpdateLoading(false)
                }} />
            </Modal>
        </Card>
    </>
}
