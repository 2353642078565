import { deleteApiAuthLogout, getApiAuthState, usePostApiUserChangePassword, usePutApiUserChangeDisplayName } from "@/API";
import { useAuthStore } from "@/Stores/AuthStore";
import { Button, Modal, TextInput } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { useNavigate } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { stringToPath } from "remeda";

export function ChangePasswordDialog(props: { opened: boolean, onClose: () => void }) {
    const auth = useAuthStore();
    const navigate = useNavigate()
    const [old_password, set_old_password] = useState("")
    const [new_password, set_new_password] = useState("")
    const [confirm_new_password, set_confirm_new_password] = useState("")
    const changePassword_Mutation = usePostApiUserChangePassword()

    return <>
        <Modal opened={props.opened} onClose={props.onClose} title="修改密碼">
            <TextInput
                type="password"
                label="舊密碼"
                withAsterisk
                value={old_password}
                onChange={(event) => set_old_password(event.target.value)}
            />
            <TextInput
                type="password"
                label="新密碼"
                withAsterisk
                value={new_password}
                onChange={(event) => set_new_password(event.target.value)}
            />
            <TextInput
                type="password"
                label="確認新密碼"
                withAsterisk
                value={confirm_new_password}
                onChange={(event) => set_confirm_new_password(event.target.value)}
            />
            <Button onClick={async () => {
                if (new_password != confirm_new_password) {
                    notifications.show({ message: "確認密碼不一致", color: "red" })
                    return;
                }
                auth.SetUpdating(true)
                try {
                    await changePassword_Mutation.mutateAsync({
                        data: {
                            oldPassword: old_password,
                            newPassword: new_password
                        }
                    })
                    var result = await deleteApiAuthLogout()
                    auth.Reset()
                    notifications.show({ message: "修改密碼成功，請重新登入" })
                    navigate({ to: "/" })
                    props.onClose()
                } catch (e) {
                    const error = e as AxiosError
                    if (error.code = "401")
                        notifications.show({ message: "舊密碼輸入錯誤", color: "red" })
                    else
                        notifications.show({ message: "修改失敗", color: "red" })
                }
                auth.SetUpdating(false)
            }}>更改</Button>
        </Modal>
    </>
}