import { AppShell, Text, Box, Burger, Group, Stack, Divider, Center, Image, Anchor, Flex } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Outlet, useNavigate, useMatchRoute, useRouterState, useSearch, Link } from '@tanstack/react-router';
import { useEffect } from 'react';
import { IconCategory, IconCertificate, IconChartBar, IconChartLine, IconClockHour3, IconHome, IconInfoCircle, IconKey, IconList, IconServer, IconSettings, IconStar } from '@tabler/icons-react';
import "App.css"
import banner from "Images/banner.png"
import { useAuthStore } from './Stores/AuthStore';
import { getApiAuthState, useGetInfo, useGetLicense } from './API';
import { LoginButton, LogoutButton } from './Auth/LoginPage';
import { NavigationButton } from './Components/NavigationButton';
import { AxiosError } from 'axios';
import { SystemStatusBadge } from './System/SystemStateBadge';
import { notifications } from '@mantine/notifications';
import { useSystemInfoStore } from './Stores/SystemInfoStore';

function AdminNavigation(props: { onClickLink?: () => void }) {
  return <>
    <NavigationButton match={{ to: "/admin/UserManager", fuzzy: true }} onClickLink={props.onClickLink}><IconList style={{ marginRight: "10px" }} />使用者管理</NavigationButton>
    <NavigationButton match={{ to: "/admin/TTSUsage", fuzzy: true }} onClickLink={props.onClickLink}><IconChartBar style={{ marginRight: "10px" }} />使用狀況查詢</NavigationButton>
    {/* <NavigationButton match={{ to: "/admin/LinePay", fuzzy: true }} onClickLink={props.onClickLink}><IconMoneybag style={{ marginRight: "10px" }} />LinePay查詢</NavigationButton> */}
    <NavigationButton match={{ to: "/admin/AudioToTextBalancer", fuzzy: true }} onClickLink={props.onClickLink}><IconServer style={{ marginRight: "10px" }} />外部服務狀態</NavigationButton>
    <NavigationButton match={{ to: "/admin/ServiceMetric", fuzzy: true }} onClickLink={props.onClickLink}><IconChartLine style={{ marginRight: "10px" }} />服務指數</NavigationButton>
    <NavigationButton match={{ to: "/admin/License", fuzzy: true }} onClickLink={props.onClickLink}><IconCertificate style={{ marginRight: "10px" }} />憑證管理</NavigationButton>
    <NavigationButton match={{ to: "/admin/Info", fuzzy: true }} onClickLink={props.onClickLink}><IconInfoCircle style={{ marginRight: "10px" }} />系統資訊</NavigationButton>
    <NavigationButton match={{ to: "/admin/Setting", fuzzy: true }} onClickLink={props.onClickLink}><IconSettings style={{ marginRight: "10px" }} />設定</NavigationButton>
  </>
}
function UserNavigation(props: { onClickLink?: () => void }) {
  return <>
    <NavigationButton match={{ to: "/workspace", fuzzy: true }} onClickLink={props.onClickLink}><IconCategory style={{ marginRight: "10px" }} />工作空間</NavigationButton>
    <NavigationButton match={{ to: "/AppKey", fuzzy: true }} onClickLink={props.onClickLink}><IconKey style={{ marginRight: "10px" }} />授權金鑰管理</NavigationButton>
    <NavigationButton match={{ to: "/PeriodCreditKey", fuzzy: true }} onClickLink={props.onClickLink}><IconStar style={{ marginRight: "10px" }} />訂閱管理</NavigationButton>
    <NavigationButton match={{ to: "/CreditKey", fuzzy: true }} onClickLink={props.onClickLink}><IconClockHour3 style={{ marginRight: "10px" }} />儲值時數管理</NavigationButton>
    <NavigationButton match={{ to: "/TTSUsage", fuzzy: true }} onClickLink={props.onClickLink}><IconChartBar style={{ marginRight: "10px" }} />使用查詢</NavigationButton>
    {/* <NavigationButton match={{ to: "/Payment", fuzzy: true }} onClickLink={props.onClickLink}><IconMoneybag style={{ marginRight: "10px" }} />訂單查詢</NavigationButton> */}
    <NavigationButton match={{ to: "/Settings", fuzzy: true }} onClickLink={props.onClickLink}><IconSettings style={{ marginRight: "10px" }} />個人設定</NavigationButton>
  </>
}

function Navigation(props: { onClickLink?: () => void }) {
  const auth = useAuthStore()
  const search = useSearch({ strict: false })
  return <>
    {/* <Button variant="subtle" onClick={() => { navigate({ to: "/" }); }}>首頁</Button> */}
    <Stack w="100%" gap={5}>
      <NavigationButton match={{ to: "/" }} ><IconHome style={{ marginRight: "10px" }} />首頁</NavigationButton>
      {auth.data.role == "admin" && <AdminNavigation onClickLink={props.onClickLink} />}
      {auth.data.role == "user" && <UserNavigation onClickLink={props.onClickLink} />}
      <Box pos="absolute" bottom={20} w="95%">
        <Divider my="lg" />
        <Center>
          <Text fw={700} style={{ fontSize: "20px" }} variant="gradient" mb={10} gradient={{ from: 'blue', to: 'cyan', deg: 90 }}>
            {auth.data.displayName}
          </Text>
        </Center>
        {!auth.data.userName ? <LoginButton variant="subtle" color="" redirect={search?.redirect as any} /> : <LogoutButton variant="light" color="gray" />}
      </Box>
    </Stack>
  </>
}
export default function App() {
  const auth = useAuthStore()
  const license = useGetLicense()
  const systemInfo = useGetInfo()
  const systemInfoStore = useSystemInfoStore()
  const router = useRouterState();
  const matchRoute = useMatchRoute()
  const navigate = useNavigate()
  const [mobileOpened, { toggle: toggleMobile }] = useDisclosure();
  const [desktopOpened, { toggle: toggleDesktop }] = useDisclosure(true);
  useEffect(() => {
    auth.SetUpdating(true)
    try {
      getApiAuthState().then(result => {
        if (result.data.userName && result.data.role)
          auth.Update(result.data)
      }).catch((e: AxiosError) => {
        var index = matchRoute({ to: "/" })
        var audioRecord = matchRoute({ to: "/workspace/$workspaceId/audioRecord/$audioRecordId" })
        var workspace = matchRoute({ to: "/workspace/$workspaceId" })
        var adminLogin = matchRoute({ to: "/admin/Login" })
        var nav = router.location.pathname.startsWith("/nav")
        if (e.status == 401 && !index && !adminLogin && !nav && !workspace && !audioRecord) {
          navigate({ to: "/", search: { redirect: router.location.pathname } })
        }
      })
    } catch (e) {

    }
    auth.SetUpdating(false)
  }, [])
  useEffect(() => {
    if (license.data?.data.validation) {
      if (!license.data?.data.validation.isAllValid) {
        notifications.show({ message: "憑證失效，請通知管理員更換憑證", color: "yellow" })
      }
    }
  }, [license.data?.data.validation?.isAllValid])
  useEffect(() => {
    if (systemInfo.data?.data) systemInfoStore.Update(systemInfo.data?.data)
  }, [systemInfo.data?.data])
  return (
    <>
      {router.location.pathname.startsWith("/nav") ? <Outlet /> :
        <AppShell
          header={{ height: 60 }}
          navbar={{
            width: 300,
            breakpoint: 'sm',
            collapsed: { mobile: !mobileOpened, desktop: !desktopOpened },
          }}
          padding="md"
        >
          <AppShell.Header>
            <Group h="100%" px="md">
              <Burger opened={mobileOpened} onClick={toggleMobile} hiddenFrom="sm" size="sm" />
              <Burger opened={desktopOpened} onClick={toggleDesktop} visibleFrom="sm" size="sm" />
              <Flex flex={1} direction="row" justify="start">
                <Link href='/'>
                  <Image h={50} fit="contain" src={banner} />
                </Link>
              </Flex>
            </Group>
            <Box pos="absolute" top={0} right={20} style={{ transform: "translateY(50%)" }}>
              <SystemStatusBadge />
            </Box>
          </AppShell.Header>
          <AppShell.Navbar py="md" px={4}>
            <Navigation onClickLink={() => {
              if (mobileOpened) toggleMobile();
            }} />
          </AppShell.Navbar>

          <AppShell.Main h="100vh">
            <Outlet />
          </AppShell.Main>
        </AppShell>}
    </>
  );
}