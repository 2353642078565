import * as R from "remeda"
import dayjs from "dayjs";
import { AudioToTextRecord, ServiceMetric } from "@/API";
import { Table } from "@mantine/core";

export function ServiceMetricListViewer(props: { data: ServiceMetric[] }) {
    return <>
        <Table>
            <Table.Thead>
                <Table.Tr>
                    <Table.Th>時間</Table.Th>
                    <Table.Th>剩餘Request限制</Table.Th>
                    <Table.Th>Request限制總額</Table.Th>
                    <Table.Th>訊息</Table.Th>
                </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
                {props.data.map((x, idx) => <Table.Tr key={`AudioToTextRecordListViewer_${idx}`}>
                    <Table.Td>{dayjs(x.timestamp).toDate().toLocaleString()}</Table.Td>
                    <Table.Td>{x.remainingRequests}</Table.Td>
                    <Table.Td>{x.limitRequests}</Table.Td>
                    <Table.Td>{x.message}</Table.Td>
                </Table.Tr>
                )}
            </Table.Tbody>
        </Table>
    </>
}