import * as R from "remeda";
import { v4 as uuidv4 } from 'uuid';
import { RichTextEditor, Link } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import SubScript from '@tiptap/extension-subscript';
import TextStyle from '@tiptap/extension-text-style';
import { Color } from '@tiptap/extension-color';
import { Markdown } from 'tiptap-markdown';
import ImageResize from 'tiptap-extension-resize-image';
import { Button, Flex, TextInput, Badge, FileButton, Modal, rem, Tooltip, Group } from '@mantine/core';
import { useBlocker, useParams } from '@tanstack/react-router';
import { getGetApiAudioRecordTextDocumentImageListQueryKey, getGetApiAudioRecordTextDocumentListQueryKey, putApiAudioRecordTextDocumentImageUpload, TextDocument, usePutApiAudioRecordTextDocumentUpdate } from '@/API';
import { notifications } from '@mantine/notifications';
import { useCallback, useEffect, useRef, useState } from "react";
import { IconAlbum, IconCheck, IconDeviceFloppy, IconDownload, IconEdit, IconEye, IconPhotoPlus, IconX } from "@tabler/icons-react";
import { useQueryClient } from "@tanstack/react-query";

import Collaboration from '@tiptap/extension-collaboration'
import CollaborationCursor from '@tiptap/extension-collaboration-cursor'
import * as Y from 'yjs'
import { SignalrProvider } from "./SignalrProvider/SignalrProvider";
import * as signalR from "@microsoft/signalr";
import { byteArrayToString, stringToByteArray } from "./SignalrProvider/encodingUtils";
import { useAuthStore } from "@/Stores/AuthStore";
import { get_random_hue_color_from_string } from "@/RandomColor";
import { UseSignalrState } from "@/Helper/UseSignalrState";
import { SignalrStateBadge } from "@/Components/SignalrStateBadge";
import { UseWorkspaceAuth } from "@/Workspaces/UseWorkspaceAuth";
import { useAudioRecordStateStore } from "../AudioRecordStateStore";
import { TextDocumentImageBox } from "./TextDocumentImageBox";
import { GetTextDocumentImageUrl } from "@/APIURL";
import { useDisclosure } from "@mantine/hooks";
import { usePDF } from 'react-to-pdf';
import { TextSaveToFile } from "@/Helper/SaveToFile";
import AudioRecordTime from "./AudioRecordTimeExtension";
import { AudioRecordTimeControl } from "./AudioRecordTimeExtension/AudioRecordTimeControl";

export function TextDocumentItem(props: { document: TextDocument }) {
    const queryClient = useQueryClient()
    const state = useAudioRecordStateStore()
    const [saving, setSaveing] = useState(false)
    const { workspaceId, audioRecordId } = useParams({ from: "/workspace/$workspaceId/audioRecord/$audioRecordId" })
    const update_Muation = usePutApiAudioRecordTextDocumentUpdate()
    const [editable, setEditable] = useState(false)
    const [name, setName] = useState(props.document.name ?? "")
    const auth = useAuthStore()
    const workspaceEditorAuth = UseWorkspaceAuth(workspaceId, "Editor")
    const yDoc = useRef<Y.Doc>(new Y.Doc())
    const provider = useRef<SignalrProvider>(new SignalrProvider(yDoc.current))
    const { SetConnection, connectionState } = UseSignalrState()
    const [opened_imageBox, { open: open_imageBox, close: close_imageBox }] = useDisclosure(false);
    const { toPDF, targetRef } = usePDF();
    useBlocker({
        blockerFn: () => window.confirm('確定要不保存離開嗎?'),
        condition: workspaceEditorAuth && state.needSave && editable,
    })
    useEffect(() => {
        state.SetNeedSave(false)
        yDoc.current = yDoc.current
        var connection = new signalR.HubConnectionBuilder()
            .withUrl("/textDocumentHub")
            .withAutomaticReconnect()
            .build();
        provider.current = new SignalrProvider(yDoc.current)
        provider.current.Initialize(connection, props.document.id!)
        if (provider.current._client)
            SetConnection(provider.current._client)
        return () => {
            provider.current?._client?.stop()
        }
    }, [])
    const editor = useEditor({
        editable: editable,
        extensions: [
            StarterKit,
            Markdown,
            Underline,
            Link,
            Superscript,
            SubScript,
            Highlight,
            TextAlign.configure({ types: ['heading', 'paragraph'] }),
            TextStyle,
            Color,
            AudioRecordTime,
            ImageResize.configure(),
            Collaboration.configure({
                document: yDoc.current,
            }),
            CollaborationCursor.configure({
                provider: provider.current,
                user: {
                    name: auth.data.displayName ? auth.data.displayName : auth.data.userName,
                    color: get_random_hue_color_from_string(50, 60, auth.data.displayName ? auth.data.displayName : auth.data.userName ?? "unknown"),
                },
            }),
        ],
        onUpdate: (e) => {
            if (e.editor.isInitialized)
                if (editor?.getJSON() != editor?.getJSON()) {
                    state.SetNeedSave(true)
                }
        },
        onPaste: (e) => {
            const items = e.clipboardData?.items;
            if (items)
                for (const item of items) {
                    if (item.type.startsWith('image/')) {
                        const file = item.getAsFile();
                        if (file) {
                            UploadImageFile(file)
                        }
                    }
                }
            navigator.clipboard.writeText('');
        }
    }, [props.document.id, provider.current, editable]);
    const [initEditor, setInitEditor] = useState(false)
    useEffect(() => {
        if (!initEditor) {
            if (props.document.rawTextType == "Yjs" && props.document.rawText) {
                Y.applyUpdate(yDoc.current, stringToByteArray(props.document.rawText))
            }
            else
                editor?.commands.setContent(props.document.text ?? "", false)
            setInitEditor(true)
        }
    }, [editor])
    const UploadImageFile = useCallback(async (imageFile: File) => {
        const id = notifications.show({
            loading: true,
            title: '上傳圖片',
            message: '正在上傳圖片...',
            autoClose: false,
            withCloseButton: false,
        });
        try {
            var file = new File([imageFile], uuidv4() + "." + imageFile.name.split('.').pop(), { type: imageFile.type })
            await putApiAudioRecordTextDocumentImageUpload({ imageFile: file }, { workspaceId: workspaceId, audioRecordId: audioRecordId })
            var url = GetTextDocumentImageUrl(workspaceId, audioRecordId, file.name)
            editor?.chain().focus().setImage({ src: url }).run()
            queryClient.invalidateQueries({
                queryKey: getGetApiAudioRecordTextDocumentImageListQueryKey({
                    workspaceId: workspaceId, audioRecordId: audioRecordId
                })
            })
            notifications.update({
                id,
                color: 'teal',
                title: '上傳圖片',
                message: '上傳圖片成功',
                icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
                loading: false,
                autoClose: 2000,
            });
        }
        catch (e) {
            notifications.update({
                id,
                color: 'red',
                title: '上傳圖片',
                message: '上傳圖片失敗',
                icon: <IconX style={{ width: rem(18), height: rem(18) }} />,
                loading: false,
                autoClose: 2000,
            });
        }

    }, [workspaceId, audioRecordId, editor])
    var Save = useCallback(async () => {
        if (saving) return;
        setSaveing(true)
        var markdown = editor?.storage.markdown.getMarkdown();
        var doc = R.clone(props.document)
        doc.textType = "Markdown"
        doc.text = markdown;
        doc.rawTextType = "Yjs"
        doc.rawText = byteArrayToString(Y.encodeStateAsUpdate(yDoc.current!))
        doc.name = name;
        try {
            await update_Muation.mutateAsync({ params: { workspaceId: workspaceId }, data: doc })
            await queryClient.invalidateQueries({ queryKey: getGetApiAudioRecordTextDocumentListQueryKey() })
            notifications.show({ message: "更新成功" })
            state.SetNeedSave(false)
            setEditable(false)
        } catch (e) {
            notifications.show({ message: "更新失敗", color: "red" })
        }
        setSaveing(false)
    }, [editor, saving, name, props.document, yDoc.current])
    return (<><Flex direction="column" h="100%" style={{ overflowY: "auto" }}>
        <SignalrStateBadge state={connectionState} pos="absolute" right={0} top={0} />
        {workspaceEditorAuth &&
            <Group align="center" justify="space-between">
                <Flex align="center">
                    <Tooltip openDelay={500} style={{ whiteSpace: "pre-line" }} multiline events={{ hover: true, focus: true, touch: false }} color="gray" label={`編輯器使用共編系統，因此如果所有使用者都關閉編輯器時，修改資料將會丟失。\n離開編輯模式將不會提醒保存資料，請務必隨時保存資料。`}>
                        <Button variant="light" leftSection={editable ? <IconEye /> : <IconEdit />} onClick={() => {
                            setEditable(x => !x)
                        }}>{editable ? "檢視模式" : "編輯模式"}</Button>
                    </Tooltip>
                    <Button loading={saving} variant="light" leftSection={<IconDeviceFloppy />} disabled={!state.needSave} onClick={async () => {
                        await Save()
                    }}>保存資料</Button>
                    {editable ?
                        <TextInput
                            placeholder="名稱"
                            value={name}
                            onChange={(event) => {
                                setName(event.currentTarget.value)
                                state.SetNeedSave(true)
                            }}
                        />
                        : <Badge tt="none" size="lg">{name}</Badge>}
                </Flex>
                <Flex align="center">
                    <Button radius="xl" size="compact-xs" onClick={async () => {
                        TextSaveToFile(`${props.document.name}.txt`, editor?.storage.markdown.getMarkdown())
                    }}>匯出文字<IconDownload size={14} style={{ marginLeft: "3px" }} /></Button>
                    <Button radius="xl" size="compact-xs" onClick={async () => {
                        toPDF({ filename: `${props.document.name}.pdf` })
                    }}>匯出PDF<IconDownload size={14} style={{ marginLeft: "3px" }} /></Button>
                </Flex>
            </Group>
        }
        <Flex direction="column" h="100%" style={{ overflowY: "auto" }} >
            <RichTextEditor editor={editor} style={{ border: editable ? "" : "0" }}>
                {editable && <>
                    <RichTextEditor.Toolbar sticky >
                        <RichTextEditor.ControlsGroup>
                            <RichTextEditor.Bold />
                            <RichTextEditor.Italic />
                            <RichTextEditor.Underline />
                            <RichTextEditor.Strikethrough />
                            <RichTextEditor.ClearFormatting />
                            <RichTextEditor.Highlight />
                            <RichTextEditor.Code />
                        </RichTextEditor.ControlsGroup>
                        <RichTextEditor.ControlsGroup>
                            <RichTextEditor.ColorPicker
                                colors={[
                                    '#25262b',
                                    '#868e96',
                                    '#fa5252',
                                    '#e64980',
                                    '#be4bdb',
                                    '#7950f2',
                                    '#4c6ef5',
                                    '#228be6',
                                    '#15aabf',
                                    '#12b886',
                                    '#40c057',
                                    '#82c91e',
                                    '#fab005',
                                    '#fd7e14',
                                ]}
                            />
                            <RichTextEditor.UnsetColor />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                            <RichTextEditor.H1 />
                            <RichTextEditor.H2 />
                            <RichTextEditor.H3 />
                            <RichTextEditor.H4 />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                            <RichTextEditor.Blockquote />
                            <RichTextEditor.Hr />
                            <RichTextEditor.BulletList />
                            <RichTextEditor.OrderedList />
                            <RichTextEditor.Subscript />
                            <RichTextEditor.Superscript />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                            <RichTextEditor.Link />
                            <RichTextEditor.Unlink />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                            <RichTextEditor.AlignLeft />
                            <RichTextEditor.AlignCenter />
                            <RichTextEditor.AlignJustify />
                            <RichTextEditor.AlignRight />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                            <RichTextEditor.Undo />
                            <RichTextEditor.Redo />
                        </RichTextEditor.ControlsGroup>
                        <RichTextEditor.ControlsGroup>
                            <AudioRecordTimeControl />
                        </RichTextEditor.ControlsGroup>
                        <RichTextEditor.ControlsGroup>
                            <RichTextEditor.Control title="圖片庫" onClick={() => {
                                open_imageBox()
                            }}>
                                <IconAlbum stroke={1.5} size="1rem" />
                            </RichTextEditor.Control>
                            <RichTextEditor.Control
                                title="上傳圖片"
                            >
                                <FileButton onChange={file => {
                                    if (file) UploadImageFile(file)
                                }} accept="image/png,image/jpeg">
                                    {(props) => <IconPhotoPlus {...props} stroke={1.5} size="1rem" />}
                                </FileButton>
                            </RichTextEditor.Control>
                        </RichTextEditor.ControlsGroup>
                    </RichTextEditor.Toolbar>
                </>}
                <RichTextEditor.Content ref={targetRef} />
            </RichTextEditor>
        </Flex>
    </Flex >
        <Modal opened={opened_imageBox} onClose={close_imageBox} title="圖片庫">
            <TextDocumentImageBox workspaceId={workspaceId} audioRecordId={audioRecordId} onClickFile={fileName => {
                var url = GetTextDocumentImageUrl(workspaceId, audioRecordId, fileName)
                editor?.chain().focus().setImage({ src: url }).run()
                close_imageBox()
            }} />
        </Modal>
    </>);
}