import { HubConnection, HubConnectionState } from "@microsoft/signalr";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";

export function UseSignalrState() {
    const _connection = useRef<HubConnection>();
    const [connectionState, setConnectionState] = useState<HubConnectionState>(HubConnectionState.Disconnected);
    const SetConnection = function (connection: HubConnection) {
        _connection.current = connection
        connection.onclose(() => setConnectionState(HubConnectionState.Disconnected))
        connection.onreconnected(() => setConnectionState(HubConnectionState.Connected))
        connection.onreconnecting(() => setConnectionState(HubConnectionState.Connecting))
    }
    useEffect(() => {
        var check: NodeJS.Timeout;
        if (connectionState != HubConnectionState.Connected) {
            check = setInterval(() => {
                if (_connection.current?.state) {
                    setConnectionState(_connection.current?.state)
                    if (_connection.current?.state == HubConnectionState.Connected)
                        clearInterval(check)
                }
            }, 200)
        }
        return () => {
            clearInterval(check)
        }
    }, [connectionState])
    return { SetConnection, connectionState }
}