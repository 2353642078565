import { AudioRecordBase, AudioRecordPublicPermission, getApiAudioRecordGet, getGetApiAudioRecordListQueryKey, getGetApiAudioRecordMediaSizeQueryKey, useDeleteApiAudioRecordDelete, useGetApiAudioRecordMediaSize, usePutApiAudioRecordUpdate, usePutApiAudioRecordUpdatePublicPermission, Workspace } from "@/API";
import { Text, Card, Group, Menu, ActionIcon, rem, Button, Modal, LoadingOverlay, Loader, Stack, Flex, Box } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconDots, IconEdit, IconEye, IconTrash } from "@tabler/icons-react";
import { EditAudioRecordNameForm } from "./EditAudioRecordNameForm";
import * as R from "remeda";
import { notifications } from "@mantine/notifications";
import { modals } from "@mantine/modals";
import { useQueryClient } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { UseWorkspaceAuth } from "../UseWorkspaceAuth";
import { BytesToString } from "@/Helper/string";
import { AudioRecordPublicPermssionForm } from "../AudioRecordPublicPermssionForm";
import { AudioRecordPublicPermissionBadge, AudioRecordPublicPermissionToLevel, GetAudioRecordPublicPermission } from "../AudioRecordPublicPermissionText";
import { ShareLink } from "@/Components/ShareLink";
import { GetAudioRecordUrl } from "@/APIURL";
export function AudioRecordListCard({ workspace, audioRecord, onEnter }: { workspace: Workspace, audioRecord: AudioRecordBase, onEnter: () => void }) {
    const queryClient = useQueryClient()
    const workspaceEditorAuth = UseWorkspaceAuth(audioRecord.workspaceId!, "Editor")
    const size = useGetApiAudioRecordMediaSize({ workspaceId: audioRecord.workspaceId!, audioRecordId: audioRecord.id! }, { query: { queryKey: getGetApiAudioRecordMediaSizeQueryKey({ workspaceId: audioRecord.workspaceId!, audioRecordId: audioRecord.id! }), staleTime: 60 * 1000 } })
    const deleteAudioRecord_Mutation = useDeleteApiAudioRecordDelete()
    const [deleteAudioRecordLoading, setDeleteAudioRecordLoading] = useState(false)
    const [updateAudioRecordLoading, setUpdateAudioRecordLoading] = useState(false)
    const updateAudioRecord_Mutation = usePutApiAudioRecordUpdate()
    const [editName_opened, { open: editName_open, close: editName_close }] = useDisclosure(false);
    const editPermission_Mutation = usePutApiAudioRecordUpdatePublicPermission()
    const [editPermission_opened, { open: editPermission_open, close: editPermission_close }] = useDisclosure(false);
    const publicPermission = useMemo(() => {
        return GetAudioRecordPublicPermission(audioRecord.publicPermission ?? "None", workspace.publicPermission ?? "None")
    }, [audioRecord.publicPermission, workspace.publicPermission])
    const useWorkspacePublicPermission = useMemo(() => {
        return AudioRecordPublicPermissionToLevel(workspace.publicPermission ?? "None") > AudioRecordPublicPermissionToLevel(audioRecord.publicPermission ?? "None")
    }, [audioRecord.publicPermission, workspace.publicPermission])
    return <Card w={400} shadow="sm" padding="lg" pt={5} radius="md" withBorder>
        <LoadingOverlay
            visible={deleteAudioRecordLoading || updateAudioRecordLoading}
            zIndex={1000}
            loaderProps={{ type: 'bars' }}
        />
        <Group justify="space-between" w="100%">
            <Stack mt="md" mb="xs" w="100%" gap={0}>
                <Group align="center" gap={5} pr={20}>
                    <Text style={{ flexShrink: "1" }} fw={500} truncate="end">{audioRecord.name}</Text>
                    {publicPermission && publicPermission != "None" &&
                        <ShareLink url={window.origin + GetAudioRecordUrl(audioRecord.workspaceId!, audioRecord.id!)} >
                            <AudioRecordPublicPermissionBadge permission={publicPermission!} badgeProps={{ style: { cursor: "pointer" } }} />
                        </ShareLink>
                    }
                </Group>
                <Text size="sm" c="gray">創建時間: {new Date(audioRecord.createdTimestamp! * 1000).toLocaleString()}</Text>
                {
                    size.data?.data != undefined ? <Text size="sm" c="gray">媒體大小: {BytesToString(size.data?.data)}</Text> : <Loader size="xs" />
                }
            </Stack>
            {workspaceEditorAuth &&
                <Box pos="absolute" right={10} top={10}>
                    <Menu withinPortal position="bottom-end" shadow="sm" >
                        <Menu.Target>
                            <ActionIcon variant="subtle" color="gray" pos="absolute" right={10} top={10}>
                                <IconDots style={{ width: rem(16), height: rem(16) }} />
                            </ActionIcon>
                        </Menu.Target>
                        <Menu.Dropdown>
                            <Menu.Item
                                leftSection={<IconEdit style={{ width: rem(14), height: rem(14) }} />}
                                color="var(--mantine-primary-color-filled)"
                                onClick={() => {
                                    editName_open()
                                }}
                            >
                                修改名稱
                            </Menu.Item>
                            <Menu.Item
                                leftSection={<IconEye style={{ width: rem(14), height: rem(14) }} />}
                                color="var(--mantine-primary-color-filled)"
                                onClick={editPermission_open}
                            >
                                修改公開權限
                            </Menu.Item>
                            <Menu.Item
                                leftSection={<IconTrash style={{ width: rem(14), height: rem(14) }} />}
                                color="red"
                                onClick={() => {
                                    modals.openConfirmModal({
                                        title: '刪除錄音紀錄',
                                        children: (
                                            <Text size="sm">
                                                是否要刪除錄音紀錄 {audioRecord.name ?? ""}?
                                            </Text>
                                        ),
                                        labels: { confirm: '刪除', cancel: '取消' },
                                        confirmProps: { color: 'red', loading: deleteAudioRecordLoading },
                                        onConfirm: async () => {
                                            setDeleteAudioRecordLoading(true)
                                            try {
                                                if (audioRecord.id && audioRecord.workspaceId) {
                                                    await deleteAudioRecord_Mutation.mutateAsync({ params: { workspaceId: audioRecord.workspaceId, audioRecordId: audioRecord.id } })
                                                    notifications.show({ message: "刪除成功" })
                                                    queryClient.invalidateQueries({ queryKey: getGetApiAudioRecordListQueryKey() })
                                                }
                                            } catch (ex) {
                                                notifications.show({ message: "刪除失敗", color: "red" })
                                            }
                                            setDeleteAudioRecordLoading(false)
                                        },
                                    });
                                }}
                            >
                                刪除
                            </Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                </Box>
            }
        </Group>
        <Button fullWidth radius="md" onClick={() => {
            onEnter()
        }}>
            開啟
        </Button>
        <Modal title="修改名稱" opened={editName_opened} onClose={editName_close}>
            <EditAudioRecordNameForm initValue={audioRecord.name ?? ""} onSubmit={async v => {
                editName_close()
                setUpdateAudioRecordLoading(true)
                try {
                    if (audioRecord.workspaceId && audioRecord.id) {
                        var record = await getApiAudioRecordGet({ workspaceId: audioRecord.workspaceId, audioRecordId: audioRecord.id })
                        var data = R.clone(record.data)
                        data.name = v
                        if (data.workspaceId) {
                            try {
                                await updateAudioRecord_Mutation.mutateAsync({ params: { workspaceId: data.workspaceId }, data: data })
                                notifications.show({ message: "修改成功" })
                                queryClient.invalidateQueries({ queryKey: getGetApiAudioRecordListQueryKey() })
                            } catch (e) {
                                notifications.show({ message: "修改失敗", color: "red" })
                            }
                        }
                    }
                }
                catch (e) {

                }
                setUpdateAudioRecordLoading(false)
            }} />
        </Modal>
        <Modal opened={editPermission_opened} onClose={editPermission_close} title="修改公開權限" centered>
            {useWorkspacePublicPermission && <Text c="gray">
                已繼承工作空間公開權限
            </Text>}
            <AudioRecordPublicPermssionForm initValue={audioRecord.publicPermission ?? "None"} options={["None", "View"]} onConfirm={async (p) => {
                editPermission_close();
                setUpdateAudioRecordLoading(true)
                try {
                    if (audioRecord.workspaceId && audioRecord.id) {
                        try {
                            await editPermission_Mutation.mutateAsync({ params: { workspaceId: audioRecord.workspaceId, audioRecordId: audioRecord.id, permission: p } })
                            notifications.show({ message: "修改成功" })
                            queryClient.invalidateQueries({ queryKey: getGetApiAudioRecordListQueryKey() })
                        } catch (e) {
                            notifications.show({ message: "修改失敗", color: "red" })
                        }
                    }
                }
                catch (e) {

                }
                setUpdateAudioRecordLoading(false)
            }} />
        </Modal>
    </Card>
}