import { AudioRecord, AudioRecordEntry, getGetApiAudioRecordGetQueryKey, usePutApiAudioRecordUpdate } from "@/API"
import { Button, Group, Modal, TextInput } from "@mantine/core"
import { useForm } from "@mantine/form"
import { useDisclosure } from "@mantine/hooks"
import { notifications } from "@mantine/notifications"
import { useQueryClient } from "@tanstack/react-query"
import { useMemo, useState } from "react"
import * as R from "remeda"

export function EditRecordAllSpeakersForm(props: { audioRecord: AudioRecord, readOnly?: boolean }) {
    const queryClient = useQueryClient()
    const update_Mutation = usePutApiAudioRecordUpdate()
    const [opened, { open, close }] = useDisclosure(false);
    const [editLoading, setEditLoading] = useState(false)
    const [selectSpeaker, setSelectSpeaker] = useState("")
    const [editSpeaker, setTargetSpeaker] = useState("")
    var speakers = useMemo(() => {
        return R.unique(R.flatMap(props.audioRecord.recordEntries ?? [], x => x.speakers).map(x => x ?? "")).filter(x => !!x)
    }, [props.audioRecord])
    return <>
        {speakers.map((speaker, idx) => <Button key={`speaker_${idx}`} color="orange" radius="xl" size="compact-xs" onClick={() => {
            if (props.readOnly) return;
            setSelectSpeaker(speaker)
            setTargetSpeaker(speaker)
            open()
        }}>
            {speaker}
        </Button>)}
        <Modal opened={opened} onClose={close} title="修改語者">
            <TextInput value={editSpeaker} onChange={(event) => setTargetSpeaker(event.currentTarget.value)}
            />
            <Group justify="flex-end" mt="md">
                <Button loading={editLoading} onClick={async () => {
                    if (selectSpeaker.trim() == editSpeaker.trim()) return;
                    if (props.audioRecord.id && props.audioRecord.workspaceId) {
                        setEditLoading(true)
                        try {
                            var data = R.clone(props.audioRecord)
                            data.recordEntries?.forEach(entry => {
                                if (entry.speakers) {
                                    for (var i = 0; i < entry.speakers.length; i++) {
                                        if (entry.speakers[i] == selectSpeaker) {
                                            entry.speakers[i] = editSpeaker.trim()
                                        }
                                    }
                                    entry.speakers = entry.speakers.filter(x => !!x);
                                }
                            })
                            await update_Mutation.mutateAsync({ data: data, params: { workspaceId: props.audioRecord.workspaceId } })
                            notifications.show({ message: "修改成功" })
                            queryClient.invalidateQueries({ queryKey: getGetApiAudioRecordGetQueryKey({ workspaceId: props.audioRecord.workspaceId, audioRecordId: props.audioRecord.id }) })
                            close()
                        } catch {
                            notifications.show({ message: "修改失敗", color: "red" })
                        }
                        setEditLoading(false)
                    }
                }}>確定</Button>
            </Group>
        </Modal>
    </>
}