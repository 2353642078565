import { useForm } from '@mantine/form';
import {
    TextInput,
    PasswordInput,
    Text,
    Group,
    Button,
    Stack,
    ButtonProps,
    Collapse,
    Modal
} from '@mantine/core';
import { deleteApiAuthLogout, getGetApiSocialAuthGoogleQueryKey, postApiAuthLogin } from '@/API';
import { useAuthStore } from '@/Stores/AuthStore';
import { modals, openContextModal } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import { IconBrandGoogleFilled, IconLogin, IconLogout, IconUser } from '@tabler/icons-react';
import { RegisteredRouter, RoutePaths, NavigateOptions, useNavigate, useSearch, redirect } from '@tanstack/react-router';
import { useDisclosure } from '@mantine/hooks';
import { useEffect } from 'react';
import { useSystemInfoStore } from '@/Stores/SystemInfoStore';

export function LogoutButton(props?: ButtonProps & { navigateoptions?: NavigateOptions }) {
    const auth = useAuthStore()
    const navigate = useNavigate()
    return <>
        <Button w="100%" onClick={() => {
            modals.openConfirmModal({
                title: '登出確認',
                children: (
                    <Text size="sm">
                        確定登出?
                    </Text>
                ),
                labels: { confirm: '確定', cancel: '取消' },
                confirmProps: { color: 'red' },
                onConfirm: async () => {
                    try {
                        var result = await deleteApiAuthLogout()
                        auth.Reset()
                        notifications.show({
                            message: '登出成功',
                        })
                        navigate(props?.navigateoptions ?? { to: "/" })
                    } catch (e) {

                    }
                },
            });
        }} {...props}><IconLogout style={{ marginRight: "10px" }} />登出</Button>
    </>
}

export function LoginButton(props?: ButtonProps & { redirect?: RoutePaths<RegisteredRouter["routeTree"]> }) {
    const [opened, { open, close }] = useDisclosure(false);
    useEffect(() => {
        if (props?.redirect)
            open()
    }, [props?.redirect])
    return <>
        <Modal opened={opened} onClose={close} title={<Text fw={700} style={{ fontSize: "20px" }} variant="gradient" mb={10} gradient={{ from: 'blue', to: 'cyan', deg: 90 }}>快可錄 - 登入</Text>}>
            <LoginPage redirect={props?.redirect} />
        </Modal>
        <Button w="100%" onClick={() => {
            open();
        }} {...props} ><IconLogin style={{ marginRight: "10px" }} />登入</Button>
    </>
}
export function LoginPage(props: { redirect?: RoutePaths<RegisteredRouter["routeTree"]>, onLogin?: () => void }) {
    const auth = useAuthStore()
    const infoStore = useSystemInfoStore()
    const form = useForm({
        initialValues: {
            userName: '',
            password: ''
        },
        validate: {
            // password: (val) => (val.length <= 6 ? 'Password should include at least 6 characters' : null),
        },
    });
    const navigate = useNavigate()
    const [opened, { toggle }] = useDisclosure(false);
    return (
        <>
            <Stack mb="md" mt="md">
                {infoStore.info.googleAuth &&
                    <Button onClick={async () => {
                        var base = getGetApiSocialAuthGoogleQueryKey({ redirect: props.redirect ?? "/" })
                        window.location.href = `${base[0]}?redirect=${base[1].redirect}`;
                    }} variant="light" leftSection={<IconBrandGoogleFilled />}>Google登入</Button>}
                {/* <Button variant="light" leftSection={<IconBrandAppleFilled />}>Apple</Button> */}
                <Button onClick={async () => {
                    toggle()
                }} variant="light" leftSection={<IconUser />}>帳號密碼登入</Button>
            </Stack>
            {/* <Divider labelPosition="center" my="lg" /> */}
            <Collapse in={opened} transitionTimingFunction="linear">
                <form onSubmit={form.onSubmit(async (x) => {
                    auth.SetUpdating(true)
                    try {
                        var result = await postApiAuthLogin(x)
                        if (result.data.userName && result.data.role) {
                            auth.Update(result.data)
                            notifications.show({
                                message: '登入成功',
                            })
                            props.onLogin?.()
                            if (props.redirect) navigate({ to: props.redirect })
                        }
                    } catch (e) {
                        notifications.show({
                            message: '登入失敗', color: "red"
                        })
                        console.log(e)
                    }
                    auth.SetUpdating(false)
                })}>
                    <Stack>
                        <TextInput
                            required
                            label="使用者名稱"
                            placeholder="Your username"
                            value={form.values.userName}
                            onChange={(event) => form.setFieldValue('userName', event.currentTarget.value)}
                            radius="md"
                        />

                        <PasswordInput
                            required
                            label="使用者密碼"
                            placeholder="Your password"
                            value={form.values.password}
                            onChange={(event) => form.setFieldValue('password', event.currentTarget.value)}
                            error={form.errors.password && 'Password should include at least 6 characters'}
                            radius="md"
                        />
                    </Stack>

                    <Group justify="space-between" mt="xl">
                        <Button type="submit" radius="xl">
                            登入
                        </Button>
                    </Group>
                </form>
            </Collapse>
        </>
    );
}