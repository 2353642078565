import * as R from "remeda"
import dayjs from "dayjs";
import { AudioToTextRecord } from "@/API";
import { Table } from "@mantine/core";

export function AudioToTextRecordListViewer(props: { data: AudioToTextRecord[] }) {
    return <>
        <Table>
            <Table.Thead>
                <Table.Tr>
                    <Table.Th>時間</Table.Th>
                    <Table.Th>音檔長度(秒)</Table.Th>
                    <Table.Th>回應時間(秒)</Table.Th>
                    <Table.Th>STT類別</Table.Th>
                    <Table.Th>回應狀態</Table.Th>
                    <Table.Th>錯誤訊息</Table.Th>
                </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
                {props.data.map((x, idx) => <Table.Tr key={`AudioToTextRecordListViewer_${idx}`}>
                    <Table.Td>{dayjs(x.timestamp).toDate().toLocaleString()}</Table.Td>
                    <Table.Td>{R.round(x.duration ?? -1, 1)}</Table.Td>
                    <Table.Td>{R.round(x.responseTime ?? -1, 1)}</Table.Td>
                    <Table.Td>{x.metadata?.sttType}</Table.Td>
                    <Table.Td>{x.metadata?.responseType}</Table.Td>
                    <Table.Td>{x.errorMessage}</Table.Td>
                </Table.Tr>
                )}
            </Table.Tbody>
        </Table>
    </>
}