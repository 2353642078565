import App from '@/App'
import { AuthStoreState } from '@/Stores/AuthStore'
import { useInterval } from '@mantine/hooks'
import { createRootRoute, createRootRouteWithContext, Link, Outlet, redirect } from '@tanstack/react-router'
import { TanStackRouterDevtools } from '@tanstack/router-devtools'
import { z } from 'zod'
interface MyRouterContext {
  // The ReturnType of your useAuth hook or the value of your AuthContext
  authStore: AuthStoreState
}
const rootSearchSchema = z.object({
  redirect: z.string().optional().catch(''),
})
export const Route = createRootRouteWithContext<MyRouterContext>()({
  component: () => (
    <>
      <App />
      {/* <TanStackRouterDevtools /> */}
    </>
  ),
  validateSearch: (search) => rootSearchSchema.parse(search),
  // beforeLoad: ({ context, location }) => {
  //   if (location.pathname == "/" || location.pathname == "/admin/login") return;
  //   if (!context.authStore.data.userName) {
  //     throw redirect({
  //       to: '/',
  //       search: {
  //         redirect: location.href,
  //       },
  //     })
  //   }
  // },
})