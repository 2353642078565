import { getApiWorkspaceUserGetRole, getGetApiAudioRecordListQueryKey, useGetApiAudioRecordList, useGetApiWorkspaceGet } from "@/API";
import { Box, Group, Text, LoadingOverlay, Pagination, Flex, Button, ActionIcon, Breadcrumbs, Tooltip, } from "@mantine/core";
import { Link, useLocation, useNavigate, useParams } from "@tanstack/react-router";
import { useEffect, useMemo, useState } from "react";
import { AudioRecordListCard } from "./AudioRecordListCard";
import { WorkspaceSearch } from "../Search";
import { CreateAudioRecordButton } from "./CreateAudioRecordButton";
import { IconSettings, IconShare } from "@tabler/icons-react";
import { UseWorkspaceAuth } from "../UseWorkspaceAuth";
import { RequestWorkspaceAuthPage } from "./RequestWorkspaceAuthPage";
import { ShareLink } from "@/Components/ShareLink";
import { CompareAudioRecordPublicPermission } from "../AudioRecordPublicPermissionText";
import { useAuthStore } from "@/Stores/AuthStore";

export function WorkspaceItem() {
    const navigate = useNavigate()
    const location = useLocation()
    const auth = useAuthStore()
    const [activePage, setPage] = useState(1);
    const pageSize = 12;
    const { workspaceId } = useParams({ from: "/workspace/$workspaceId/" })
    const managerAuth = UseWorkspaceAuth(workspaceId, "Manager")
    const viewerAuth = UseWorkspaceAuth(workspaceId, "Viewer")
    const editorAuth = UseWorkspaceAuth(workspaceId, "Editor")
    const workspace = useGetApiWorkspaceGet({ id: workspaceId })
    const publicPermission = useMemo(() => {
        return workspace.data?.data.publicPermission ?? "None"
    }, [workspace.data?.data.publicPermission])
    const audioRecordBaseList = useGetApiAudioRecordList({ workspaceId: workspaceId, skip: (activePage - 1) * pageSize, limit: pageSize }, {
        query: {
            queryKey: getGetApiAudioRecordListQueryKey({ workspaceId: workspaceId, skip: (activePage - 1) * pageSize, limit: pageSize }),
            enabled: viewerAuth || CompareAudioRecordPublicPermission(publicPermission, "View")
        }
    })

    return <Box pos="relative" h="100%">
        <LoadingOverlay
            visible={audioRecordBaseList.isFetching}
            zIndex={1000}
            loaderProps={{ type: 'bars' }}
        />
        <Flex>
            <Breadcrumbs className="no-underline">
                {auth.data.userName &&
                    <Link to="/workspace">
                        <Text c="gray">
                            工作空間列表
                        </Text>
                    </Link>
                }
                <Text fw={500} c="var(--mantine-primary-color-filled)">
                    {workspace.data?.data.name}
                </Text>
            </Breadcrumbs>
            {(managerAuth) &&
                <Tooltip label="工作空間設定" color="gray">
                    <ActionIcon variant="subtle" size="md" ml="5px" onClick={() => navigate({
                        to: "/workspace/$workspaceId/settings/users", params: {
                            workspaceId: workspaceId
                        }
                    })}>
                        <IconSettings style={{ width: '100%', height: '100%' }} stroke={1.5} />
                    </ActionIcon>
                </Tooltip>
            }
        </Flex>
        {(viewerAuth || CompareAudioRecordPublicPermission(publicPermission, "View")) ? <>
            <Group>
                <Pagination total={Math.ceil((audioRecordBaseList.data?.data.total ?? 0) / pageSize)} value={activePage} onChange={setPage} siblings={1} defaultValue={10} />
                <WorkspaceSearch workspaceIds={[workspaceId]} />
                <ShareLink url={window.origin + location.pathname} >
                    <Button leftSection={<IconShare />} >分享</Button>
                </ShareLink>

            </Group>
            {editorAuth &&
                <CreateAudioRecordButton workspaceId={workspaceId} >
                    <Button mt={10}>
                        新增文件
                    </Button>
                </CreateAudioRecordButton>
            }
            <Flex mt={10} gap={10} style={{ flexWrap: "wrap" }}>
                {audioRecordBaseList.data?.data.results?.map((d, idx) => {
                    return <AudioRecordListCard key={`AudioRecordListCard_${idx}`} workspace={workspace.data?.data!} audioRecord={d} onEnter={() => {
                        if (d.id) navigate({
                            to: "/workspace/$workspaceId/audioRecord/$audioRecordId", params: {
                                workspaceId: workspaceId,
                                audioRecordId: d.id
                            }
                        })
                    }} />

                })}
            </Flex ></> : <>
            <RequestWorkspaceAuthPage workspaceId={workspaceId} />
        </>}
    </Box>
}