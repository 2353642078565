import { getApiAuthState, usePutApiUserChangeDisplayName } from "@/API";
import { useAuthStore } from "@/Stores/AuthStore";
import { Button, Modal, TextInput } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { useEffect, useState } from "react";
import { stringToPath } from "remeda";

export function ChangeDisplayNameDialog(props: { opened: boolean, onClose: () => void }) {
    const auth = useAuthStore();
    const [name, setName] = useState("")
    const changeDisplayName_Mutation = usePutApiUserChangeDisplayName()
    useEffect(() => {
        setName(auth.data.displayName ?? "")
    }, [props.opened])
    return <>
        <Modal opened={props.opened} onClose={props.onClose} title="更改名稱">
            <TextInput
                value={name!}
                onChange={(event) => setName(event.target.value)}
            />
            <Button onClick={async () => {
                auth.SetUpdating(true)
                try {
                    await changeDisplayName_Mutation.mutateAsync({
                        params: {
                            newDisplayName: name
                        }
                    })
                    var result = await getApiAuthState()
                    if (result.data.userName && result.data.role)
                        auth.Update(result.data)

                    notifications.show({ message: "更改成功" })
                    props.onClose()
                } catch (e) {
                    notifications.show({ message: "更改失敗", color: "red" })
                }
                auth.SetUpdating(false)
            }}>更改</Button>
        </Modal>
    </>
}