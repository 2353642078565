import { AudioRecordPublicPermission } from "@/API";
import { Button, Group, Modal, Select } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { PropsWithChildren } from "react";
import { AudioRecordPublicPermissionText } from "./AudioRecordPublicPermissionText";

export function AudioRecordPublicPermssionForm(props: { initValue: AudioRecordPublicPermission, options: AudioRecordPublicPermission[], onConfirm: (value: AudioRecordPublicPermission) => void }) {
    const form = useForm<{ value: AudioRecordPublicPermission }>({
        mode: 'uncontrolled',
        initialValues: {
            value: props.initValue,
        },
    });
    return <form onSubmit={form.onSubmit((values) => props.onConfirm(values.value))}>
        <Select
            label="權限"
            allowDeselect={false}
            data={props.options.map(x => {
                return { value: x, label: AudioRecordPublicPermissionText(x) }
            })}
            {...form.getInputProps("value")}
        />
        <Group justify="flex-end" mt="md">
            <Button type="submit">確認</Button>
        </Group>
    </form>
}