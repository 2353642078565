import { SystemSetting, useGetApiAdminSystemSettingGet, useGetApiAdminSystemTotalMediaSize, usePutApiAdminSystemSettingUpdate } from "@/API"
import { BytesToString, SecondToString } from "@/Helper/string"
import { Button, Group, Loader, Modal, NumberInput, Paper, Text } from "@mantine/core"
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

export function SystemSettingIndex() {
    const queryClient = useQueryClient()
    var setting = useGetApiAdminSystemSettingGet()
    var update_Muation = usePutApiAdminSystemSettingUpdate()
    const [opened, { open, close }] = useDisclosure(false);
    const form = useForm<SystemSetting>({
        mode: 'uncontrolled'
    });
    return <>
        <Button onClick={() => {
            form.setValues(setting.data?.data!)
            open();
        }}>
            設定
        </Button>
        <Paper withBorder shadow="xs" p="md">
            <Text>新帳號預設時數</Text>
            <Text>
                {(setting.data?.data.newAccountBalance ?? 0) / 3600}小時
            </Text>
            <Text>新帳號預設時數到期時間</Text>
            <Text>
                {setting.data?.data.newAccountBalanceExpiryDays}天
            </Text>
        </Paper>
        <Modal opened={opened} onClose={close} title="設定" centered>
            <form onSubmit={form.onSubmit(async (values) => {
                try {
                    values.id = ""
                    await update_Muation.mutateAsync({ data: values })
                    queryClient.invalidateQueries({ queryKey: setting.queryKey })
                    notifications.show({ message: "設定成功" })
                    close()
                }
                catch (e) {
                    notifications.show({ message: "設定失敗", color: "red" })
                }
            })}>
                <NumberInput
                    label="新帳號預設時數"
                    suffix="小時"
                    defaultValue={form.getInputProps('newAccountBalance').defaultValue / 3600}
                    onChange={x => form.getInputProps('newAccountBalance').onChange((x as number) * 3600)}
                />
                <NumberInput
                    label="新帳號預設時數到期時間"
                    suffix="天"
                    {...form.getInputProps('newAccountBalanceExpiryDays')}
                />

                <Group justify="flex-end" mt="md">
                    <Button type="submit">確定</Button>
                </Group>
            </form>
        </Modal>
    </>
}
