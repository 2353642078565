import { PostApiWorkspaceCreateParams } from '@/API';
import { Button, Checkbox, Group, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';

export function EditWorkspaceForm(props: { onSubmit: (data: PostApiWorkspaceCreateParams) => void }) {
    const form = useForm<PostApiWorkspaceCreateParams>({
        mode: 'uncontrolled',
        initialValues: {
            name: ""
        }
    });

    return (
        <form onSubmit={form.onSubmit((values) => {
            props.onSubmit(values);
        })}>
            <TextInput
                withAsterisk
                label="名稱"
                placeholder="Workspace name"
                key={form.key('name')}
                {...form.getInputProps('name')}
            />
            <Group justify="flex-end" mt="md">
                <Button type="submit">創建</Button>
            </Group>
        </form>
    );
}