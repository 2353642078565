import * as R from "remeda";
import { AudioRecordEntry, getApiAudioRecordGet, TextDocument, usePostApiAudioRecordTextDocumentCreate, usePostApiTextSummarization, usePutApiAudioRecordTextDocumentUpdate } from "@/API"
import { Box } from "@mantine/core"
import { PropsWithChildren, useRef } from "react"
import * as Y from 'yjs'
import { byteArrayToString } from "./SignalrProvider/encodingUtils";
import { useEditor } from "@tiptap/react";
import { RichTextEditor } from "@mantine/tiptap";
import StarterKit from '@tiptap/starter-kit';
import Collaboration from "@tiptap/extension-collaboration";
import { Markdown } from "tiptap-markdown";
import AudioRecordTime from "./AudioRecordTimeExtension";
import { AxiosError } from "axios";

export function CreateSummaryButton(props: { workspaceId: string, audioRecordId: string, onStart?: () => void, onEnd?: (success: boolean, msg: string, doc?: TextDocument) => void } & PropsWithChildren) {
    const create_Mutation = usePostApiAudioRecordTextDocumentCreate()
    const update_Mutation = usePutApiAudioRecordTextDocumentUpdate()
    const summarization_Mutation = usePostApiTextSummarization({ axios: { headers: { "Content-Type": "application/json" } } })
    const yDoc = useRef<Y.Doc>(new Y.Doc())
    const editor = useEditor({
        extensions: [
            StarterKit,
            Markdown.configure({
                html: true
            }),
            AudioRecordTime,
            Collaboration.configure({
                document: yDoc.current,
            }),
        ],
    });
    return <>
        <RichTextEditor hidden={true} editor={editor}>
            <>
            </>
        </RichTextEditor>
        <Box onClick={async () => {
            props.onStart?.()
            var record = await getApiAudioRecordGet({ workspaceId: props.workspaceId, audioRecordId: props.audioRecordId })
            var splitData = SplitAudioRecordEntries(record.data.recordEntries!)

            if (record.data.recordEntries?.length == 0) {
                props.onEnd?.(false, "無轉錄內容", undefined)
                return;
            }
            editor?.commands.setContent("")
            editor?.commands.insertContent(`## ${record.data.name} 摘要`)
            console.log(editor?.state.doc.content.size)

            try {
                for (var i = 0; i < splitData.length; i++) {
                    var summarization_result = await summarization_Mutation.mutateAsync({ data: splitData[i].text })
                    editor?.commands.insertContentAt(editor?.state.doc.content.size, `<AudioRecordTime time=${splitData[i].time + 0.1} />`)
                    editor?.commands.insertContentAt(editor?.state.doc.content.size, summarization_result.data)
                }
            }
            catch (e) {
                const error = e as AxiosError
                var message = ""
                if (typeof error.response?.data == "string")
                    message = error.response?.data as string
                props.onEnd?.(false, message, undefined)
                return;
            }
            var createResult = await create_Mutation.mutateAsync({ params: { name: "摘要", workspaceId: props.workspaceId, audioRecordId: props.audioRecordId } })
            var doc = R.clone(createResult.data)
            doc.textType = "Markdown"
            doc.text = editor?.storage.markdown.getMarkdown();
            doc.rawTextType = "Yjs"
            doc.rawText = byteArrayToString(Y.encodeStateAsUpdate(yDoc.current))

            var update = await update_Mutation.mutateAsync({ params: { workspaceId: props.workspaceId }, data: doc })
            props.onEnd?.(true, "", doc)
        }} >{props.children}</Box >

    </>
}

type SplitAudioRecordEntriesResult = {
    time: number,
    text: string,
}
function SplitAudioRecordEntries(entries: AudioRecordEntry[], intervalThreshold = 5, maxTextCount = 1000) {
    if (entries.length == 0)
        return []
    var results: SplitAudioRecordEntriesResult[] = []
    var target: SplitAudioRecordEntriesResult = {
        time: entries[0].time!,
        text: ""
    }
    var lastTime = entries[0].time!
    for (var i = 0; i < entries.length; i++) {
        const entry = entries[i]
        if (entry.time! - lastTime > intervalThreshold || (target.text + entry.text).length > maxTextCount) {
            results.push(target)
            target = {
                time: entry.time!,
                text: ""
            }
        }
        lastTime = entry.time! + entry.duration!
        target.text += entry.text + "\n"
    }
    if (target.text) {
        results.push(target)
    }
    return results
}