import { AuthState, AuthStateRole } from '@/API'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

export interface AudioRecordStateStore {
    needSave: boolean,
    SetNeedSave: (value: boolean) => void,
    targetTime: number,
    SetTargetTime: (time: number) => void
    playerTime: number,
    SetPlayerTime: (time: number) => void
}
export const useAudioRecordStateStore = create<AudioRecordStateStore>()(
    devtools(
        immer((set) => ({
            needSave: false,
            SetNeedSave: (value: boolean) =>
                set((state) => {
                    state.needSave = value
                }),
            targetTime: -1,
            SetTargetTime: (time: number) =>
                set((state) => {
                    state.targetTime = time
                }),
            playerTime: 0,
            SetPlayerTime: (time: number) =>
                set((state) => {
                    state.playerTime = time
                }),
        }))),
)