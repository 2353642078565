import { UserPasswordViewModel, getGetApiAdminUserManagerListUsersQueryKey, usePostApiAdminUserManagerCreateUser, } from "@/API";
import { Button, Group, Modal, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { useQueryClient } from "@tanstack/react-query";

export function CreateUserDialog(props: { opened: boolean, onClose: () => void }) {
    const queryClient = useQueryClient()
    const CreateUser_Mutation = usePostApiAdminUserManagerCreateUser()
    const form = useForm<UserPasswordViewModel>({
        initialValues: {
            userName: '',
            password: "",
        },
    });
    return <>
        <Modal opened={props.opened} onClose={props.onClose} title="新增使用者">
            <form onSubmit={form.onSubmit(async (values) => {
                try {
                    await CreateUser_Mutation.mutateAsync({ data: values })
                    queryClient.invalidateQueries({ queryKey: getGetApiAdminUserManagerListUsersQueryKey() })
                    notifications.show({ message: "新增成功" })
                    props.onClose()
                } catch (e) {
                    notifications.show({ message: "新增失敗", color: "red" })

                }
            })}>
                <TextInput
                    withAsterisk
                    label="使用者名稱"
                    placeholder="user name"
                    {...form.getInputProps('userName')}
                />
                <TextInput
                    withAsterisk
                    label="使用者密碼"
                    placeholder="user password"
                    {...form.getInputProps('password')}
                />
                <Group justify="flex-end" mt="md">
                    <Button type="submit">新增</Button>
                </Group>
            </form>
        </Modal>
    </>
}