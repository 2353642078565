import { useAuthStore } from "@/Stores/AuthStore";
import { Text, Button } from '@mantine/core';
import { ChangeDisplayNameDialog } from "./ChangeDisplayNameDialog";
import { useDisclosure } from "@mantine/hooks";
import { ChangePasswordDialog } from "./ChangePasswordDialog";


export function SettingsPage() {
    var auth = useAuthStore();
    const [ChangeDisplayName_opened, { open: ChangeDisplayName_open, close: ChangeDisplayName_close }] = useDisclosure(false);
    const [ChangePassword_opened, { open: ChangePassword_open, close: ChangePassword_close }] = useDisclosure(false);
    return <>
        <Text fw={700} style={{ fontSize: "20px" }} variant="gradient" mb={10}>
            帳號:{auth.data.userName}
        </Text>
        <div style={{ display: "flex", alignContent: "center" }}>
            <Text fw={700} style={{ fontSize: "20px" }} variant="gradient" mb={10}>
                名稱:{auth.data.displayName && auth.data.displayName != "" ? auth.data.displayName : "無"}
            </Text>
            <Button ml={10} onClick={ChangeDisplayName_open}>修改名稱</Button>
        </div>
        {auth.data.provider == "System" &&
            <Button color="green" onClick={ChangePassword_open}>修改密碼</Button>
        }

        <ChangeDisplayNameDialog opened={ChangeDisplayName_opened} onClose={ChangeDisplayName_close} />
        {auth.data.provider == "System" &&
            <ChangePasswordDialog opened={ChangePassword_opened} onClose={ChangePassword_close} />}
    </>;
}
