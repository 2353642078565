import { TextDocument } from "@/API";
import { Link } from '@tanstack/react-router'
import { Box, Group, Text } from "@mantine/core";
import { HighlightText } from "../../Components/HighlightText";

export function TextDocumentSearchCard(props: { textDocument: TextDocument }) {
    return <Box>
        <Link style={{ fontSize: 20 }} to="/workspace/$workspaceId/audioRecord/$audioRecordId" params={{ workspaceId: props.textDocument.workspaceId ?? "", audioRecordId: props.textDocument.audioRecordId ?? "" }} search={{ docId: props.textDocument.id ?? "" }}>
            <HighlightText text={props.textDocument.name ?? ""} />
        </Link>
        <HighlightText text={props.textDocument.text ?? ""} style={{color:"#868e96",fontSize:"14px"}} />
    </Box>
}