import { CSSProperties, useMemo } from "react";

export function HighlightText({ text, highlightColor = "#ffec99", style }: { text: string, textColor?: string, highlightColor?: string, style?: CSSProperties }) {
    const parts = useMemo(() => {
        return text?.split(/(<em>.*?<\/em>)/g) ?? [];
    }, [text])
    return <div >
        {parts.map((part, index) =>
            <span
                key={`${part}_${index}`}
                style={{
                    margin: '0px',
                    whiteSpace: "pre-wrap",
                    fontWeight: "normal",
                    ...style
                }}
            >
                {part.startsWith('<em>') ? (
                    <span style={{ backgroundColor: highlightColor, width: "100%" }} key={index} >
                        {part.replace(/<\/?em>/g, '')}
                    </span>
                ) : (
                    <span key={index} style={{ width: "100%" }}>{part}</span>
                )}
            </span >
        )}
    </div >
}