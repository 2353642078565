import dayjs from "dayjs";
import { EditPeriodCreditKeyViewModel, getApiAdminPeriodCreditKeyGet, getGetApiAdminPeriodCreditKeyListQueryKey, usePatchApiAdminPeriodCreditKeyEdit } from "@/API";
import { DateTimePicker } from '@mantine/dates';
import { Button, Group, Loader, Modal, Textarea } from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import 'dayjs/locale/zh-tw';

export function EditPeriodCreditKeyDialog(props: { userId: string, keyId: string, opened: boolean, onClose: () => void }) {
    const queryClient = useQueryClient()
    const EditKey_Mutation = usePatchApiAdminPeriodCreditKeyEdit()
    const [isloaded, setIsloaded] = useState(false)
    const [expiryTime, setExpiryTime] = useState<Date | null>();
    const [startTime, setStartTime] = useState<Date | null>();
    const form = useForm<EditPeriodCreditKeyViewModel>({
        initialValues: {
            comment: ""
        },
    });
    useEffect(() => {
        if (props.opened) {
            setIsloaded(false)
            form.reset()
            getApiAdminPeriodCreditKeyGet({ userId: props.userId, keyId: props.keyId }).then(x => {
                setExpiryTime(dayjs(x.data.expiryTime).toDate())
                setStartTime(dayjs(x.data.startTime).toDate())
                form.setValues(x.data)
                setIsloaded(true)
            })
        }
    }, [props.opened])
    return <>
        <Modal opened={props.opened} onClose={props.onClose} title="修改儲值金鑰">
            {isloaded ?
                <form onSubmit={form.onSubmit(async (values) => {
                    try {
                        await EditKey_Mutation.mutateAsync({
                            params: { userId: props.userId, keyId: props.keyId }, data: {
                                comment: values.comment,
                                startTime: startTime?.toISOString(),
                                expiryTime: expiryTime?.toISOString()
                            }
                        })
                        queryClient.invalidateQueries({ queryKey: getGetApiAdminPeriodCreditKeyListQueryKey() })
                        notifications.show({ message: "修改成功" })
                        props.onClose()
                    } catch (e) {
                        notifications.show({ message: "修改失敗", color: "red" })
                    }
                })}>
                    <DateTimePicker
                        label="開始時間"
                        locale="zh-tw"
                        valueFormat="YYYY/MM/DD HH:mm"
                        value={startTime}
                        onChange={setStartTime}
                    />
                    <DateTimePicker
                        label="到期時間"
                        locale="zh-tw"
                        valueFormat="YYYY/MM/DD HH:mm"
                        value={expiryTime}
                        onChange={setExpiryTime}
                    />
                    <Textarea
                        label="註記"
                        rows={5}
                        {...form.getInputProps('comment')}
                    />
                    <Group justify="flex-end" mt="md">
                        <Button type="submit">修改</Button>
                    </Group>
                </form>
                : <Loader />}
        </Modal>
    </>
}