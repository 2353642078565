import { useGetStatus } from "@/API";
import { Indicator, Tooltip } from "@mantine/core";
import { IconServer } from "@tabler/icons-react";
import { useEffect, useState } from "react";

export function SystemStatusBadge() {
    const status = useGetStatus()
    const [statusColor, setStateColor] = useState("gray")
    const [statusMsg, setStateMsg] = useState("")
    useEffect(() => {
        var interval = setInterval(() => {
            if (!status.isLoading && !status.isFetching && !status.isRefetching) {
                try {
                    status.refetch();
                } catch (e) {
                    console.error("refetch status error:", e);
                }
            }
        }, 5000)
        return () => {
            clearInterval(interval)
        }
    })
    useEffect(() => {
        if (status.status == "error") {
            setStateColor("gray")
            setStateMsg("失效")
            return;
        }
        if (status.data?.data.audioToTextResponseTimePerSecond != undefined) {
            const p = status.data?.data.audioToTextResponseTimePerSecond
            if (p >= 1) {
                setStateColor("red")
                setStateMsg("過載")
            }
            else if (p >= 0.8) {
                setStateColor("red")
                setStateMsg("高負載")
            }
            else if (p >= 0.4) {
                setStateColor("yellow")
                setStateMsg("中負載")
            }
            else {
                setStateColor("green")
                setStateMsg("低負載")
            }
            return;
        }
    }, [status.data?.data, status.status])
    return <>
        <Tooltip multiline events={{ hover: true, focus: false, touch: true }} style={{ whiteSpace: "pre-line" }} label={`負載狀況:${statusMsg}(${Math.round((status.data?.data.audioToTextResponseTimePerSecond ?? 0) * 100)}%)\n轉錄中使用者:${status.data?.data.audioToTextUsers}`}>
            <Indicator color={statusColor} >
                <IconServer color={status.status == "error" ? "gray" : "var(--mantine-primary-color-filled)"} />
            </Indicator>
        </Tooltip>
    </>
}