import React, { CSSProperties, useCallback, useEffect, useImperativeHandle, useRef } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { Align, VariableSizeList as List } from "react-window";

declare module "react" {
    function forwardRef<T, P = {}>(
        render: (props: P, ref: React.Ref<T>) => React.ReactNode | null
    ): (props: P & React.RefAttributes<T>) => React.ReactNode | null;
}

function Row<TData>({ data, index, setSize, windowWidth, components }: { data: TData[], index: number, setSize: (index: number, height: number) => void, windowWidth: number, components: (data: TData[], index: number) => React.ReactNode }) {
    const rowRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        setSize(index, rowRef.current?.getBoundingClientRect().height ?? 0);
    }, [setSize, index, windowWidth]);

    return (
        <div ref={rowRef}>
            {components(data, index)}
        </div>
    );
};

export type VirtualListHandle = {
    ScrollToBottom: () => void,
    ScrollToItem: (index: number, align?: Align) => void
};
export type VirtualListProps<TData> = {
    data: TData[],
    components: (data: TData[], index: number) => React.ReactNode,
    style?: CSSProperties,
    heightOffset?: number,
    widthOffset?: number,
    onScrollToBottom?: (toBottomOffset: number) => void
};
function VirtualListInner<TData>({ data, components, style, heightOffset = 0, widthOffset = 0, onScrollToBottom }: VirtualListProps<TData>, ref: React.ForwardedRef<VirtualListHandle>) {
    const autoResizeRef = useRef<AutoSizer>(null);
    const listRef = useRef<List>(null);
    const outerRef = useRef<HTMLDivElement>(null);
    const sizeMap = useRef<{ [key: number]: number }>({});
    const setSize = useCallback((index: number, size: number) => {
        sizeMap.current = { ...sizeMap.current, [index]: size };
        listRef.current?.resetAfterIndex(index);
    }, []);
    const getSize = (index: number) => sizeMap.current[index] || 50;
    useImperativeHandle(ref, () => {
        return {
            ScrollToItem(index: number, align?: Align) {
                if (listRef.current) {
                    listRef.current.scrollToItem(index, align)
                }
            },
            ScrollToBottom() {
                if (listRef.current) {
                    listRef.current.scrollToItem(data.length - 1, "end")
                }
            }
        };
    }, [data.length]);
    return <AutoSizer style={style} ref={autoResizeRef} >
        {({ height, width }) => {
            return <List
                onScroll={e => {
                    if (outerRef.current) {
                        var h = outerRef.current.scrollHeight - outerRef.current.clientHeight;
                        onScrollToBottom?.(h - e.scrollOffset)
                    }
                }}
                outerRef={outerRef}
                ref={listRef}
                height={height + heightOffset}
                width={width + widthOffset}
                itemCount={data.length}
                itemSize={getSize}
                itemData={data}
            // innerElementType={ListContainer}
            >
                {({ data, style, index }) => <div style={style}>
                    <Row
                        data={data}
                        index={index}
                        setSize={setSize}
                        windowWidth={width}
                        components={components}
                    />
                </div>
                }
            </List>
        }}
    </AutoSizer>
}
export const VirtualList = React.forwardRef(VirtualListInner);