import { deleteApiAdminLinePayPaymentDelete, getApiAdminLinePayPaymentListPaymentsByUser, getApiAdminLinePayPaymentSearchDetails, patchApiAdminLinePayPaymentRefund, useDeleteApiAdminLinePayPaymentDelete } from "@/API";
import { Box, Button, Group, Stack, TextInput, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import { useQueryClient } from "@tanstack/react-query";
import { useSearch } from "@tanstack/react-router";
import { AxiosError } from "axios";
import dayjs from "dayjs";
import { DataTable } from "mantine-datatable";
import { useState } from "react";

export function LinePayAdminIndex() {
    const { orderId, userId } = useSearch({ from: "/admin/_LinePay" })
    const searchPaymentDetailsForm = useForm({
        initialValues: {
            id: orderId ?? "",
        }
    });
    const [searchPaymentDetailsLoading, setSearchPaymentDetailsLoading] = useState(false)
    const listPaymentsByUserForm = useForm({
        initialValues: {
            userId: userId ?? "",
        }
    });
    const [listPaymentsByUserLoading, setListPaymentsByUserLoading] = useState(false)

    return <>
        <form onSubmit={searchPaymentDetailsForm.onSubmit(async (values) => {
            setSearchPaymentDetailsLoading(true)
            await OpenPaymentDetails(values.id)
            setSearchPaymentDetailsLoading(false)
        })}>
            <TextInput
                withAsterisk
                label="訂單ID"
                {...searchPaymentDetailsForm.getInputProps("id")}
            />
            <Button loading={searchPaymentDetailsLoading} type="submit">查詢</Button>
        </form>
        <form onSubmit={listPaymentsByUserForm.onSubmit(async (values) => {
            setListPaymentsByUserLoading(true)
            await OpenPaymentsByUserId(values.userId)
            setListPaymentsByUserLoading(false)
        })}>
            <TextInput
                withAsterisk
                label="使用者ID"
                {...listPaymentsByUserForm.getInputProps("userId")}
            />
            <Button loading={listPaymentsByUserLoading} type="submit">查詢</Button>
        </form>
    </>
}
async function OpenPaymentsByUserId(userId: string) {
    try {
        var result = await getApiAdminLinePayPaymentListPaymentsByUser({ userId: userId })
        var modalId = "PaymentList"
        modals.open({
            title: "使用者ID:" + userId,
            modalId: modalId,
            fullScreen: true,
            children: (
                <DataTable
                    withTableBorder
                    columns={[
                        {
                            accessor: "id", width: 180, title: "ID"
                        },
                        {
                            accessor: "name", width: 100, title: "名稱", render: (data) => (data.packages?.at(0)?.name),
                        },
                        {
                            accessor: "amount", width: 80, title: "價格", render: (data) => (`${data.amount}元`),
                        },
                        {
                            accessor: "timestamp", width: 150, title: "創建時間", render: (data) => (new Date(Date.parse(data.timestamp!)).toLocaleString()),
                        },
                        {
                            accessor: "status", width: 150, title: "訂單狀態", render: (data) => {
                                switch (data.status) {
                                    case "created":
                                        return "未付款"
                                    case "canceled":
                                        return "交易取消"
                                    case "completed":
                                        return "交易完成"
                                    case "refund":
                                        return "已退款"
                                }
                                return "錯誤"
                            }
                        },
                        {
                            accessor: "action", width: 150, title: "操作", render: (data) => <>
                                <PaymentDetailsButton id={data.id!} />
                                {data.status == "completed" &&
                                    <PaymentRefundButton id={data.id!} onSuccess={() => {
                                        modals.close(modalId)
                                        OpenPaymentsByUserId(userId)
                                    }} />
                                }
                                <PaymentDeleteButton id={data.id!} onSuccess={() => {
                                    modals.close(modalId)
                                    OpenPaymentsByUserId(userId)
                                }} />
                            </>
                        }

                    ]}
                    records={result?.data}
                />
            ),
        });
    } catch (e) {
        var error = e as AxiosError
        notifications.show({ message: error.response?.data as string, color: "red" })
    }
}
function PaymentDeleteButton(props: { id: string, onSuccess?: () => void }) {
    return <Button size="compact-xs" variant="outline" ml={5} onClick={async () => {
        modals.openContextModal({
            modal: "ConfirmCountDown",
            title: '刪除訂單',
            innerProps: {
                countDownSecond: 10,
                labels: { confirm: '刪除', cancel: '取消' },
                children: (
                    <Text size="sm">
                        是否要刪除訂單?
                    </Text>
                ),
                confirmProps: { color: 'red' },
                onConfirm: async () => {
                    try {
                        await deleteApiAdminLinePayPaymentDelete({ id: props.id! })
                        props.onSuccess?.()
                    }
                    catch (e) {
                        notifications.show({ message: "訂單刪除失敗" })
                    }
                }
            }
        });

    }}>刪除</Button>
}
export function PaymentRefundButton(props: { id: string, onSuccess?: () => void }) {
    return <Button size="compact-xs" variant="outline" ml={5} onClick={async () => {
        modals.openContextModal({
            modal: "ConfirmCountDown",
            title: '退款',
            innerProps: {
                countDownSecond: 10,
                children: (
                    <Text size="sm">
                        是否要全額退款訂單?
                    </Text>
                ),
                labels: { confirm: '退款', cancel: '取消' },
                confirmProps: { color: 'red' },
                onConfirm: async () => {
                    try {
                        await patchApiAdminLinePayPaymentRefund({ id: props.id! })
                        props.onSuccess?.()
                    }
                    catch (e) {
                        notifications.show({ message: "訂單退款失敗" })
                    }
                },
            }
        });
    }}>退款</Button>
}
export function PaymentDetailsButton(props: { id: string }) {
    const [loading, setLoading] = useState(false)
    return <Button loading={loading} size="compact-xs" variant="outline" onClick={async () => {
        setLoading(true)
        await OpenPaymentDetails(props.id)
        setLoading(false)
    }}>Details</Button>
}
async function OpenPaymentDetails(id: string) {
    try {
        var result = await getApiAdminLinePayPaymentSearchDetails({ id: id })
        modals.open({
            title: "訂單ID:" + id,
            fullScreen: true,
            children: (
                <Box style={{ whiteSpace: "pre-wrap" }}>
                    {JSON.stringify(result.data, null, "\t")}
                </Box>
            ),
        });
    } catch (e) {
        var error = e as AxiosError
        notifications.show({ message: error.response?.data as string, color: "red" })
    }
}