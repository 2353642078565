import { Box, Group, Button, ButtonProps, GroupProps } from "@mantine/core";
import { useInterval } from "@mantine/hooks";
import { ContextModalProps } from "@mantine/modals";
import { ReactNode, useEffect, useState } from "react";

type ConfirmLabels = Record<'confirm' | 'cancel', ReactNode>;
interface ConfirmModalProps {
    countDownSecond: number,
    children?: React.ReactNode;
    onCancel?: () => void;
    onConfirm?: () => void;
    cancelProps?: ButtonProps & React.ComponentPropsWithoutRef<'button'>;
    confirmProps?: ButtonProps & React.ComponentPropsWithoutRef<'button'>;
    closeOnConfirm?: boolean,
    closeOnCancel?: boolean,
    groupProps?: GroupProps;
    labels?: ConfirmLabels;
}
export const ConfirmCountDownModal = ({
    context,
    id,
    innerProps,
}: ContextModalProps<ConfirmModalProps>) => {
    const [seconds, setSeconds] = useState(innerProps.countDownSecond);
    const interval = useInterval(() => {
        setSeconds((s) => s - 1)
    }, 1000);
    useEffect(() => {
        setSeconds(innerProps.countDownSecond)
        interval.start();
        return interval.stop;
    }, []);
    useEffect(() => {
        if (seconds - 1 < 0) {
            interval.stop()
            return;
        }
    }, [seconds]);
    return <>
        {innerProps.children && <Box mb="md">{innerProps.children}</Box>}
        <Group justify="flex-end" {...innerProps.groupProps}>
            <Button variant="default" {...innerProps.cancelProps} onClick={(event) => {
                typeof innerProps.cancelProps?.onClick === 'function' && innerProps.cancelProps?.onClick(event);
                typeof innerProps.onCancel === 'function' && innerProps.onCancel();
                (innerProps.closeOnCancel || innerProps.closeOnCancel == undefined) && context.closeModal(id!);
            }}>
                {innerProps.cancelProps?.children || (innerProps.labels?.cancel)}
            </Button>
            <Button disabled={seconds > 0} {...innerProps.confirmProps} onClick={(event) => {
                typeof innerProps.confirmProps?.onClick === 'function' && innerProps.confirmProps?.onClick(event);
                typeof innerProps.onConfirm === 'function' && innerProps.onConfirm();
                (innerProps.closeOnConfirm || innerProps.closeOnConfirm == undefined) && context.closeModal(id!);
            }}>
                {seconds > 0 ? seconds :
                    innerProps.confirmProps?.children || (innerProps.labels?.confirm)}
            </Button>
        </Group>
    </>
}