export function TextSaveToFile(filename: string, text: string) {
    var blob = new Blob([text], { type: "text/plain" });
    BlobSaveToFile(blob, filename)
}

export function BlobSaveToFile(blob: Blob, fileName: string) {
    var url = window.URL.createObjectURL(blob);
    UrlSaveToFile(url, fileName)
}

export function UrlSaveToFile(url: string, fileName: string) {
    var fileLink = document.createElement('a');
    fileLink.href = url;
    fileLink.download = fileName;
    document.body.appendChild(fileLink);
    fileLink.click();
    fileLink.remove();
}