import { getGetApiAudioRecordJobGetTranscriptionJobQueryKey, useDeleteApiAudioRecordClearAllEntries, usePostApiAudioRecordJobCreateTranscriptionJob, usePostApiAudioRecordJobCreateTranslationJob } from "@/API"
import { languageCodeData } from "@/Helper/languageCodeData"
import { Button, ButtonProps, Group, Modal, Select, Text } from "@mantine/core"
import { useForm } from "@mantine/form"
import { useDisclosure } from "@mantine/hooks"
import { notifications } from "@mantine/notifications"
import { useQueryClient } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { PropsWithChildren, useState } from "react"

export function CreateAudioTranslationJobForm(props: PropsWithChildren & { workspaceId: string, audioRecordId: string, onCreateStart?: () => void, onCreateEnd?: (success: boolean) => void }) {
    const queryClient = useQueryClient()
    const [loading, setLoading] = useState(false)
    const create_Mutation = usePostApiAudioRecordJobCreateTranslationJob()
    const form = useForm({
        mode: 'uncontrolled',
        initialValues: {
            translatedLanguageCode: 'en',
        }
    });
    return <form onSubmit={form.onSubmit(async (values) => {
        props.onCreateStart?.()
        setLoading(true)
        try {
            await create_Mutation.mutateAsync({
                params: {
                    workspaceId: props.workspaceId,
                    audioRecordId: props.audioRecordId,
                    translatedLanguage: values.translatedLanguageCode
                }
            })
            await queryClient.invalidateQueries({ queryKey: getGetApiAudioRecordJobGetTranscriptionJobQueryKey({ workspaceId: props.workspaceId, audioRecordId: props.audioRecordId }) })
            props.onCreateEnd?.(true)
            notifications.show({ message: "開始翻譯" })
        } catch (e) {
            var error = e as AxiosError
            var msg = ""
            if (typeof error.response?.data == "string") msg = error.response?.data
            props.onCreateEnd?.(false)
            notifications.show({ message: "翻譯失敗" + (msg ? ":" + msg : ""), color: "red" })
        }
        setLoading(false)
    })}>
        <Select
            label="選擇翻譯語言"
            allowDeselect={false}
            {...form.getInputProps('translatedLanguageCode')}
            description="如果該語言已被翻譯，則會重新翻譯"
            data={Object.keys(languageCodeData).filter(x => x.toLowerCase() != "auto" && !!x).map(x => {
                return {
                    label: languageCodeData[x].name,
                    value: x
                }
            })}
        />
        <Group justify="flex-end" mt="md">
            <Button loading={loading} type="submit">確認</Button>
        </Group>
    </form>

}
export function CreateAudioTranscriptionJobButton(props: PropsWithChildren & { workspaceId: string, audioRecordId: string, buttonProps?: ButtonProps, onSuccess?: () => void }) {
    const [opened, { open, close }] = useDisclosure(false);
    const [loading, setLoading] = useState(false)
    return <>
        <Button {...props.buttonProps} loading={loading} onClick={async () => {
            open();
        }}>{props.children}</Button>
        <Modal opened={opened} onClose={close} closeOnClickOutside={!loading} closeOnEscape={!loading} title="新增翻譯任務">
            <CreateAudioTranslationJobForm workspaceId={props.workspaceId} audioRecordId={props.audioRecordId} onCreateStart={() => setLoading(true)} onCreateEnd={() => setLoading(false)} />
        </Modal>
    </>
}