import { mergeAttributes, Node } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'

import Component from './Component'

export default Node.create({
    name: 'AudioRecordTimeComponent',

    group: 'block',

    atom: true,

    addAttributes() {
        return {
            time: {
                default: 0
            },
        }
    },

    parseHTML() {
        return [
            {
                tag: 'AudioRecordTime',
            },
        ]
    },

    renderHTML({ HTMLAttributes }) {
        return ['AudioRecordTime', mergeAttributes(HTMLAttributes)]
    },

    addNodeView() {
        return ReactNodeViewRenderer(Component)
    },
})