import { useGetApiAdminAudioToTextBalancerListSource } from "@/API";
import { Box, Card, Loader, ScrollArea, Table } from "@mantine/core";

export function SourceList() {
    var sources = useGetApiAdminAudioToTextBalancerListSource()
    return <Card shadow="sm" padding="xs" radius="md" withBorder>
        <ScrollArea>
            {sources.isSuccess ?
                <Table>
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th>名稱</Table.Th>
                            <Table.Th>類別</Table.Th>
                            <Table.Th>預設權重</Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                        {sources.data?.data.map((x, idx) => {
                            return <Table.Tr key={idx}>
                                <Table.Td>{x.name}</Table.Td>
                                <Table.Td>{x.sttType}</Table.Td>
                                <Table.Td>{x.weight}</Table.Td>
                            </Table.Tr>
                        })}
                    </Table.Tbody>
                </Table> : <Loader m={10} />}
        </ScrollArea>
    </Card>
}