import { getGetApiAudioRecordJobGetTranscriptionJobQueryKey, useDeleteApiAudioRecordClearAllEntries, usePostApiAudioRecordJobCreateTranscriptionJob } from "@/API"
import { languageCodeData } from "@/Helper/languageCodeData"
import { Button, ButtonProps, Group, Modal, Select } from "@mantine/core"
import { useForm } from "@mantine/form"
import { useDisclosure } from "@mantine/hooks"
import { notifications } from "@mantine/notifications"
import { useQueryClient } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { PropsWithChildren, useState } from "react"

export function CreateAudioTranscriptionJobForm(props: PropsWithChildren & { workspaceId: string, audioRecordId: string, cleanOld?: boolean, onCreateStart?: () => void, onCreateEnd?: (success: boolean) => void }) {
    const queryClient = useQueryClient()
    const [loading, setLoading] = useState(false)
    const create_Mutation = usePostApiAudioRecordJobCreateTranscriptionJob()
    const clearAudioRecord_Mutation = useDeleteApiAudioRecordClearAllEntries()
    const form = useForm({
        mode: 'uncontrolled',
        initialValues: {
            languageCode: 'zh',
            translatedLanguageCode: 'auto',
        }
    });
    return <form onSubmit={form.onSubmit(async (values) => {
        props.onCreateStart?.()
        setLoading(true)
        try {
            if (props.cleanOld) {
                await clearAudioRecord_Mutation.mutateAsync({
                    params: {
                        workspaceId: props.workspaceId,
                        audioRecordId: props.audioRecordId,
                    }
                })
            }
            await create_Mutation.mutateAsync({
                params: {
                    workspaceId: props.workspaceId,
                    audioRecordId: props.audioRecordId,
                    language: values.languageCode,
                    translatedLanguage: values.translatedLanguageCode
                }
            })
            await queryClient.invalidateQueries({ queryKey: getGetApiAudioRecordJobGetTranscriptionJobQueryKey({ workspaceId: props.workspaceId, audioRecordId: props.audioRecordId }) })
            props.onCreateEnd?.(true)
            notifications.show({ message: "開始轉錄" })
        } catch (e) {
            var error = e as AxiosError
            var msg = ""
            if (typeof error.response?.data == "string") msg = error.response?.data
            props.onCreateEnd?.(false)
            notifications.show({ message: "轉錄失敗" + (msg ? ":" + msg : ""), color: "red" })
        }
        setLoading(false)
    })}>
        <Select
            label="選擇音檔語言"
            allowDeselect={false}
            {...form.getInputProps('languageCode')}
            data={Object.keys(languageCodeData).map(x => {
                return {
                    label: languageCodeData[x].name.toLowerCase() != "auto" ? languageCodeData[x].name : "自動",
                    value: x
                }
            })}
        />
        <Select
            label="選擇翻譯語言"
            allowDeselect={false}
            {...form.getInputProps('translatedLanguageCode')}
            data={Object.keys(languageCodeData).map(x => {
                return {
                    label: languageCodeData[x].name.toLowerCase() != "auto" ? languageCodeData[x].name : "無",
                    value: x
                }
            })}
        />

        <Group justify="flex-end" mt="md">
            <Button loading={loading} type="submit">確認</Button>
        </Group>
    </form>

}
export function CreateAudioTranscriptionJobButton(props: PropsWithChildren & { workspaceId: string, audioRecordId: string, cleanOld?: boolean, buttonProps?: ButtonProps, onSuccess?: () => void }) {
    const [opened, { open, close }] = useDisclosure(false);
    const [loading, setLoading] = useState(false)
    return <>
        <Button {...props.buttonProps} loading={loading} onClick={async () => {
            open();
        }}>{props.children}</Button>
        <Modal opened={opened} onClose={close} closeOnClickOutside={!loading} closeOnEscape={!loading} title="新增轉錄任務">
            <CreateAudioTranscriptionJobForm workspaceId={props.workspaceId} audioRecordId={props.audioRecordId} cleanOld={props.cleanOld} onCreateStart={() => setLoading(true)} onCreateEnd={() => setLoading(false)} />
        </Modal>
    </>
}