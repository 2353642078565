import { Box, Button, Center, CopyButton, Flex, Input, Popover, Text } from "@mantine/core";
import { PropsWithChildren } from "react";

export function ShareLink(props: { url: string } & PropsWithChildren) {
    return <>
        <Popover position="bottom" withArrow shadow="md">
            <Popover.Target>
                <Center>
                    {props.children}
                </Center>
            </Popover.Target>
            <Popover.Dropdown>
                <Text fw={500} size="sm">分享連結</Text>
                <Flex gap={0} align="center">
                    <Input size="xs" flex={1} value={props.url} readOnly={true} onClick={(e) => {

                        e.currentTarget.select()
                    }} />
                    <CopyButton value={props.url}>
                        {({ copied, copy }) => (
                            <Button size="compact-xs" color={copied ? 'teal' : 'blue'} onClick={copy}>
                                {copied ? '已複製' : '複製'}
                            </Button>
                        )}
                    </CopyButton>
                </Flex>
            </Popover.Dropdown>
        </Popover>
    </>
}