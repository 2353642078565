/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * ToDo API
 * An ASP.NET Core Web API for managing ToDo items
 * OpenAPI spec version: v1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import axios from 'axios'
import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
export type GetApiWorkspaceUserCheckPendingUserParams = {
workspaceId?: string;
};

export type PostApiWorkspaceUserRequestPendingUserParams = {
workspaceId?: string;
};

export type PostApiWorkspaceUserProcessPendingUserParams = {
workspaceId?: string;
userName?: string;
role?: WorkspaceUserRole;
};

export type GetApiWorkspaceUserListPendingUsersParams = {
workspaceId?: string;
};

export type DeleteApiWorkspaceUserDeleteUserParams = {
workspaceId?: string;
userName?: string;
};

export type PutApiWorkspaceUserSetUserOwnerRoleParams = {
workspaceId?: string;
userName?: string;
};

export type PutApiWorkspaceUserSetUserRoleParams = {
workspaceId?: string;
userName?: string;
role?: WorkspaceUserRole;
};

export type GetApiWorkspaceUserListUsersParams = {
workspaceId?: string;
};

export type GetApiWorkspaceUserGetRoleParams = {
workspaceId?: string;
};

export type DeleteApiWorkspaceDeleteParams = {
id?: string;
};

export type PutApiWorkspaceRenameParams = {
id?: string;
name?: string;
};

export type PutApiWorkspaceUpdatePublicPermissionParams = {
id?: string;
permission?: AudioRecordPublicPermission;
};

export type PostApiWorkspaceCreateParams = {
name?: string;
};

export type GetApiWorkspaceGetParams = {
id?: string;
};

export type GetApiWorkspaceMediaSizeParams = {
id?: string;
};

export type DeleteApiAdminUserManagerDeleteUserParams = {
userId?: string;
};

export type GetApiAdminUserManagerListUsersParams = {
search?: string;
skip?: number;
take?: number;
};

export type PutApiUserChangeDisplayNameParams = {
newDisplayName?: string;
};

export type PostApiTextTranslationParams = {
language?: string;
};

export type PostApiAdminSttUsageLogSearchParams = {
userId?: string;
startTime?: string;
endTime?: string;
timezone?: string;
};

export type PostApiSttUsageLogSearchParams = {
startTime?: string;
endTime?: string;
timezone?: string;
};

export type GetApiSocialAuthGoogleParams = {
redirect?: string;
};

export type PostApiAdminServiceMetricSearchParams = {
source?: string;
limit?: number;
startTime?: string;
endTime?: string;
};

export type DeleteApiAdminPeriodCreditKeyDeleteParams = {
userId?: string;
keyId?: string;
};

export type PatchApiAdminPeriodCreditKeyIncreaseBalanceParams = {
userId?: string;
keyId?: string;
increaseBalance?: number;
};

export type PatchApiAdminPeriodCreditKeyEditParams = {
userId?: string;
keyId?: string;
};

export type PostApiAdminPeriodCreditKeyAddParams = {
userId?: string;
};

export type GetApiAdminPeriodCreditKeyGetParams = {
userId?: string;
keyId?: string;
};

export type GetApiAdminPeriodCreditKeyListParams = {
userId?: string;
};

export type DeleteApiAdminLinePayPaymentDeleteParams = {
id?: string;
};

export type PatchApiAdminLinePayPaymentRefundParams = {
id?: string;
};

export type GetApiAdminLinePayPaymentListPaymentsByUserParams = {
userId?: string;
};

export type GetApiAdminLinePayPaymentSearchDetailsParams = {
id?: string;
};

export type DeleteApiLinePayPaymentCancelParams = {
id?: string;
};

export type GetLinepayCreditkeyCallbackParams = {
transactionId?: number;
orderId?: string;
};

export type PostApiCreditKeyLinePayPurchaseParams = {
productId?: string;
};

export type DeleteApiAdminCreditKeyDeleteParams = {
userId?: string;
keyId?: string;
};

export type PatchApiAdminCreditKeyIncreaseBalanceParams = {
userId?: string;
keyId?: string;
increaseBalance?: number;
};

export type PatchApiAdminCreditKeyEditParams = {
userId?: string;
keyId?: string;
};

export type PostApiAdminCreditKeyAddParams = {
userId?: string;
};

export type GetApiAdminCreditKeyGetParams = {
userId?: string;
keyId?: string;
};

export type GetApiAdminCreditKeyListParams = {
userId?: string;
};

export type PostApiAdminAudioToTextBalancerSearchParams = {
source?: string;
limit?: number;
startTime?: string;
endTime?: string;
};

export type PostApiAdminAudioToTextBalancerSearchAvergeParams = {
source?: string;
startTime?: string;
endTime?: string;
sttType?: SttType;
};

export type GetApiAdminAudioToTextBalancerListCurrentWeightParams = {
sttType?: SttType;
};

export type DeleteApiAudioRecordTextDocumentImageDeleteParams = {
workspaceId?: string;
audioRecordId?: string;
fileName?: string;
};

export type PutApiAudioRecordTextDocumentImageUploadBody = {
  imageFile?: Blob;
};

export type PutApiAudioRecordTextDocumentImageUploadParams = {
workspaceId?: string;
audioRecordId?: string;
};

export type GetApiAudioRecordTextDocumentImageGetParams = {
workspaceId?: string;
audioRecordId?: string;
fileName?: string;
};

export type GetApiAudioRecordTextDocumentImageListParams = {
workspaceId?: string;
audioRecordId?: string;
};

export type DeleteApiAudioRecordTextDocumentDeleteParams = {
workspaceId?: string;
textDocumentId?: string;
};

export type PutApiAudioRecordTextDocumentUpdateParams = {
workspaceId?: string;
};

export type PostApiAudioRecordTextDocumentCreateParams = {
workspaceId?: string;
audioRecordId?: string;
name?: string;
};

export type GetApiAudioRecordTextDocumentGetParams = {
workspaceId?: string;
textDocumentId?: string;
};

export type GetApiAudioRecordTextDocumentListParams = {
workspaceId?: string;
audioRecordId?: string;
skip?: number;
limit?: number;
};

export type DeleteApiAudioRecordJobDeleteTranslationJobParams = {
workspaceId?: string;
audioRecordId?: string;
};

export type PostApiAudioRecordJobCreateTranslationJobParams = {
workspaceId?: string;
audioRecordId?: string;
translatedLanguage?: string;
};

export type GetApiAudioRecordJobGetTranslationJobParams = {
workspaceId?: string;
audioRecordId?: string;
};

export type DeleteApiAudioRecordJobDeleteTranscriptionJobParams = {
workspaceId?: string;
audioRecordId?: string;
};

export type PostApiAudioRecordJobCreateTranscriptionJobParams = {
workspaceId?: string;
audioRecordId?: string;
language?: string;
translatedLanguage?: string;
};

export type GetApiAudioRecordJobGetTranscriptionJobParams = {
workspaceId?: string;
audioRecordId?: string;
};

export type DeleteApiAudioRecordDeleteEntryParams = {
workspaceId?: string;
audioRecordId?: string;
entryId?: string;
};

export type PutApiAudioRecordUpdateEntryParams = {
workspaceId?: string;
audioRecordId?: string;
};

export type DeleteApiAudioRecordClearAllEntriesParams = {
workspaceId?: string;
audioRecordId?: string;
};

export type DeleteApiAudioRecordDeleteAudioParams = {
workspaceId?: string;
audioRecordId?: string;
};

export type GetApiAudioRecordGetAudioInfoParams = {
workspaceId?: string;
audioRecordId?: string;
};

export type GetApiAudioRecordGetAudioParams = {
workspaceId?: string;
audioRecordId?: string;
};

export type PostApiAudioRecordUploadAudioBody = {
  audioFile?: Blob;
};

export type PostApiAudioRecordUploadAudioParams = {
workspaceId?: string;
audioRecordId?: string;
audioId?: string;
};

export type DeleteApiAudioRecordDeleteParams = {
workspaceId?: string;
audioRecordId?: string;
};

export type PutApiAudioRecordUpdateParams = {
workspaceId?: string;
};

export type PutApiAudioRecordUpdatePublicPermissionParams = {
workspaceId?: string;
audioRecordId?: string;
permission?: AudioRecordPublicPermission;
};

export type PostApiAudioRecordCreateParams = {
workspaceId?: string;
name?: string;
};

export type GetApiAudioRecordGetReferenceParams = {
workspaceId?: string;
audioRecordId?: string;
};

export type GetApiAudioRecordGetParams = {
workspaceId?: string;
audioRecordId?: string;
};

export type GetApiAudioRecordMediaSizeParams = {
workspaceId?: string;
audioRecordId?: string;
};

export type GetApiAudioRecordListParams = {
workspaceId?: string;
skip?: number;
limit?: number;
};

export type PostApiAudioAudioToTextBody = {
  audioFile?: Blob;
  context?: string;
};

export type PostApiAudioAudioToTextParams = {
appKey?: string;
language?: string;
prompt?: string;
noSpeechThreshold?: number;
sttType?: SttType;
};

export type PostApiAudioEmbeddingBody = {
  audioFile?: Blob;
};

export type PostApiAudioEmbeddingParams = {
appKey?: string;
};

export type DeleteApiAdminAppKeyDeleteParams = {
userId?: string;
keyId?: string;
};

export type PutApiAdminAppKeyEditParams = {
userId?: string;
keyId?: string;
};

export type PostApiAdminAppKeyCreateParams = {
userId?: string;
};

export type GetApiAdminAppKeyListParams = {
userId?: string;
};

export type DeleteApiAppKeyDeleteParams = {
keyId?: string;
};

export type PutApiAppKeyEditParams = {
keyId?: string;
};

export type GetApiAppKeyInfoParams = {
keyId?: string;
};

export type WorkspaceUserRole = typeof WorkspaceUserRole[keyof typeof WorkspaceUserRole];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkspaceUserRole = {
  None: 'None',
  Viewer: 'Viewer',
  Editor: 'Editor',
  Manager: 'Manager',
  Owner: 'Owner',
} as const;

export interface WorkspaceUser {
  role?: WorkspaceUserRole;
  /** @nullable */
  userName?: string | null;
}

export interface WorkspaceSearchQueryViewModel {
  /** @nullable */
  cropLength?: number | null;
  limit?: number;
  /** @nullable */
  query?: string | null;
  skip?: number;
  /** @nullable */
  workspaceIds?: string[] | null;
}

export interface WorkspacePendingUser {
  /** @nullable */
  userName?: string | null;
}

export interface Workspace {
  /** @nullable */
  audioRecords?: AudioRecordReference[] | null;
  /** @nullable */
  id?: string | null;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  pendingUsers?: WorkspacePendingUser[] | null;
  publicPermission?: AudioRecordPublicPermission;
  /** @nullable */
  users?: WorkspaceUser[] | null;
}

export type UserProvider = typeof UserProvider[keyof typeof UserProvider];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserProvider = {
  None: 'None',
  System: 'System',
  Google: 'Google',
} as const;

export interface UserPasswordViewModel {
  /** @nullable */
  displayName?: string | null;
  /** @nullable */
  password?: string | null;
  /** @nullable */
  userName?: string | null;
}

export interface UserPassword {
  /** @nullable */
  passwordHash?: string | null;
  /** @nullable */
  passwordSalt?: string | null;
}

export interface UserIdPasswordViewModel {
  /** @nullable */
  password?: string | null;
  /** @nullable */
  userId?: string | null;
}

export interface User {
  /** @nullable */
  appKeys?: AppKey[] | null;
  /** @nullable */
  creditKeys?: CreditKey[] | null;
  /** @nullable */
  displayName?: string | null;
  /** @nullable */
  id?: string | null;
  password?: UserPassword;
  /** @nullable */
  periodCreditKeys?: PeriodCreditKey[] | null;
  provider?: UserProvider;
  /** @nullable */
  userName?: string | null;
}

export interface UserPagingList {
  count?: number;
  /** @nullable */
  users?: User[] | null;
}

export type TextDocumentType = typeof TextDocumentType[keyof typeof TextDocumentType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TextDocumentType = {
  None: 'None',
  Json: 'Json',
  Markdown: 'Markdown',
} as const;

export type TextDocumentRawType = typeof TextDocumentRawType[keyof typeof TextDocumentRawType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TextDocumentRawType = {
  None: 'None',
  Yjs: 'Yjs',
} as const;

export interface TextDocumentBase {
  /** @nullable */
  audioRecordId?: string | null;
  createdTimestamp?: number;
  /** @nullable */
  id?: string | null;
  /** @nullable */
  name?: string | null;
  updatedTimestamp?: number;
  /** @nullable */
  workspaceId?: string | null;
}

export interface TextDocumentBaseSearchListResult {
  /** @nullable */
  limit?: number | null;
  /** @nullable */
  results?: TextDocumentBase[] | null;
  skip?: number;
  total?: number;
}

export interface TextDocument {
  /** @nullable */
  audioRecordId?: string | null;
  createdTimestamp?: number;
  /** @nullable */
  id?: string | null;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  rawText?: string | null;
  rawTextType?: TextDocumentRawType;
  /** @nullable */
  text?: string | null;
  textType?: TextDocumentType;
  updatedTimestamp?: number;
  /** @nullable */
  workspaceId?: string | null;
}

export interface SystemStatus {
  audioToTextResponseTimePerSecond?: number;
  audioToTextUsers?: number;
}

export interface SystemSetting {
  /** @nullable */
  id?: string | null;
  newAccountBalance?: number;
  newAccountBalanceExpiryDays?: number;
}

export interface SystemInfo {
  googleAuth?: boolean;
  /** @nullable */
  version?: string | null;
}

export type SttType = typeof SttType[keyof typeof SttType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SttType = {
  none: 'none',
  realtime: 'realtime',
  correction: 'correction',
  file: 'file',
} as const;

export interface ServiceMetricLogMetadata {
  /** @nullable */
  source?: string | null;
}

export interface ServiceMetric {
  limitRequests?: number;
  /** @nullable */
  message?: string | null;
  metadata?: ServiceMetricLogMetadata;
  remainingRequests?: number;
  timestamp?: string;
}

export interface SearchSttUsageLogGroup {
  time?: string;
  /** @nullable */
  usages?: AppKeyUsageModel[] | null;
}

export interface STTWeightViewModel {
  /** @nullable */
  name?: string | null;
  weight?: number;
}

export interface PeriodCreditKey {
  balance?: number;
  /** @nullable */
  comment?: string | null;
  expiryTime?: string;
  /** @nullable */
  keyId?: string | null;
  /** @nullable */
  source?: string | null;
  sourceType?: CreditKeySourceType;
  startTime?: string;
}

export interface MultiSourceSearchResult {
  /** @nullable */
  audioRecords?: AudioRecord[] | null;
  audioRecordsTotal?: number;
  /** @nullable */
  textDocuments?: TextDocument[] | null;
  textDocumentsTotal?: number;
}

export type LinePayTransactionType = typeof LinePayTransactionType[keyof typeof LinePayTransactionType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LinePayTransactionType = {
  PAYMENT: 'PAYMENT',
  PAYMENT_REFUND: 'PAYMENT_REFUND',
  PARTIAL_REFUND: 'PARTIAL_REFUND',
} as const;

export interface LinePayRequestPaymentUrl {
  /** @nullable */
  app?: string | null;
  /** @nullable */
  web?: string | null;
}

export type LinePayRefundTransactionType = typeof LinePayRefundTransactionType[keyof typeof LinePayRefundTransactionType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LinePayRefundTransactionType = {
  PAYMENT_REFUND: 'PAYMENT_REFUND',
  PARTIAL_REFUND: 'PARTIAL_REFUND',
} as const;

export interface LinePayPaymentUrl {
  /** @nullable */
  app?: string | null;
  /** @nullable */
  web?: string | null;
}

export type LinePayPaymentStatus = typeof LinePayPaymentStatus[keyof typeof LinePayPaymentStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LinePayPaymentStatus = {
  none: 'none',
  created: 'created',
  completed: 'completed',
  canceled: 'canceled',
  refund: 'refund',
} as const;

export interface LinePayPaymentProduct {
  /** @nullable */
  id?: string | null;
  /** @nullable */
  name?: string | null;
  price?: number;
  quantity?: number;
}

export interface LinePayPaymentPackage {
  amount?: number;
  /** @nullable */
  id?: string | null;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  products?: LinePayPaymentProduct[] | null;
}

export interface LinePayPaymentDetailsShippingInfo {
  address?: LinePayPaymentDetailsAddressInfo;
  feeAmount?: number;
  /** @nullable */
  methodId?: string | null;
}

export interface LinePayPaymentDetailsTransactionInfo {
  /** @nullable */
  authorizationExpireDate?: string | null;
  /** @nullable */
  currency?: string | null;
  /** @nullable */
  merchantName?: string | null;
  originalTransactionId?: number;
  /** @nullable */
  packages?: LinePayPaymentDetailsPackageInfo[] | null;
  /** @nullable */
  payInfo?: LinePayPaymentDetailsPaymentInfo[] | null;
  /** @nullable */
  payStatus?: string | null;
  /** @nullable */
  productName?: string | null;
  /** @nullable */
  refundList?: LinePayPaymentDetailsRefundInfo[] | null;
  shipping?: LinePayPaymentDetailsShippingInfo;
  /** @nullable */
  transactionDate?: string | null;
  transactionId?: number;
  transactionType?: LinePayTransactionType;
}

export interface LinePayPaymentDetailsResponse {
  /** @nullable */
  info?: LinePayPaymentDetailsTransactionInfo[] | null;
  /** @nullable */
  returnCode?: string | null;
  /** @nullable */
  returnMessage?: string | null;
}

export interface LinePayPaymentDetailsViewModel {
  details?: LinePayPaymentDetailsResponse;
  payment?: LinePayPayment;
  status?: LinePayCheckPaymentStatusResponse;
}

export interface LinePayPaymentDetailsRefundInfo {
  refundAmount?: number;
  /** @nullable */
  refundTransactionDate?: string | null;
  refundTransactionId?: number;
  transactionType?: LinePayRefundTransactionType;
}

export interface LinePayPaymentDetailsRecipientInfo {
  /** @nullable */
  email?: string | null;
  /** @nullable */
  firstName?: string | null;
  /** @nullable */
  firstNameOptional?: string | null;
  /** @nullable */
  lastName?: string | null;
  /** @nullable */
  lastNameOptional?: string | null;
  /** @nullable */
  phoneNo?: string | null;
}

export interface LinePayPaymentDetailsProductInfo {
  /** @nullable */
  id?: string | null;
  /** @nullable */
  imageUrl?: string | null;
  /** @nullable */
  name?: string | null;
  originalPrice?: number;
  price?: number;
  quantity?: number;
}

export interface LinePayPaymentDetailsPaymentInfo {
  amount?: number;
  method?: LinePayPayMethod;
}

export interface LinePayPaymentDetailsPackageInfo {
  amount?: number;
  /** @nullable */
  id?: string | null;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  products?: LinePayPaymentDetailsProductInfo[] | null;
  userFeeAmount?: number;
}

export interface LinePayPaymentDetailsAddressInfo {
  /** @nullable */
  city?: string | null;
  /** @nullable */
  country?: string | null;
  /** @nullable */
  detail?: string | null;
  /** @nullable */
  optional?: string | null;
  /** @nullable */
  postalCode?: string | null;
  recipient?: LinePayPaymentDetailsRecipientInfo;
  /** @nullable */
  state?: string | null;
}

export type LinePayPayMethod = typeof LinePayPayMethod[keyof typeof LinePayPayMethod];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LinePayPayMethod = {
  CREDIT_CARD: 'CREDIT_CARD',
  BALANCE: 'BALANCE',
  DISCOUNT: 'DISCOUNT',
} as const;

export type LinePayCurrency = typeof LinePayCurrency[keyof typeof LinePayCurrency];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LinePayCurrency = {
  USD: 'USD',
  JPY: 'JPY',
  TWD: 'TWD',
  THB: 'THB',
} as const;

export interface LinePayPayment {
  amount?: number;
  /** @nullable */
  comment?: string | null;
  currency?: LinePayCurrency;
  /** @nullable */
  id?: string | null;
  /** @nullable */
  packages?: LinePayPaymentPackage[] | null;
  paymentUrl?: LinePayPaymentUrl;
  status?: LinePayPaymentStatus;
  timestamp?: string;
  transactionId?: number;
  /** @nullable */
  userId?: string | null;
}

export interface LinePayCheckPaymentStatusShipping {
  feeAmount?: number;
  /** @nullable */
  methodId?: string | null;
}

export interface LinePayCheckPaymentStatusInfo {
  shipping?: LinePayCheckPaymentStatusShipping;
}

export interface LinePayCheckPaymentStatusResponse {
  info?: LinePayCheckPaymentStatusInfo;
  /** @nullable */
  returnCode?: string | null;
  /** @nullable */
  returnMessage?: string | null;
}

export interface LicenseValidationModel {
  readonly isAllValid?: boolean;
  isExist?: boolean;
  isNotExpiration?: boolean;
  isValid?: boolean;
  systemTime?: string;
}

export interface LicenseStatus {
  /** @nullable */
  subject?: string | null;
  validation?: LicenseValidationModel;
  validFrom?: number;
  validTo?: number;
}

export interface LicenseModel {
  /** @nullable */
  extensions?: string | null;
  issueDate?: number;
  /** @nullable */
  issuer?: string | null;
  /** @nullable */
  signature?: string | null;
  /** @nullable */
  subject?: string | null;
  validFrom?: number;
  validTo?: number;
}

export interface LicenseData {
  /** @nullable */
  id?: string | null;
  license?: LicenseModel;
}

export type JobStatus = typeof JobStatus[keyof typeof JobStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JobStatus = {
  None: 'None',
  Waiting: 'Waiting',
  Processing: 'Processing',
  Completed: 'Completed',
  Error: 'Error',
} as const;

export interface EditPeriodCreditKeyViewModel {
  /** @nullable */
  comment?: string | null;
  expiryTime?: string;
  startTime?: string;
}

export interface EditCreditKeyViewModel {
  /** @nullable */
  comment?: string | null;
  expiryTime?: string;
}

export interface DataUpdateMeta {
  previousUpdatedTimestamp?: number;
  updatedTimestamp?: number;
}

export type CreditKeySourceType = typeof CreditKeySourceType[keyof typeof CreditKeySourceType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreditKeySourceType = {
  none: 'none',
  linepay: 'linepay',
} as const;

export interface CreditKey {
  balance?: number;
  /** @nullable */
  comment?: string | null;
  expiryTime?: string;
  /** @nullable */
  keyId?: string | null;
  /** @nullable */
  source?: string | null;
  sourceType?: CreditKeySourceType;
}

export interface CreateEditAppKeyViewModel {
  /** @nullable */
  comment?: string | null;
}

export interface ChangePasswordModel {
  /** @nullable */
  newPassword?: string | null;
  /** @nullable */
  oldPassword?: string | null;
}

export interface AuthViewModel {
  /** @nullable */
  password?: string | null;
  /** @nullable */
  userName?: string | null;
}

export type AuthStateRole = typeof AuthStateRole[keyof typeof AuthStateRole];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AuthStateRole = {
  user: 'user',
  admin: 'admin',
} as const;

export interface AuthState {
  /** @nullable */
  displayName?: string | null;
  provider?: UserProvider;
  role?: AuthStateRole;
  /** @nullable */
  userName?: string | null;
}

export interface AudioToTextSegment {
  end?: string;
  start?: string;
  /** @nullable */
  text?: string | null;
}

export interface AudioToTextResult {
  duration?: number;
  /** @nullable */
  language?: string | null;
  /** @nullable */
  provider?: string | null;
  /** @nullable */
  segments?: AudioToTextSegment[] | null;
  /** @nullable */
  text?: string | null;
}

export type AudioToTextRecordResponseType = typeof AudioToTextRecordResponseType[keyof typeof AudioToTextRecordResponseType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AudioToTextRecordResponseType = {
  none: 'none',
  normal: 'normal',
  error: 'error',
  timeout: 'timeout',
} as const;

export interface AudioToTextRecordMetadata {
  responseType?: AudioToTextRecordResponseType;
  /** @nullable */
  source?: string | null;
  sttType?: SttType;
}

export interface AudioToTextRecordAvergeViewModel {
  averageDuration?: number;
  averageInvalidCountPercentage?: number;
  averageReponseTime?: number;
  averageReponseTimePerDurationSecond?: number;
}

export interface AudioToTextRecord {
  duration?: number;
  /** @nullable */
  errorMessage?: string | null;
  metadata?: AudioToTextRecordMetadata;
  responseTime?: number;
  timestamp?: string;
}

export interface AudioToTextBalancerViewModel {
  /** @nullable */
  name?: string | null;
  sttType?: SttType;
  weight?: number;
}

export interface AudioRecordTranslationJob {
  /** @nullable */
  audioRecordId?: string | null;
  createdTime?: string;
  /** @nullable */
  id?: string | null;
  /** @nullable */
  message?: string | null;
  processedCount?: number;
  /** @nullable */
  processJobId?: string | null;
  status?: JobStatus;
  totalCount?: number;
  /** @nullable */
  translatedLanguages?: string[] | null;
  updatedTime?: string;
  /** @nullable */
  userId?: string | null;
  /** @nullable */
  workspaceId?: string | null;
}

export interface AudioRecordTranslatedText {
  /** @nullable */
  language?: string | null;
  /** @nullable */
  text?: string | null;
}

export interface AudioRecordTranscriptionJob {
  /** @nullable */
  audioRecordId?: string | null;
  /** @nullable */
  context?: string | null;
  createdTime?: string;
  duration?: number;
  /** @nullable */
  id?: string | null;
  /** @nullable */
  language?: string | null;
  /** @nullable */
  message?: string | null;
  noSpeechThreshold?: number;
  /** @nullable */
  processJobId?: string | null;
  /** @nullable */
  prompt?: string | null;
  status?: JobStatus;
  transciptionTime?: number;
  /** @nullable */
  translatedLanguages?: string[] | null;
  updatedTime?: string;
  /** @nullable */
  userId?: string | null;
  /** @nullable */
  workspaceId?: string | null;
}

export interface AudioRecordReference {
  /** @nullable */
  audioIds?: string[] | null;
  /** @nullable */
  id?: string | null;
}

export type AudioRecordPublicPermission = typeof AudioRecordPublicPermission[keyof typeof AudioRecordPublicPermission];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AudioRecordPublicPermission = {
  None: 'None',
  View: 'View',
} as const;

export interface AudioRecordEntry {
  duration?: number;
  /** @nullable */
  id?: string | null;
  /** @nullable */
  speakers?: string[] | null;
  /** @nullable */
  text?: string | null;
  time?: number;
  /** @nullable */
  translatedTexts?: AudioRecordTranslatedText[] | null;
}

export interface AudioRecordBase {
  createdTimestamp?: number;
  /** @nullable */
  id?: string | null;
  /** @nullable */
  name?: string | null;
  publicPermission?: AudioRecordPublicPermission;
  updatedTimestamp?: number;
  /** @nullable */
  workspaceId?: string | null;
}

export interface AudioRecordBaseSearchListResult {
  /** @nullable */
  limit?: number | null;
  /** @nullable */
  results?: AudioRecordBase[] | null;
  skip?: number;
  total?: number;
}

export interface AudioRecord {
  createdTimestamp?: number;
  /** @nullable */
  id?: string | null;
  /** @nullable */
  keywords?: string[] | null;
  /** @nullable */
  name?: string | null;
  publicPermission?: AudioRecordPublicPermission;
  /** @nullable */
  recordEntries?: AudioRecordEntry[] | null;
  /** @nullable */
  tags?: string[] | null;
  updatedTimestamp?: number;
  /** @nullable */
  workspaceId?: string | null;
}

export interface AudioInfoViewModel {
  duration?: number;
  size?: number;
}

export interface AppKeyUsageModel {
  /** @nullable */
  appKey?: string | null;
  usage?: number;
}

export interface AppKeyInfo {
  /** @nullable */
  comment?: string | null;
  /** @nullable */
  keyId?: string | null;
  /** @nullable */
  userName?: string | null;
  /** @nullable */
  validCreditKeys?: CreditKey[] | null;
  /** @nullable */
  validPeriodCreditKeys?: PeriodCreditKey[] | null;
}

export interface AppKey {
  /** @nullable */
  comment?: string | null;
  /** @nullable */
  keyId?: string | null;
}

export interface AddPeriodCreditKeyViewModel {
  balance?: number;
  /** @nullable */
  comment?: string | null;
  expiryTime?: string;
  startTime?: string;
}

export interface AddCreditKeyViewModel {
  balance?: number;
  /** @nullable */
  comment?: string | null;
  expiryTime?: string;
}



type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;



export const getApiAppKeyInfo = (
    params?: GetApiAppKeyInfoParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AppKeyInfo>> => {
    
    return axios.get(
      `/api/AppKey/Info`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetApiAppKeyInfoQueryKey = (params?: GetApiAppKeyInfoParams,) => {
    return [`/api/AppKey/Info`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiAppKeyInfoQueryOptions = <TData = Awaited<ReturnType<typeof getApiAppKeyInfo>>, TError = AxiosError<unknown>>(params?: GetApiAppKeyInfoParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAppKeyInfo>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiAppKeyInfoQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiAppKeyInfo>>> = ({ signal }) => getApiAppKeyInfo(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiAppKeyInfo>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiAppKeyInfoQueryResult = NonNullable<Awaited<ReturnType<typeof getApiAppKeyInfo>>>
export type GetApiAppKeyInfoQueryError = AxiosError<unknown>



export function useGetApiAppKeyInfo<TData = Awaited<ReturnType<typeof getApiAppKeyInfo>>, TError = AxiosError<unknown>>(
 params?: GetApiAppKeyInfoParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAppKeyInfo>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiAppKeyInfoQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getApiAppKeyList = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AppKey[]>> => {
    
    return axios.get(
      `/api/AppKey/List`,options
    );
  }


export const getGetApiAppKeyListQueryKey = () => {
    return [`/api/AppKey/List`] as const;
    }

    
export const getGetApiAppKeyListQueryOptions = <TData = Awaited<ReturnType<typeof getApiAppKeyList>>, TError = AxiosError<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAppKeyList>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiAppKeyListQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiAppKeyList>>> = ({ signal }) => getApiAppKeyList({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiAppKeyList>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiAppKeyListQueryResult = NonNullable<Awaited<ReturnType<typeof getApiAppKeyList>>>
export type GetApiAppKeyListQueryError = AxiosError<unknown>



export function useGetApiAppKeyList<TData = Awaited<ReturnType<typeof getApiAppKeyList>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAppKeyList>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiAppKeyListQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const postApiAppKeyCreate = (
    createEditAppKeyViewModel: CreateEditAppKeyViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<string>> => {
    
    return axios.post(
      `/api/AppKey/Create`,
      createEditAppKeyViewModel,options
    );
  }



export const getPostApiAppKeyCreateMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAppKeyCreate>>, TError,{data: CreateEditAppKeyViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiAppKeyCreate>>, TError,{data: CreateEditAppKeyViewModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiAppKeyCreate>>, {data: CreateEditAppKeyViewModel}> = (props) => {
          const {data} = props ?? {};

          return  postApiAppKeyCreate(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiAppKeyCreateMutationResult = NonNullable<Awaited<ReturnType<typeof postApiAppKeyCreate>>>
    export type PostApiAppKeyCreateMutationBody = CreateEditAppKeyViewModel
    export type PostApiAppKeyCreateMutationError = AxiosError<unknown>

    export const usePostApiAppKeyCreate = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAppKeyCreate>>, TError,{data: CreateEditAppKeyViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiAppKeyCreate>>,
        TError,
        {data: CreateEditAppKeyViewModel},
        TContext
      > => {

      const mutationOptions = getPostApiAppKeyCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const putApiAppKeyEdit = (
    createEditAppKeyViewModel: CreateEditAppKeyViewModel,
    params?: PutApiAppKeyEditParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.put(
      `/api/AppKey/Edit`,
      createEditAppKeyViewModel,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getPutApiAppKeyEditMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiAppKeyEdit>>, TError,{data: CreateEditAppKeyViewModel;params?: PutApiAppKeyEditParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof putApiAppKeyEdit>>, TError,{data: CreateEditAppKeyViewModel;params?: PutApiAppKeyEditParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiAppKeyEdit>>, {data: CreateEditAppKeyViewModel;params?: PutApiAppKeyEditParams}> = (props) => {
          const {data,params} = props ?? {};

          return  putApiAppKeyEdit(data,params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiAppKeyEditMutationResult = NonNullable<Awaited<ReturnType<typeof putApiAppKeyEdit>>>
    export type PutApiAppKeyEditMutationBody = CreateEditAppKeyViewModel
    export type PutApiAppKeyEditMutationError = AxiosError<unknown>

    export const usePutApiAppKeyEdit = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiAppKeyEdit>>, TError,{data: CreateEditAppKeyViewModel;params?: PutApiAppKeyEditParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof putApiAppKeyEdit>>,
        TError,
        {data: CreateEditAppKeyViewModel;params?: PutApiAppKeyEditParams},
        TContext
      > => {

      const mutationOptions = getPutApiAppKeyEditMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const deleteApiAppKeyDelete = (
    params?: DeleteApiAppKeyDeleteParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/api/AppKey/Delete`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getDeleteApiAppKeyDeleteMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiAppKeyDelete>>, TError,{params?: DeleteApiAppKeyDeleteParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiAppKeyDelete>>, TError,{params?: DeleteApiAppKeyDeleteParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiAppKeyDelete>>, {params?: DeleteApiAppKeyDeleteParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteApiAppKeyDelete(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiAppKeyDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiAppKeyDelete>>>
    
    export type DeleteApiAppKeyDeleteMutationError = AxiosError<unknown>

    export const useDeleteApiAppKeyDelete = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiAppKeyDelete>>, TError,{params?: DeleteApiAppKeyDeleteParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiAppKeyDelete>>,
        TError,
        {params?: DeleteApiAppKeyDeleteParams},
        TContext
      > => {

      const mutationOptions = getDeleteApiAppKeyDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getApiAdminAppKeyList = (
    params?: GetApiAdminAppKeyListParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AppKey[]>> => {
    
    return axios.get(
      `/api/admin/AppKey/List`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetApiAdminAppKeyListQueryKey = (params?: GetApiAdminAppKeyListParams,) => {
    return [`/api/admin/AppKey/List`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiAdminAppKeyListQueryOptions = <TData = Awaited<ReturnType<typeof getApiAdminAppKeyList>>, TError = AxiosError<unknown>>(params?: GetApiAdminAppKeyListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAdminAppKeyList>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiAdminAppKeyListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiAdminAppKeyList>>> = ({ signal }) => getApiAdminAppKeyList(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiAdminAppKeyList>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiAdminAppKeyListQueryResult = NonNullable<Awaited<ReturnType<typeof getApiAdminAppKeyList>>>
export type GetApiAdminAppKeyListQueryError = AxiosError<unknown>



export function useGetApiAdminAppKeyList<TData = Awaited<ReturnType<typeof getApiAdminAppKeyList>>, TError = AxiosError<unknown>>(
 params?: GetApiAdminAppKeyListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAdminAppKeyList>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiAdminAppKeyListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const postApiAdminAppKeyCreate = (
    createEditAppKeyViewModel: CreateEditAppKeyViewModel,
    params?: PostApiAdminAppKeyCreateParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<string>> => {
    
    return axios.post(
      `/api/admin/AppKey/Create`,
      createEditAppKeyViewModel,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getPostApiAdminAppKeyCreateMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAdminAppKeyCreate>>, TError,{data: CreateEditAppKeyViewModel;params?: PostApiAdminAppKeyCreateParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiAdminAppKeyCreate>>, TError,{data: CreateEditAppKeyViewModel;params?: PostApiAdminAppKeyCreateParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiAdminAppKeyCreate>>, {data: CreateEditAppKeyViewModel;params?: PostApiAdminAppKeyCreateParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postApiAdminAppKeyCreate(data,params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiAdminAppKeyCreateMutationResult = NonNullable<Awaited<ReturnType<typeof postApiAdminAppKeyCreate>>>
    export type PostApiAdminAppKeyCreateMutationBody = CreateEditAppKeyViewModel
    export type PostApiAdminAppKeyCreateMutationError = AxiosError<unknown>

    export const usePostApiAdminAppKeyCreate = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAdminAppKeyCreate>>, TError,{data: CreateEditAppKeyViewModel;params?: PostApiAdminAppKeyCreateParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiAdminAppKeyCreate>>,
        TError,
        {data: CreateEditAppKeyViewModel;params?: PostApiAdminAppKeyCreateParams},
        TContext
      > => {

      const mutationOptions = getPostApiAdminAppKeyCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const putApiAdminAppKeyEdit = (
    createEditAppKeyViewModel: CreateEditAppKeyViewModel,
    params?: PutApiAdminAppKeyEditParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.put(
      `/api/admin/AppKey/Edit`,
      createEditAppKeyViewModel,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getPutApiAdminAppKeyEditMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiAdminAppKeyEdit>>, TError,{data: CreateEditAppKeyViewModel;params?: PutApiAdminAppKeyEditParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof putApiAdminAppKeyEdit>>, TError,{data: CreateEditAppKeyViewModel;params?: PutApiAdminAppKeyEditParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiAdminAppKeyEdit>>, {data: CreateEditAppKeyViewModel;params?: PutApiAdminAppKeyEditParams}> = (props) => {
          const {data,params} = props ?? {};

          return  putApiAdminAppKeyEdit(data,params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiAdminAppKeyEditMutationResult = NonNullable<Awaited<ReturnType<typeof putApiAdminAppKeyEdit>>>
    export type PutApiAdminAppKeyEditMutationBody = CreateEditAppKeyViewModel
    export type PutApiAdminAppKeyEditMutationError = AxiosError<unknown>

    export const usePutApiAdminAppKeyEdit = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiAdminAppKeyEdit>>, TError,{data: CreateEditAppKeyViewModel;params?: PutApiAdminAppKeyEditParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof putApiAdminAppKeyEdit>>,
        TError,
        {data: CreateEditAppKeyViewModel;params?: PutApiAdminAppKeyEditParams},
        TContext
      > => {

      const mutationOptions = getPutApiAdminAppKeyEditMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const deleteApiAdminAppKeyDelete = (
    params?: DeleteApiAdminAppKeyDeleteParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/api/admin/AppKey/Delete`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getDeleteApiAdminAppKeyDeleteMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiAdminAppKeyDelete>>, TError,{params?: DeleteApiAdminAppKeyDeleteParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiAdminAppKeyDelete>>, TError,{params?: DeleteApiAdminAppKeyDeleteParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiAdminAppKeyDelete>>, {params?: DeleteApiAdminAppKeyDeleteParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteApiAdminAppKeyDelete(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiAdminAppKeyDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiAdminAppKeyDelete>>>
    
    export type DeleteApiAdminAppKeyDeleteMutationError = AxiosError<unknown>

    export const useDeleteApiAdminAppKeyDelete = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiAdminAppKeyDelete>>, TError,{params?: DeleteApiAdminAppKeyDeleteParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiAdminAppKeyDelete>>,
        TError,
        {params?: DeleteApiAdminAppKeyDeleteParams},
        TContext
      > => {

      const mutationOptions = getDeleteApiAdminAppKeyDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiAudioEmbedding = (
    postApiAudioEmbeddingBody: PostApiAudioEmbeddingBody,
    params?: PostApiAudioEmbeddingParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<number[]>> => {const formData = new FormData();
if(postApiAudioEmbeddingBody.audioFile !== undefined) {
 formData.append('audioFile', postApiAudioEmbeddingBody.audioFile)
 }

    
    return axios.post(
      `/api/Audio/Embedding`,
      formData,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getPostApiAudioEmbeddingMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAudioEmbedding>>, TError,{data: PostApiAudioEmbeddingBody;params?: PostApiAudioEmbeddingParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiAudioEmbedding>>, TError,{data: PostApiAudioEmbeddingBody;params?: PostApiAudioEmbeddingParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiAudioEmbedding>>, {data: PostApiAudioEmbeddingBody;params?: PostApiAudioEmbeddingParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postApiAudioEmbedding(data,params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiAudioEmbeddingMutationResult = NonNullable<Awaited<ReturnType<typeof postApiAudioEmbedding>>>
    export type PostApiAudioEmbeddingMutationBody = PostApiAudioEmbeddingBody
    export type PostApiAudioEmbeddingMutationError = AxiosError<unknown>

    export const usePostApiAudioEmbedding = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAudioEmbedding>>, TError,{data: PostApiAudioEmbeddingBody;params?: PostApiAudioEmbeddingParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiAudioEmbedding>>,
        TError,
        {data: PostApiAudioEmbeddingBody;params?: PostApiAudioEmbeddingParams},
        TContext
      > => {

      const mutationOptions = getPostApiAudioEmbeddingMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiAudioAudioToText = (
    postApiAudioAudioToTextBody: PostApiAudioAudioToTextBody,
    params?: PostApiAudioAudioToTextParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AudioToTextResult>> => {const formData = new FormData();
if(postApiAudioAudioToTextBody.audioFile !== undefined) {
 formData.append('audioFile', postApiAudioAudioToTextBody.audioFile)
 }
if(postApiAudioAudioToTextBody.context !== undefined) {
 formData.append('context', postApiAudioAudioToTextBody.context)
 }

    
    return axios.post(
      `/api/Audio/AudioToText`,
      formData,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getPostApiAudioAudioToTextMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAudioAudioToText>>, TError,{data: PostApiAudioAudioToTextBody;params?: PostApiAudioAudioToTextParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiAudioAudioToText>>, TError,{data: PostApiAudioAudioToTextBody;params?: PostApiAudioAudioToTextParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiAudioAudioToText>>, {data: PostApiAudioAudioToTextBody;params?: PostApiAudioAudioToTextParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postApiAudioAudioToText(data,params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiAudioAudioToTextMutationResult = NonNullable<Awaited<ReturnType<typeof postApiAudioAudioToText>>>
    export type PostApiAudioAudioToTextMutationBody = PostApiAudioAudioToTextBody
    export type PostApiAudioAudioToTextMutationError = AxiosError<unknown>

    export const usePostApiAudioAudioToText = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAudioAudioToText>>, TError,{data: PostApiAudioAudioToTextBody;params?: PostApiAudioAudioToTextParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiAudioAudioToText>>,
        TError,
        {data: PostApiAudioAudioToTextBody;params?: PostApiAudioAudioToTextParams},
        TContext
      > => {

      const mutationOptions = getPostApiAudioAudioToTextMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getApiAudioRecordList = (
    params?: GetApiAudioRecordListParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AudioRecordBaseSearchListResult>> => {
    
    return axios.get(
      `/api/AudioRecord/List`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetApiAudioRecordListQueryKey = (params?: GetApiAudioRecordListParams,) => {
    return [`/api/AudioRecord/List`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiAudioRecordListQueryOptions = <TData = Awaited<ReturnType<typeof getApiAudioRecordList>>, TError = AxiosError<unknown>>(params?: GetApiAudioRecordListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAudioRecordList>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiAudioRecordListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiAudioRecordList>>> = ({ signal }) => getApiAudioRecordList(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiAudioRecordList>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiAudioRecordListQueryResult = NonNullable<Awaited<ReturnType<typeof getApiAudioRecordList>>>
export type GetApiAudioRecordListQueryError = AxiosError<unknown>



export function useGetApiAudioRecordList<TData = Awaited<ReturnType<typeof getApiAudioRecordList>>, TError = AxiosError<unknown>>(
 params?: GetApiAudioRecordListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAudioRecordList>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiAudioRecordListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getApiAudioRecordMediaSize = (
    params?: GetApiAudioRecordMediaSizeParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<number>> => {
    
    return axios.get(
      `/api/AudioRecord/MediaSize`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetApiAudioRecordMediaSizeQueryKey = (params?: GetApiAudioRecordMediaSizeParams,) => {
    return [`/api/AudioRecord/MediaSize`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiAudioRecordMediaSizeQueryOptions = <TData = Awaited<ReturnType<typeof getApiAudioRecordMediaSize>>, TError = AxiosError<unknown>>(params?: GetApiAudioRecordMediaSizeParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAudioRecordMediaSize>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiAudioRecordMediaSizeQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiAudioRecordMediaSize>>> = ({ signal }) => getApiAudioRecordMediaSize(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiAudioRecordMediaSize>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiAudioRecordMediaSizeQueryResult = NonNullable<Awaited<ReturnType<typeof getApiAudioRecordMediaSize>>>
export type GetApiAudioRecordMediaSizeQueryError = AxiosError<unknown>



export function useGetApiAudioRecordMediaSize<TData = Awaited<ReturnType<typeof getApiAudioRecordMediaSize>>, TError = AxiosError<unknown>>(
 params?: GetApiAudioRecordMediaSizeParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAudioRecordMediaSize>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiAudioRecordMediaSizeQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getApiAudioRecordGet = (
    params?: GetApiAudioRecordGetParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AudioRecord>> => {
    
    return axios.get(
      `/api/AudioRecord/Get`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetApiAudioRecordGetQueryKey = (params?: GetApiAudioRecordGetParams,) => {
    return [`/api/AudioRecord/Get`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiAudioRecordGetQueryOptions = <TData = Awaited<ReturnType<typeof getApiAudioRecordGet>>, TError = AxiosError<unknown>>(params?: GetApiAudioRecordGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAudioRecordGet>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiAudioRecordGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiAudioRecordGet>>> = ({ signal }) => getApiAudioRecordGet(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiAudioRecordGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiAudioRecordGetQueryResult = NonNullable<Awaited<ReturnType<typeof getApiAudioRecordGet>>>
export type GetApiAudioRecordGetQueryError = AxiosError<unknown>



export function useGetApiAudioRecordGet<TData = Awaited<ReturnType<typeof getApiAudioRecordGet>>, TError = AxiosError<unknown>>(
 params?: GetApiAudioRecordGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAudioRecordGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiAudioRecordGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getApiAudioRecordGetReference = (
    params?: GetApiAudioRecordGetReferenceParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AudioRecordReference>> => {
    
    return axios.get(
      `/api/AudioRecord/GetReference`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetApiAudioRecordGetReferenceQueryKey = (params?: GetApiAudioRecordGetReferenceParams,) => {
    return [`/api/AudioRecord/GetReference`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiAudioRecordGetReferenceQueryOptions = <TData = Awaited<ReturnType<typeof getApiAudioRecordGetReference>>, TError = AxiosError<unknown>>(params?: GetApiAudioRecordGetReferenceParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAudioRecordGetReference>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiAudioRecordGetReferenceQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiAudioRecordGetReference>>> = ({ signal }) => getApiAudioRecordGetReference(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiAudioRecordGetReference>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiAudioRecordGetReferenceQueryResult = NonNullable<Awaited<ReturnType<typeof getApiAudioRecordGetReference>>>
export type GetApiAudioRecordGetReferenceQueryError = AxiosError<unknown>



export function useGetApiAudioRecordGetReference<TData = Awaited<ReturnType<typeof getApiAudioRecordGetReference>>, TError = AxiosError<unknown>>(
 params?: GetApiAudioRecordGetReferenceParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAudioRecordGetReference>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiAudioRecordGetReferenceQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const postApiAudioRecordCreate = (
    params?: PostApiAudioRecordCreateParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AudioRecord>> => {
    
    return axios.post(
      `/api/AudioRecord/Create`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getPostApiAudioRecordCreateMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAudioRecordCreate>>, TError,{params?: PostApiAudioRecordCreateParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiAudioRecordCreate>>, TError,{params?: PostApiAudioRecordCreateParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiAudioRecordCreate>>, {params?: PostApiAudioRecordCreateParams}> = (props) => {
          const {params} = props ?? {};

          return  postApiAudioRecordCreate(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiAudioRecordCreateMutationResult = NonNullable<Awaited<ReturnType<typeof postApiAudioRecordCreate>>>
    
    export type PostApiAudioRecordCreateMutationError = AxiosError<unknown>

    export const usePostApiAudioRecordCreate = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAudioRecordCreate>>, TError,{params?: PostApiAudioRecordCreateParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiAudioRecordCreate>>,
        TError,
        {params?: PostApiAudioRecordCreateParams},
        TContext
      > => {

      const mutationOptions = getPostApiAudioRecordCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const putApiAudioRecordUpdatePublicPermission = (
    params?: PutApiAudioRecordUpdatePublicPermissionParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.put(
      `/api/AudioRecord/UpdatePublicPermission`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getPutApiAudioRecordUpdatePublicPermissionMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiAudioRecordUpdatePublicPermission>>, TError,{params?: PutApiAudioRecordUpdatePublicPermissionParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof putApiAudioRecordUpdatePublicPermission>>, TError,{params?: PutApiAudioRecordUpdatePublicPermissionParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiAudioRecordUpdatePublicPermission>>, {params?: PutApiAudioRecordUpdatePublicPermissionParams}> = (props) => {
          const {params} = props ?? {};

          return  putApiAudioRecordUpdatePublicPermission(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiAudioRecordUpdatePublicPermissionMutationResult = NonNullable<Awaited<ReturnType<typeof putApiAudioRecordUpdatePublicPermission>>>
    
    export type PutApiAudioRecordUpdatePublicPermissionMutationError = AxiosError<unknown>

    export const usePutApiAudioRecordUpdatePublicPermission = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiAudioRecordUpdatePublicPermission>>, TError,{params?: PutApiAudioRecordUpdatePublicPermissionParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof putApiAudioRecordUpdatePublicPermission>>,
        TError,
        {params?: PutApiAudioRecordUpdatePublicPermissionParams},
        TContext
      > => {

      const mutationOptions = getPutApiAudioRecordUpdatePublicPermissionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const putApiAudioRecordUpdate = (
    audioRecord: AudioRecord,
    params?: PutApiAudioRecordUpdateParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<DataUpdateMeta>> => {
    
    return axios.put(
      `/api/AudioRecord/Update`,
      audioRecord,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getPutApiAudioRecordUpdateMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiAudioRecordUpdate>>, TError,{data: AudioRecord;params?: PutApiAudioRecordUpdateParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof putApiAudioRecordUpdate>>, TError,{data: AudioRecord;params?: PutApiAudioRecordUpdateParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiAudioRecordUpdate>>, {data: AudioRecord;params?: PutApiAudioRecordUpdateParams}> = (props) => {
          const {data,params} = props ?? {};

          return  putApiAudioRecordUpdate(data,params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiAudioRecordUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof putApiAudioRecordUpdate>>>
    export type PutApiAudioRecordUpdateMutationBody = AudioRecord
    export type PutApiAudioRecordUpdateMutationError = AxiosError<unknown>

    export const usePutApiAudioRecordUpdate = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiAudioRecordUpdate>>, TError,{data: AudioRecord;params?: PutApiAudioRecordUpdateParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof putApiAudioRecordUpdate>>,
        TError,
        {data: AudioRecord;params?: PutApiAudioRecordUpdateParams},
        TContext
      > => {

      const mutationOptions = getPutApiAudioRecordUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const deleteApiAudioRecordDelete = (
    params?: DeleteApiAudioRecordDeleteParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/api/AudioRecord/Delete`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getDeleteApiAudioRecordDeleteMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiAudioRecordDelete>>, TError,{params?: DeleteApiAudioRecordDeleteParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiAudioRecordDelete>>, TError,{params?: DeleteApiAudioRecordDeleteParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiAudioRecordDelete>>, {params?: DeleteApiAudioRecordDeleteParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteApiAudioRecordDelete(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiAudioRecordDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiAudioRecordDelete>>>
    
    export type DeleteApiAudioRecordDeleteMutationError = AxiosError<unknown>

    export const useDeleteApiAudioRecordDelete = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiAudioRecordDelete>>, TError,{params?: DeleteApiAudioRecordDeleteParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiAudioRecordDelete>>,
        TError,
        {params?: DeleteApiAudioRecordDeleteParams},
        TContext
      > => {

      const mutationOptions = getDeleteApiAudioRecordDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiAudioRecordUploadAudio = (
    postApiAudioRecordUploadAudioBody: PostApiAudioRecordUploadAudioBody,
    params?: PostApiAudioRecordUploadAudioParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {const formData = new FormData();
if(postApiAudioRecordUploadAudioBody.audioFile !== undefined) {
 formData.append('audioFile', postApiAudioRecordUploadAudioBody.audioFile)
 }

    
    return axios.post(
      `/api/AudioRecord/UploadAudio`,
      formData,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getPostApiAudioRecordUploadAudioMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAudioRecordUploadAudio>>, TError,{data: PostApiAudioRecordUploadAudioBody;params?: PostApiAudioRecordUploadAudioParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiAudioRecordUploadAudio>>, TError,{data: PostApiAudioRecordUploadAudioBody;params?: PostApiAudioRecordUploadAudioParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiAudioRecordUploadAudio>>, {data: PostApiAudioRecordUploadAudioBody;params?: PostApiAudioRecordUploadAudioParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postApiAudioRecordUploadAudio(data,params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiAudioRecordUploadAudioMutationResult = NonNullable<Awaited<ReturnType<typeof postApiAudioRecordUploadAudio>>>
    export type PostApiAudioRecordUploadAudioMutationBody = PostApiAudioRecordUploadAudioBody
    export type PostApiAudioRecordUploadAudioMutationError = AxiosError<unknown>

    export const usePostApiAudioRecordUploadAudio = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAudioRecordUploadAudio>>, TError,{data: PostApiAudioRecordUploadAudioBody;params?: PostApiAudioRecordUploadAudioParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiAudioRecordUploadAudio>>,
        TError,
        {data: PostApiAudioRecordUploadAudioBody;params?: PostApiAudioRecordUploadAudioParams},
        TContext
      > => {

      const mutationOptions = getPostApiAudioRecordUploadAudioMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getApiAudioRecordGetAudio = (
    params?: GetApiAudioRecordGetAudioParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.get(
      `/api/AudioRecord/GetAudio`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetApiAudioRecordGetAudioQueryKey = (params?: GetApiAudioRecordGetAudioParams,) => {
    return [`/api/AudioRecord/GetAudio`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiAudioRecordGetAudioQueryOptions = <TData = Awaited<ReturnType<typeof getApiAudioRecordGetAudio>>, TError = AxiosError<unknown>>(params?: GetApiAudioRecordGetAudioParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAudioRecordGetAudio>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiAudioRecordGetAudioQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiAudioRecordGetAudio>>> = ({ signal }) => getApiAudioRecordGetAudio(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiAudioRecordGetAudio>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiAudioRecordGetAudioQueryResult = NonNullable<Awaited<ReturnType<typeof getApiAudioRecordGetAudio>>>
export type GetApiAudioRecordGetAudioQueryError = AxiosError<unknown>



export function useGetApiAudioRecordGetAudio<TData = Awaited<ReturnType<typeof getApiAudioRecordGetAudio>>, TError = AxiosError<unknown>>(
 params?: GetApiAudioRecordGetAudioParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAudioRecordGetAudio>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiAudioRecordGetAudioQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getApiAudioRecordGetAudioInfo = (
    params?: GetApiAudioRecordGetAudioInfoParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AudioInfoViewModel>> => {
    
    return axios.get(
      `/api/AudioRecord/GetAudioInfo`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetApiAudioRecordGetAudioInfoQueryKey = (params?: GetApiAudioRecordGetAudioInfoParams,) => {
    return [`/api/AudioRecord/GetAudioInfo`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiAudioRecordGetAudioInfoQueryOptions = <TData = Awaited<ReturnType<typeof getApiAudioRecordGetAudioInfo>>, TError = AxiosError<unknown>>(params?: GetApiAudioRecordGetAudioInfoParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAudioRecordGetAudioInfo>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiAudioRecordGetAudioInfoQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiAudioRecordGetAudioInfo>>> = ({ signal }) => getApiAudioRecordGetAudioInfo(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiAudioRecordGetAudioInfo>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiAudioRecordGetAudioInfoQueryResult = NonNullable<Awaited<ReturnType<typeof getApiAudioRecordGetAudioInfo>>>
export type GetApiAudioRecordGetAudioInfoQueryError = AxiosError<unknown>



export function useGetApiAudioRecordGetAudioInfo<TData = Awaited<ReturnType<typeof getApiAudioRecordGetAudioInfo>>, TError = AxiosError<unknown>>(
 params?: GetApiAudioRecordGetAudioInfoParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAudioRecordGetAudioInfo>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiAudioRecordGetAudioInfoQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const deleteApiAudioRecordDeleteAudio = (
    params?: DeleteApiAudioRecordDeleteAudioParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/api/AudioRecord/DeleteAudio`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getDeleteApiAudioRecordDeleteAudioMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiAudioRecordDeleteAudio>>, TError,{params?: DeleteApiAudioRecordDeleteAudioParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiAudioRecordDeleteAudio>>, TError,{params?: DeleteApiAudioRecordDeleteAudioParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiAudioRecordDeleteAudio>>, {params?: DeleteApiAudioRecordDeleteAudioParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteApiAudioRecordDeleteAudio(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiAudioRecordDeleteAudioMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiAudioRecordDeleteAudio>>>
    
    export type DeleteApiAudioRecordDeleteAudioMutationError = AxiosError<unknown>

    export const useDeleteApiAudioRecordDeleteAudio = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiAudioRecordDeleteAudio>>, TError,{params?: DeleteApiAudioRecordDeleteAudioParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiAudioRecordDeleteAudio>>,
        TError,
        {params?: DeleteApiAudioRecordDeleteAudioParams},
        TContext
      > => {

      const mutationOptions = getDeleteApiAudioRecordDeleteAudioMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const deleteApiAudioRecordClearAllEntries = (
    params?: DeleteApiAudioRecordClearAllEntriesParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<DataUpdateMeta>> => {
    
    return axios.delete(
      `/api/AudioRecord/ClearAllEntries`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getDeleteApiAudioRecordClearAllEntriesMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiAudioRecordClearAllEntries>>, TError,{params?: DeleteApiAudioRecordClearAllEntriesParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiAudioRecordClearAllEntries>>, TError,{params?: DeleteApiAudioRecordClearAllEntriesParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiAudioRecordClearAllEntries>>, {params?: DeleteApiAudioRecordClearAllEntriesParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteApiAudioRecordClearAllEntries(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiAudioRecordClearAllEntriesMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiAudioRecordClearAllEntries>>>
    
    export type DeleteApiAudioRecordClearAllEntriesMutationError = AxiosError<unknown>

    export const useDeleteApiAudioRecordClearAllEntries = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiAudioRecordClearAllEntries>>, TError,{params?: DeleteApiAudioRecordClearAllEntriesParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiAudioRecordClearAllEntries>>,
        TError,
        {params?: DeleteApiAudioRecordClearAllEntriesParams},
        TContext
      > => {

      const mutationOptions = getDeleteApiAudioRecordClearAllEntriesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const putApiAudioRecordUpdateEntry = (
    audioRecordEntry: AudioRecordEntry,
    params?: PutApiAudioRecordUpdateEntryParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<DataUpdateMeta>> => {
    
    return axios.put(
      `/api/AudioRecord/UpdateEntry`,
      audioRecordEntry,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getPutApiAudioRecordUpdateEntryMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiAudioRecordUpdateEntry>>, TError,{data: AudioRecordEntry;params?: PutApiAudioRecordUpdateEntryParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof putApiAudioRecordUpdateEntry>>, TError,{data: AudioRecordEntry;params?: PutApiAudioRecordUpdateEntryParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiAudioRecordUpdateEntry>>, {data: AudioRecordEntry;params?: PutApiAudioRecordUpdateEntryParams}> = (props) => {
          const {data,params} = props ?? {};

          return  putApiAudioRecordUpdateEntry(data,params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiAudioRecordUpdateEntryMutationResult = NonNullable<Awaited<ReturnType<typeof putApiAudioRecordUpdateEntry>>>
    export type PutApiAudioRecordUpdateEntryMutationBody = AudioRecordEntry
    export type PutApiAudioRecordUpdateEntryMutationError = AxiosError<unknown>

    export const usePutApiAudioRecordUpdateEntry = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiAudioRecordUpdateEntry>>, TError,{data: AudioRecordEntry;params?: PutApiAudioRecordUpdateEntryParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof putApiAudioRecordUpdateEntry>>,
        TError,
        {data: AudioRecordEntry;params?: PutApiAudioRecordUpdateEntryParams},
        TContext
      > => {

      const mutationOptions = getPutApiAudioRecordUpdateEntryMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const deleteApiAudioRecordDeleteEntry = (
    params?: DeleteApiAudioRecordDeleteEntryParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<DataUpdateMeta>> => {
    
    return axios.delete(
      `/api/AudioRecord/DeleteEntry`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getDeleteApiAudioRecordDeleteEntryMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiAudioRecordDeleteEntry>>, TError,{params?: DeleteApiAudioRecordDeleteEntryParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiAudioRecordDeleteEntry>>, TError,{params?: DeleteApiAudioRecordDeleteEntryParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiAudioRecordDeleteEntry>>, {params?: DeleteApiAudioRecordDeleteEntryParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteApiAudioRecordDeleteEntry(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiAudioRecordDeleteEntryMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiAudioRecordDeleteEntry>>>
    
    export type DeleteApiAudioRecordDeleteEntryMutationError = AxiosError<unknown>

    export const useDeleteApiAudioRecordDeleteEntry = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiAudioRecordDeleteEntry>>, TError,{params?: DeleteApiAudioRecordDeleteEntryParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiAudioRecordDeleteEntry>>,
        TError,
        {params?: DeleteApiAudioRecordDeleteEntryParams},
        TContext
      > => {

      const mutationOptions = getDeleteApiAudioRecordDeleteEntryMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getApiAudioRecordJobGetTranscriptionJob = (
    params?: GetApiAudioRecordJobGetTranscriptionJobParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AudioRecordTranscriptionJob>> => {
    
    return axios.get(
      `/api/AudioRecordJob/GetTranscriptionJob`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetApiAudioRecordJobGetTranscriptionJobQueryKey = (params?: GetApiAudioRecordJobGetTranscriptionJobParams,) => {
    return [`/api/AudioRecordJob/GetTranscriptionJob`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiAudioRecordJobGetTranscriptionJobQueryOptions = <TData = Awaited<ReturnType<typeof getApiAudioRecordJobGetTranscriptionJob>>, TError = AxiosError<unknown>>(params?: GetApiAudioRecordJobGetTranscriptionJobParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAudioRecordJobGetTranscriptionJob>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiAudioRecordJobGetTranscriptionJobQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiAudioRecordJobGetTranscriptionJob>>> = ({ signal }) => getApiAudioRecordJobGetTranscriptionJob(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiAudioRecordJobGetTranscriptionJob>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiAudioRecordJobGetTranscriptionJobQueryResult = NonNullable<Awaited<ReturnType<typeof getApiAudioRecordJobGetTranscriptionJob>>>
export type GetApiAudioRecordJobGetTranscriptionJobQueryError = AxiosError<unknown>



export function useGetApiAudioRecordJobGetTranscriptionJob<TData = Awaited<ReturnType<typeof getApiAudioRecordJobGetTranscriptionJob>>, TError = AxiosError<unknown>>(
 params?: GetApiAudioRecordJobGetTranscriptionJobParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAudioRecordJobGetTranscriptionJob>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiAudioRecordJobGetTranscriptionJobQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const postApiAudioRecordJobCreateTranscriptionJob = (
    params?: PostApiAudioRecordJobCreateTranscriptionJobParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `/api/AudioRecordJob/CreateTranscriptionJob`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getPostApiAudioRecordJobCreateTranscriptionJobMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAudioRecordJobCreateTranscriptionJob>>, TError,{params?: PostApiAudioRecordJobCreateTranscriptionJobParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiAudioRecordJobCreateTranscriptionJob>>, TError,{params?: PostApiAudioRecordJobCreateTranscriptionJobParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiAudioRecordJobCreateTranscriptionJob>>, {params?: PostApiAudioRecordJobCreateTranscriptionJobParams}> = (props) => {
          const {params} = props ?? {};

          return  postApiAudioRecordJobCreateTranscriptionJob(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiAudioRecordJobCreateTranscriptionJobMutationResult = NonNullable<Awaited<ReturnType<typeof postApiAudioRecordJobCreateTranscriptionJob>>>
    
    export type PostApiAudioRecordJobCreateTranscriptionJobMutationError = AxiosError<unknown>

    export const usePostApiAudioRecordJobCreateTranscriptionJob = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAudioRecordJobCreateTranscriptionJob>>, TError,{params?: PostApiAudioRecordJobCreateTranscriptionJobParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiAudioRecordJobCreateTranscriptionJob>>,
        TError,
        {params?: PostApiAudioRecordJobCreateTranscriptionJobParams},
        TContext
      > => {

      const mutationOptions = getPostApiAudioRecordJobCreateTranscriptionJobMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const deleteApiAudioRecordJobDeleteTranscriptionJob = (
    params?: DeleteApiAudioRecordJobDeleteTranscriptionJobParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/api/AudioRecordJob/DeleteTranscriptionJob`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getDeleteApiAudioRecordJobDeleteTranscriptionJobMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiAudioRecordJobDeleteTranscriptionJob>>, TError,{params?: DeleteApiAudioRecordJobDeleteTranscriptionJobParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiAudioRecordJobDeleteTranscriptionJob>>, TError,{params?: DeleteApiAudioRecordJobDeleteTranscriptionJobParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiAudioRecordJobDeleteTranscriptionJob>>, {params?: DeleteApiAudioRecordJobDeleteTranscriptionJobParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteApiAudioRecordJobDeleteTranscriptionJob(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiAudioRecordJobDeleteTranscriptionJobMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiAudioRecordJobDeleteTranscriptionJob>>>
    
    export type DeleteApiAudioRecordJobDeleteTranscriptionJobMutationError = AxiosError<unknown>

    export const useDeleteApiAudioRecordJobDeleteTranscriptionJob = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiAudioRecordJobDeleteTranscriptionJob>>, TError,{params?: DeleteApiAudioRecordJobDeleteTranscriptionJobParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiAudioRecordJobDeleteTranscriptionJob>>,
        TError,
        {params?: DeleteApiAudioRecordJobDeleteTranscriptionJobParams},
        TContext
      > => {

      const mutationOptions = getDeleteApiAudioRecordJobDeleteTranscriptionJobMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getApiAudioRecordJobGetTranslationJob = (
    params?: GetApiAudioRecordJobGetTranslationJobParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AudioRecordTranslationJob>> => {
    
    return axios.get(
      `/api/AudioRecordJob/GetTranslationJob`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetApiAudioRecordJobGetTranslationJobQueryKey = (params?: GetApiAudioRecordJobGetTranslationJobParams,) => {
    return [`/api/AudioRecordJob/GetTranslationJob`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiAudioRecordJobGetTranslationJobQueryOptions = <TData = Awaited<ReturnType<typeof getApiAudioRecordJobGetTranslationJob>>, TError = AxiosError<unknown>>(params?: GetApiAudioRecordJobGetTranslationJobParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAudioRecordJobGetTranslationJob>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiAudioRecordJobGetTranslationJobQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiAudioRecordJobGetTranslationJob>>> = ({ signal }) => getApiAudioRecordJobGetTranslationJob(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiAudioRecordJobGetTranslationJob>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiAudioRecordJobGetTranslationJobQueryResult = NonNullable<Awaited<ReturnType<typeof getApiAudioRecordJobGetTranslationJob>>>
export type GetApiAudioRecordJobGetTranslationJobQueryError = AxiosError<unknown>



export function useGetApiAudioRecordJobGetTranslationJob<TData = Awaited<ReturnType<typeof getApiAudioRecordJobGetTranslationJob>>, TError = AxiosError<unknown>>(
 params?: GetApiAudioRecordJobGetTranslationJobParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAudioRecordJobGetTranslationJob>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiAudioRecordJobGetTranslationJobQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const postApiAudioRecordJobCreateTranslationJob = (
    params?: PostApiAudioRecordJobCreateTranslationJobParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `/api/AudioRecordJob/CreateTranslationJob`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getPostApiAudioRecordJobCreateTranslationJobMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAudioRecordJobCreateTranslationJob>>, TError,{params?: PostApiAudioRecordJobCreateTranslationJobParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiAudioRecordJobCreateTranslationJob>>, TError,{params?: PostApiAudioRecordJobCreateTranslationJobParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiAudioRecordJobCreateTranslationJob>>, {params?: PostApiAudioRecordJobCreateTranslationJobParams}> = (props) => {
          const {params} = props ?? {};

          return  postApiAudioRecordJobCreateTranslationJob(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiAudioRecordJobCreateTranslationJobMutationResult = NonNullable<Awaited<ReturnType<typeof postApiAudioRecordJobCreateTranslationJob>>>
    
    export type PostApiAudioRecordJobCreateTranslationJobMutationError = AxiosError<unknown>

    export const usePostApiAudioRecordJobCreateTranslationJob = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAudioRecordJobCreateTranslationJob>>, TError,{params?: PostApiAudioRecordJobCreateTranslationJobParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiAudioRecordJobCreateTranslationJob>>,
        TError,
        {params?: PostApiAudioRecordJobCreateTranslationJobParams},
        TContext
      > => {

      const mutationOptions = getPostApiAudioRecordJobCreateTranslationJobMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const deleteApiAudioRecordJobDeleteTranslationJob = (
    params?: DeleteApiAudioRecordJobDeleteTranslationJobParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/api/AudioRecordJob/DeleteTranslationJob`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getDeleteApiAudioRecordJobDeleteTranslationJobMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiAudioRecordJobDeleteTranslationJob>>, TError,{params?: DeleteApiAudioRecordJobDeleteTranslationJobParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiAudioRecordJobDeleteTranslationJob>>, TError,{params?: DeleteApiAudioRecordJobDeleteTranslationJobParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiAudioRecordJobDeleteTranslationJob>>, {params?: DeleteApiAudioRecordJobDeleteTranslationJobParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteApiAudioRecordJobDeleteTranslationJob(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiAudioRecordJobDeleteTranslationJobMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiAudioRecordJobDeleteTranslationJob>>>
    
    export type DeleteApiAudioRecordJobDeleteTranslationJobMutationError = AxiosError<unknown>

    export const useDeleteApiAudioRecordJobDeleteTranslationJob = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiAudioRecordJobDeleteTranslationJob>>, TError,{params?: DeleteApiAudioRecordJobDeleteTranslationJobParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiAudioRecordJobDeleteTranslationJob>>,
        TError,
        {params?: DeleteApiAudioRecordJobDeleteTranslationJobParams},
        TContext
      > => {

      const mutationOptions = getDeleteApiAudioRecordJobDeleteTranslationJobMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getApiAudioRecordTextDocumentList = (
    params?: GetApiAudioRecordTextDocumentListParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TextDocumentBaseSearchListResult>> => {
    
    return axios.get(
      `/api/AudioRecordTextDocument/List`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetApiAudioRecordTextDocumentListQueryKey = (params?: GetApiAudioRecordTextDocumentListParams,) => {
    return [`/api/AudioRecordTextDocument/List`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiAudioRecordTextDocumentListQueryOptions = <TData = Awaited<ReturnType<typeof getApiAudioRecordTextDocumentList>>, TError = AxiosError<unknown>>(params?: GetApiAudioRecordTextDocumentListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAudioRecordTextDocumentList>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiAudioRecordTextDocumentListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiAudioRecordTextDocumentList>>> = ({ signal }) => getApiAudioRecordTextDocumentList(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiAudioRecordTextDocumentList>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiAudioRecordTextDocumentListQueryResult = NonNullable<Awaited<ReturnType<typeof getApiAudioRecordTextDocumentList>>>
export type GetApiAudioRecordTextDocumentListQueryError = AxiosError<unknown>



export function useGetApiAudioRecordTextDocumentList<TData = Awaited<ReturnType<typeof getApiAudioRecordTextDocumentList>>, TError = AxiosError<unknown>>(
 params?: GetApiAudioRecordTextDocumentListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAudioRecordTextDocumentList>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiAudioRecordTextDocumentListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getApiAudioRecordTextDocumentGet = (
    params?: GetApiAudioRecordTextDocumentGetParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TextDocument>> => {
    
    return axios.get(
      `/api/AudioRecordTextDocument/Get`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetApiAudioRecordTextDocumentGetQueryKey = (params?: GetApiAudioRecordTextDocumentGetParams,) => {
    return [`/api/AudioRecordTextDocument/Get`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiAudioRecordTextDocumentGetQueryOptions = <TData = Awaited<ReturnType<typeof getApiAudioRecordTextDocumentGet>>, TError = AxiosError<unknown>>(params?: GetApiAudioRecordTextDocumentGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAudioRecordTextDocumentGet>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiAudioRecordTextDocumentGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiAudioRecordTextDocumentGet>>> = ({ signal }) => getApiAudioRecordTextDocumentGet(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiAudioRecordTextDocumentGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiAudioRecordTextDocumentGetQueryResult = NonNullable<Awaited<ReturnType<typeof getApiAudioRecordTextDocumentGet>>>
export type GetApiAudioRecordTextDocumentGetQueryError = AxiosError<unknown>



export function useGetApiAudioRecordTextDocumentGet<TData = Awaited<ReturnType<typeof getApiAudioRecordTextDocumentGet>>, TError = AxiosError<unknown>>(
 params?: GetApiAudioRecordTextDocumentGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAudioRecordTextDocumentGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiAudioRecordTextDocumentGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const postApiAudioRecordTextDocumentCreate = (
    params?: PostApiAudioRecordTextDocumentCreateParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TextDocument>> => {
    
    return axios.post(
      `/api/AudioRecordTextDocument/Create`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getPostApiAudioRecordTextDocumentCreateMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAudioRecordTextDocumentCreate>>, TError,{params?: PostApiAudioRecordTextDocumentCreateParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiAudioRecordTextDocumentCreate>>, TError,{params?: PostApiAudioRecordTextDocumentCreateParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiAudioRecordTextDocumentCreate>>, {params?: PostApiAudioRecordTextDocumentCreateParams}> = (props) => {
          const {params} = props ?? {};

          return  postApiAudioRecordTextDocumentCreate(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiAudioRecordTextDocumentCreateMutationResult = NonNullable<Awaited<ReturnType<typeof postApiAudioRecordTextDocumentCreate>>>
    
    export type PostApiAudioRecordTextDocumentCreateMutationError = AxiosError<unknown>

    export const usePostApiAudioRecordTextDocumentCreate = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAudioRecordTextDocumentCreate>>, TError,{params?: PostApiAudioRecordTextDocumentCreateParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiAudioRecordTextDocumentCreate>>,
        TError,
        {params?: PostApiAudioRecordTextDocumentCreateParams},
        TContext
      > => {

      const mutationOptions = getPostApiAudioRecordTextDocumentCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const putApiAudioRecordTextDocumentUpdate = (
    textDocument: TextDocument,
    params?: PutApiAudioRecordTextDocumentUpdateParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<DataUpdateMeta>> => {
    
    return axios.put(
      `/api/AudioRecordTextDocument/Update`,
      textDocument,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getPutApiAudioRecordTextDocumentUpdateMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiAudioRecordTextDocumentUpdate>>, TError,{data: TextDocument;params?: PutApiAudioRecordTextDocumentUpdateParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof putApiAudioRecordTextDocumentUpdate>>, TError,{data: TextDocument;params?: PutApiAudioRecordTextDocumentUpdateParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiAudioRecordTextDocumentUpdate>>, {data: TextDocument;params?: PutApiAudioRecordTextDocumentUpdateParams}> = (props) => {
          const {data,params} = props ?? {};

          return  putApiAudioRecordTextDocumentUpdate(data,params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiAudioRecordTextDocumentUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof putApiAudioRecordTextDocumentUpdate>>>
    export type PutApiAudioRecordTextDocumentUpdateMutationBody = TextDocument
    export type PutApiAudioRecordTextDocumentUpdateMutationError = AxiosError<unknown>

    export const usePutApiAudioRecordTextDocumentUpdate = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiAudioRecordTextDocumentUpdate>>, TError,{data: TextDocument;params?: PutApiAudioRecordTextDocumentUpdateParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof putApiAudioRecordTextDocumentUpdate>>,
        TError,
        {data: TextDocument;params?: PutApiAudioRecordTextDocumentUpdateParams},
        TContext
      > => {

      const mutationOptions = getPutApiAudioRecordTextDocumentUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const deleteApiAudioRecordTextDocumentDelete = (
    params?: DeleteApiAudioRecordTextDocumentDeleteParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/api/AudioRecordTextDocument/Delete`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getDeleteApiAudioRecordTextDocumentDeleteMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiAudioRecordTextDocumentDelete>>, TError,{params?: DeleteApiAudioRecordTextDocumentDeleteParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiAudioRecordTextDocumentDelete>>, TError,{params?: DeleteApiAudioRecordTextDocumentDeleteParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiAudioRecordTextDocumentDelete>>, {params?: DeleteApiAudioRecordTextDocumentDeleteParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteApiAudioRecordTextDocumentDelete(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiAudioRecordTextDocumentDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiAudioRecordTextDocumentDelete>>>
    
    export type DeleteApiAudioRecordTextDocumentDeleteMutationError = AxiosError<unknown>

    export const useDeleteApiAudioRecordTextDocumentDelete = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiAudioRecordTextDocumentDelete>>, TError,{params?: DeleteApiAudioRecordTextDocumentDeleteParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiAudioRecordTextDocumentDelete>>,
        TError,
        {params?: DeleteApiAudioRecordTextDocumentDeleteParams},
        TContext
      > => {

      const mutationOptions = getDeleteApiAudioRecordTextDocumentDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getApiAudioRecordTextDocumentImageList = (
    params?: GetApiAudioRecordTextDocumentImageListParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<string[]>> => {
    
    return axios.get(
      `/api/AudioRecordTextDocumentImage/List`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetApiAudioRecordTextDocumentImageListQueryKey = (params?: GetApiAudioRecordTextDocumentImageListParams,) => {
    return [`/api/AudioRecordTextDocumentImage/List`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiAudioRecordTextDocumentImageListQueryOptions = <TData = Awaited<ReturnType<typeof getApiAudioRecordTextDocumentImageList>>, TError = AxiosError<unknown>>(params?: GetApiAudioRecordTextDocumentImageListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAudioRecordTextDocumentImageList>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiAudioRecordTextDocumentImageListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiAudioRecordTextDocumentImageList>>> = ({ signal }) => getApiAudioRecordTextDocumentImageList(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiAudioRecordTextDocumentImageList>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiAudioRecordTextDocumentImageListQueryResult = NonNullable<Awaited<ReturnType<typeof getApiAudioRecordTextDocumentImageList>>>
export type GetApiAudioRecordTextDocumentImageListQueryError = AxiosError<unknown>



export function useGetApiAudioRecordTextDocumentImageList<TData = Awaited<ReturnType<typeof getApiAudioRecordTextDocumentImageList>>, TError = AxiosError<unknown>>(
 params?: GetApiAudioRecordTextDocumentImageListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAudioRecordTextDocumentImageList>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiAudioRecordTextDocumentImageListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getApiAudioRecordTextDocumentImageGet = (
    params?: GetApiAudioRecordTextDocumentImageGetParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Blob>> => {
    
    return axios.get(
      `/api/AudioRecordTextDocumentImage/Get`,{
        responseType: 'blob',
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetApiAudioRecordTextDocumentImageGetQueryKey = (params?: GetApiAudioRecordTextDocumentImageGetParams,) => {
    return [`/api/AudioRecordTextDocumentImage/Get`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiAudioRecordTextDocumentImageGetQueryOptions = <TData = Awaited<ReturnType<typeof getApiAudioRecordTextDocumentImageGet>>, TError = AxiosError<unknown>>(params?: GetApiAudioRecordTextDocumentImageGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAudioRecordTextDocumentImageGet>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiAudioRecordTextDocumentImageGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiAudioRecordTextDocumentImageGet>>> = ({ signal }) => getApiAudioRecordTextDocumentImageGet(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiAudioRecordTextDocumentImageGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiAudioRecordTextDocumentImageGetQueryResult = NonNullable<Awaited<ReturnType<typeof getApiAudioRecordTextDocumentImageGet>>>
export type GetApiAudioRecordTextDocumentImageGetQueryError = AxiosError<unknown>



export function useGetApiAudioRecordTextDocumentImageGet<TData = Awaited<ReturnType<typeof getApiAudioRecordTextDocumentImageGet>>, TError = AxiosError<unknown>>(
 params?: GetApiAudioRecordTextDocumentImageGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAudioRecordTextDocumentImageGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiAudioRecordTextDocumentImageGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const putApiAudioRecordTextDocumentImageUpload = (
    putApiAudioRecordTextDocumentImageUploadBody: PutApiAudioRecordTextDocumentImageUploadBody,
    params?: PutApiAudioRecordTextDocumentImageUploadParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {const formData = new FormData();
if(putApiAudioRecordTextDocumentImageUploadBody.imageFile !== undefined) {
 formData.append('imageFile', putApiAudioRecordTextDocumentImageUploadBody.imageFile)
 }

    
    return axios.put(
      `/api/AudioRecordTextDocumentImage/Upload`,
      formData,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getPutApiAudioRecordTextDocumentImageUploadMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiAudioRecordTextDocumentImageUpload>>, TError,{data: PutApiAudioRecordTextDocumentImageUploadBody;params?: PutApiAudioRecordTextDocumentImageUploadParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof putApiAudioRecordTextDocumentImageUpload>>, TError,{data: PutApiAudioRecordTextDocumentImageUploadBody;params?: PutApiAudioRecordTextDocumentImageUploadParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiAudioRecordTextDocumentImageUpload>>, {data: PutApiAudioRecordTextDocumentImageUploadBody;params?: PutApiAudioRecordTextDocumentImageUploadParams}> = (props) => {
          const {data,params} = props ?? {};

          return  putApiAudioRecordTextDocumentImageUpload(data,params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiAudioRecordTextDocumentImageUploadMutationResult = NonNullable<Awaited<ReturnType<typeof putApiAudioRecordTextDocumentImageUpload>>>
    export type PutApiAudioRecordTextDocumentImageUploadMutationBody = PutApiAudioRecordTextDocumentImageUploadBody
    export type PutApiAudioRecordTextDocumentImageUploadMutationError = AxiosError<unknown>

    export const usePutApiAudioRecordTextDocumentImageUpload = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiAudioRecordTextDocumentImageUpload>>, TError,{data: PutApiAudioRecordTextDocumentImageUploadBody;params?: PutApiAudioRecordTextDocumentImageUploadParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof putApiAudioRecordTextDocumentImageUpload>>,
        TError,
        {data: PutApiAudioRecordTextDocumentImageUploadBody;params?: PutApiAudioRecordTextDocumentImageUploadParams},
        TContext
      > => {

      const mutationOptions = getPutApiAudioRecordTextDocumentImageUploadMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const deleteApiAudioRecordTextDocumentImageDelete = (
    params?: DeleteApiAudioRecordTextDocumentImageDeleteParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/api/AudioRecordTextDocumentImage/Delete`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getDeleteApiAudioRecordTextDocumentImageDeleteMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiAudioRecordTextDocumentImageDelete>>, TError,{params?: DeleteApiAudioRecordTextDocumentImageDeleteParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiAudioRecordTextDocumentImageDelete>>, TError,{params?: DeleteApiAudioRecordTextDocumentImageDeleteParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiAudioRecordTextDocumentImageDelete>>, {params?: DeleteApiAudioRecordTextDocumentImageDeleteParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteApiAudioRecordTextDocumentImageDelete(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiAudioRecordTextDocumentImageDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiAudioRecordTextDocumentImageDelete>>>
    
    export type DeleteApiAudioRecordTextDocumentImageDeleteMutationError = AxiosError<unknown>

    export const useDeleteApiAudioRecordTextDocumentImageDelete = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiAudioRecordTextDocumentImageDelete>>, TError,{params?: DeleteApiAudioRecordTextDocumentImageDeleteParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiAudioRecordTextDocumentImageDelete>>,
        TError,
        {params?: DeleteApiAudioRecordTextDocumentImageDeleteParams},
        TContext
      > => {

      const mutationOptions = getDeleteApiAudioRecordTextDocumentImageDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getApiAdminAudioToTextBalancerListCurrentWeight = (
    params?: GetApiAdminAudioToTextBalancerListCurrentWeightParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<STTWeightViewModel[]>> => {
    
    return axios.get(
      `/api/admin/AudioToTextBalancer/ListCurrentWeight`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetApiAdminAudioToTextBalancerListCurrentWeightQueryKey = (params?: GetApiAdminAudioToTextBalancerListCurrentWeightParams,) => {
    return [`/api/admin/AudioToTextBalancer/ListCurrentWeight`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiAdminAudioToTextBalancerListCurrentWeightQueryOptions = <TData = Awaited<ReturnType<typeof getApiAdminAudioToTextBalancerListCurrentWeight>>, TError = AxiosError<unknown>>(params?: GetApiAdminAudioToTextBalancerListCurrentWeightParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAdminAudioToTextBalancerListCurrentWeight>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiAdminAudioToTextBalancerListCurrentWeightQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiAdminAudioToTextBalancerListCurrentWeight>>> = ({ signal }) => getApiAdminAudioToTextBalancerListCurrentWeight(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiAdminAudioToTextBalancerListCurrentWeight>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiAdminAudioToTextBalancerListCurrentWeightQueryResult = NonNullable<Awaited<ReturnType<typeof getApiAdminAudioToTextBalancerListCurrentWeight>>>
export type GetApiAdminAudioToTextBalancerListCurrentWeightQueryError = AxiosError<unknown>



export function useGetApiAdminAudioToTextBalancerListCurrentWeight<TData = Awaited<ReturnType<typeof getApiAdminAudioToTextBalancerListCurrentWeight>>, TError = AxiosError<unknown>>(
 params?: GetApiAdminAudioToTextBalancerListCurrentWeightParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAdminAudioToTextBalancerListCurrentWeight>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiAdminAudioToTextBalancerListCurrentWeightQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getApiAdminAudioToTextBalancerListSource = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AudioToTextBalancerViewModel[]>> => {
    
    return axios.get(
      `/api/admin/AudioToTextBalancer/ListSource`,options
    );
  }


export const getGetApiAdminAudioToTextBalancerListSourceQueryKey = () => {
    return [`/api/admin/AudioToTextBalancer/ListSource`] as const;
    }

    
export const getGetApiAdminAudioToTextBalancerListSourceQueryOptions = <TData = Awaited<ReturnType<typeof getApiAdminAudioToTextBalancerListSource>>, TError = AxiosError<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAdminAudioToTextBalancerListSource>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiAdminAudioToTextBalancerListSourceQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiAdminAudioToTextBalancerListSource>>> = ({ signal }) => getApiAdminAudioToTextBalancerListSource({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiAdminAudioToTextBalancerListSource>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiAdminAudioToTextBalancerListSourceQueryResult = NonNullable<Awaited<ReturnType<typeof getApiAdminAudioToTextBalancerListSource>>>
export type GetApiAdminAudioToTextBalancerListSourceQueryError = AxiosError<unknown>



export function useGetApiAdminAudioToTextBalancerListSource<TData = Awaited<ReturnType<typeof getApiAdminAudioToTextBalancerListSource>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAdminAudioToTextBalancerListSource>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiAdminAudioToTextBalancerListSourceQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const postApiAdminAudioToTextBalancerSearchAverge = (
    params?: PostApiAdminAudioToTextBalancerSearchAvergeParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AudioToTextRecordAvergeViewModel>> => {
    
    return axios.post(
      `/api/admin/AudioToTextBalancer/SearchAverge`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getPostApiAdminAudioToTextBalancerSearchAvergeMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAdminAudioToTextBalancerSearchAverge>>, TError,{params?: PostApiAdminAudioToTextBalancerSearchAvergeParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiAdminAudioToTextBalancerSearchAverge>>, TError,{params?: PostApiAdminAudioToTextBalancerSearchAvergeParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiAdminAudioToTextBalancerSearchAverge>>, {params?: PostApiAdminAudioToTextBalancerSearchAvergeParams}> = (props) => {
          const {params} = props ?? {};

          return  postApiAdminAudioToTextBalancerSearchAverge(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiAdminAudioToTextBalancerSearchAvergeMutationResult = NonNullable<Awaited<ReturnType<typeof postApiAdminAudioToTextBalancerSearchAverge>>>
    
    export type PostApiAdminAudioToTextBalancerSearchAvergeMutationError = AxiosError<unknown>

    export const usePostApiAdminAudioToTextBalancerSearchAverge = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAdminAudioToTextBalancerSearchAverge>>, TError,{params?: PostApiAdminAudioToTextBalancerSearchAvergeParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiAdminAudioToTextBalancerSearchAverge>>,
        TError,
        {params?: PostApiAdminAudioToTextBalancerSearchAvergeParams},
        TContext
      > => {

      const mutationOptions = getPostApiAdminAudioToTextBalancerSearchAvergeMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiAdminAudioToTextBalancerSearch = (
    params?: PostApiAdminAudioToTextBalancerSearchParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AudioToTextRecord[]>> => {
    
    return axios.post(
      `/api/admin/AudioToTextBalancer/Search`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getPostApiAdminAudioToTextBalancerSearchMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAdminAudioToTextBalancerSearch>>, TError,{params?: PostApiAdminAudioToTextBalancerSearchParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiAdminAudioToTextBalancerSearch>>, TError,{params?: PostApiAdminAudioToTextBalancerSearchParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiAdminAudioToTextBalancerSearch>>, {params?: PostApiAdminAudioToTextBalancerSearchParams}> = (props) => {
          const {params} = props ?? {};

          return  postApiAdminAudioToTextBalancerSearch(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiAdminAudioToTextBalancerSearchMutationResult = NonNullable<Awaited<ReturnType<typeof postApiAdminAudioToTextBalancerSearch>>>
    
    export type PostApiAdminAudioToTextBalancerSearchMutationError = AxiosError<unknown>

    export const usePostApiAdminAudioToTextBalancerSearch = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAdminAudioToTextBalancerSearch>>, TError,{params?: PostApiAdminAudioToTextBalancerSearchParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiAdminAudioToTextBalancerSearch>>,
        TError,
        {params?: PostApiAdminAudioToTextBalancerSearchParams},
        TContext
      > => {

      const mutationOptions = getPostApiAdminAudioToTextBalancerSearchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiAuthLogin = (
    authViewModel: AuthViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AuthState>> => {
    
    return axios.post(
      `/api/Auth/Login`,
      authViewModel,options
    );
  }



export const getPostApiAuthLoginMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAuthLogin>>, TError,{data: AuthViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiAuthLogin>>, TError,{data: AuthViewModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiAuthLogin>>, {data: AuthViewModel}> = (props) => {
          const {data} = props ?? {};

          return  postApiAuthLogin(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiAuthLoginMutationResult = NonNullable<Awaited<ReturnType<typeof postApiAuthLogin>>>
    export type PostApiAuthLoginMutationBody = AuthViewModel
    export type PostApiAuthLoginMutationError = AxiosError<unknown>

    export const usePostApiAuthLogin = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAuthLogin>>, TError,{data: AuthViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiAuthLogin>>,
        TError,
        {data: AuthViewModel},
        TContext
      > => {

      const mutationOptions = getPostApiAuthLoginMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getApiAuthState = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AuthState>> => {
    
    return axios.get(
      `/api/Auth/State`,options
    );
  }


export const getGetApiAuthStateQueryKey = () => {
    return [`/api/Auth/State`] as const;
    }

    
export const getGetApiAuthStateQueryOptions = <TData = Awaited<ReturnType<typeof getApiAuthState>>, TError = AxiosError<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAuthState>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiAuthStateQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiAuthState>>> = ({ signal }) => getApiAuthState({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiAuthState>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiAuthStateQueryResult = NonNullable<Awaited<ReturnType<typeof getApiAuthState>>>
export type GetApiAuthStateQueryError = AxiosError<unknown>



export function useGetApiAuthState<TData = Awaited<ReturnType<typeof getApiAuthState>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAuthState>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiAuthStateQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const deleteApiAuthLogout = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/api/Auth/Logout`,options
    );
  }



export const getDeleteApiAuthLogoutMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiAuthLogout>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiAuthLogout>>, TError,void, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiAuthLogout>>, void> = () => {
          

          return  deleteApiAuthLogout(axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiAuthLogoutMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiAuthLogout>>>
    
    export type DeleteApiAuthLogoutMutationError = AxiosError<unknown>

    export const useDeleteApiAuthLogout = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiAuthLogout>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiAuthLogout>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getDeleteApiAuthLogoutMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiAdminAuthLogin = (
    authViewModel: AuthViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AuthState>> => {
    
    return axios.post(
      `/api/admin/Auth/Login`,
      authViewModel,options
    );
  }



export const getPostApiAdminAuthLoginMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAdminAuthLogin>>, TError,{data: AuthViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiAdminAuthLogin>>, TError,{data: AuthViewModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiAdminAuthLogin>>, {data: AuthViewModel}> = (props) => {
          const {data} = props ?? {};

          return  postApiAdminAuthLogin(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiAdminAuthLoginMutationResult = NonNullable<Awaited<ReturnType<typeof postApiAdminAuthLogin>>>
    export type PostApiAdminAuthLoginMutationBody = AuthViewModel
    export type PostApiAdminAuthLoginMutationError = AxiosError<unknown>

    export const usePostApiAdminAuthLogin = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAdminAuthLogin>>, TError,{data: AuthViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiAdminAuthLogin>>,
        TError,
        {data: AuthViewModel},
        TContext
      > => {

      const mutationOptions = getPostApiAdminAuthLoginMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getApiCreditKeyList = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<CreditKey[]>> => {
    
    return axios.get(
      `/api/CreditKey/List`,options
    );
  }


export const getGetApiCreditKeyListQueryKey = () => {
    return [`/api/CreditKey/List`] as const;
    }

    
export const getGetApiCreditKeyListQueryOptions = <TData = Awaited<ReturnType<typeof getApiCreditKeyList>>, TError = AxiosError<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiCreditKeyList>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiCreditKeyListQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiCreditKeyList>>> = ({ signal }) => getApiCreditKeyList({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiCreditKeyList>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiCreditKeyListQueryResult = NonNullable<Awaited<ReturnType<typeof getApiCreditKeyList>>>
export type GetApiCreditKeyListQueryError = AxiosError<unknown>



export function useGetApiCreditKeyList<TData = Awaited<ReturnType<typeof getApiCreditKeyList>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiCreditKeyList>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiCreditKeyListQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getApiAdminCreditKeyList = (
    params?: GetApiAdminCreditKeyListParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<CreditKey[]>> => {
    
    return axios.get(
      `/api/admin/CreditKey/List`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetApiAdminCreditKeyListQueryKey = (params?: GetApiAdminCreditKeyListParams,) => {
    return [`/api/admin/CreditKey/List`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiAdminCreditKeyListQueryOptions = <TData = Awaited<ReturnType<typeof getApiAdminCreditKeyList>>, TError = AxiosError<unknown>>(params?: GetApiAdminCreditKeyListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAdminCreditKeyList>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiAdminCreditKeyListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiAdminCreditKeyList>>> = ({ signal }) => getApiAdminCreditKeyList(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiAdminCreditKeyList>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiAdminCreditKeyListQueryResult = NonNullable<Awaited<ReturnType<typeof getApiAdminCreditKeyList>>>
export type GetApiAdminCreditKeyListQueryError = AxiosError<unknown>



export function useGetApiAdminCreditKeyList<TData = Awaited<ReturnType<typeof getApiAdminCreditKeyList>>, TError = AxiosError<unknown>>(
 params?: GetApiAdminCreditKeyListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAdminCreditKeyList>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiAdminCreditKeyListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getApiAdminCreditKeyGet = (
    params?: GetApiAdminCreditKeyGetParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<CreditKey>> => {
    
    return axios.get(
      `/api/admin/CreditKey/Get`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetApiAdminCreditKeyGetQueryKey = (params?: GetApiAdminCreditKeyGetParams,) => {
    return [`/api/admin/CreditKey/Get`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiAdminCreditKeyGetQueryOptions = <TData = Awaited<ReturnType<typeof getApiAdminCreditKeyGet>>, TError = AxiosError<unknown>>(params?: GetApiAdminCreditKeyGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAdminCreditKeyGet>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiAdminCreditKeyGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiAdminCreditKeyGet>>> = ({ signal }) => getApiAdminCreditKeyGet(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiAdminCreditKeyGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiAdminCreditKeyGetQueryResult = NonNullable<Awaited<ReturnType<typeof getApiAdminCreditKeyGet>>>
export type GetApiAdminCreditKeyGetQueryError = AxiosError<unknown>



export function useGetApiAdminCreditKeyGet<TData = Awaited<ReturnType<typeof getApiAdminCreditKeyGet>>, TError = AxiosError<unknown>>(
 params?: GetApiAdminCreditKeyGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAdminCreditKeyGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiAdminCreditKeyGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const postApiAdminCreditKeyAdd = (
    addCreditKeyViewModel: AddCreditKeyViewModel,
    params?: PostApiAdminCreditKeyAddParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `/api/admin/CreditKey/Add`,
      addCreditKeyViewModel,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getPostApiAdminCreditKeyAddMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAdminCreditKeyAdd>>, TError,{data: AddCreditKeyViewModel;params?: PostApiAdminCreditKeyAddParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiAdminCreditKeyAdd>>, TError,{data: AddCreditKeyViewModel;params?: PostApiAdminCreditKeyAddParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiAdminCreditKeyAdd>>, {data: AddCreditKeyViewModel;params?: PostApiAdminCreditKeyAddParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postApiAdminCreditKeyAdd(data,params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiAdminCreditKeyAddMutationResult = NonNullable<Awaited<ReturnType<typeof postApiAdminCreditKeyAdd>>>
    export type PostApiAdminCreditKeyAddMutationBody = AddCreditKeyViewModel
    export type PostApiAdminCreditKeyAddMutationError = AxiosError<unknown>

    export const usePostApiAdminCreditKeyAdd = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAdminCreditKeyAdd>>, TError,{data: AddCreditKeyViewModel;params?: PostApiAdminCreditKeyAddParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiAdminCreditKeyAdd>>,
        TError,
        {data: AddCreditKeyViewModel;params?: PostApiAdminCreditKeyAddParams},
        TContext
      > => {

      const mutationOptions = getPostApiAdminCreditKeyAddMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const patchApiAdminCreditKeyEdit = (
    editCreditKeyViewModel: EditCreditKeyViewModel,
    params?: PatchApiAdminCreditKeyEditParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.patch(
      `/api/admin/CreditKey/Edit`,
      editCreditKeyViewModel,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getPatchApiAdminCreditKeyEditMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiAdminCreditKeyEdit>>, TError,{data: EditCreditKeyViewModel;params?: PatchApiAdminCreditKeyEditParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof patchApiAdminCreditKeyEdit>>, TError,{data: EditCreditKeyViewModel;params?: PatchApiAdminCreditKeyEditParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiAdminCreditKeyEdit>>, {data: EditCreditKeyViewModel;params?: PatchApiAdminCreditKeyEditParams}> = (props) => {
          const {data,params} = props ?? {};

          return  patchApiAdminCreditKeyEdit(data,params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchApiAdminCreditKeyEditMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiAdminCreditKeyEdit>>>
    export type PatchApiAdminCreditKeyEditMutationBody = EditCreditKeyViewModel
    export type PatchApiAdminCreditKeyEditMutationError = AxiosError<unknown>

    export const usePatchApiAdminCreditKeyEdit = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiAdminCreditKeyEdit>>, TError,{data: EditCreditKeyViewModel;params?: PatchApiAdminCreditKeyEditParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof patchApiAdminCreditKeyEdit>>,
        TError,
        {data: EditCreditKeyViewModel;params?: PatchApiAdminCreditKeyEditParams},
        TContext
      > => {

      const mutationOptions = getPatchApiAdminCreditKeyEditMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const patchApiAdminCreditKeyIncreaseBalance = (
    params?: PatchApiAdminCreditKeyIncreaseBalanceParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.patch(
      `/api/admin/CreditKey/IncreaseBalance`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getPatchApiAdminCreditKeyIncreaseBalanceMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiAdminCreditKeyIncreaseBalance>>, TError,{params?: PatchApiAdminCreditKeyIncreaseBalanceParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof patchApiAdminCreditKeyIncreaseBalance>>, TError,{params?: PatchApiAdminCreditKeyIncreaseBalanceParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiAdminCreditKeyIncreaseBalance>>, {params?: PatchApiAdminCreditKeyIncreaseBalanceParams}> = (props) => {
          const {params} = props ?? {};

          return  patchApiAdminCreditKeyIncreaseBalance(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchApiAdminCreditKeyIncreaseBalanceMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiAdminCreditKeyIncreaseBalance>>>
    
    export type PatchApiAdminCreditKeyIncreaseBalanceMutationError = AxiosError<unknown>

    export const usePatchApiAdminCreditKeyIncreaseBalance = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiAdminCreditKeyIncreaseBalance>>, TError,{params?: PatchApiAdminCreditKeyIncreaseBalanceParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof patchApiAdminCreditKeyIncreaseBalance>>,
        TError,
        {params?: PatchApiAdminCreditKeyIncreaseBalanceParams},
        TContext
      > => {

      const mutationOptions = getPatchApiAdminCreditKeyIncreaseBalanceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const deleteApiAdminCreditKeyDelete = (
    params?: DeleteApiAdminCreditKeyDeleteParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/api/admin/CreditKey/Delete`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getDeleteApiAdminCreditKeyDeleteMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiAdminCreditKeyDelete>>, TError,{params?: DeleteApiAdminCreditKeyDeleteParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiAdminCreditKeyDelete>>, TError,{params?: DeleteApiAdminCreditKeyDeleteParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiAdminCreditKeyDelete>>, {params?: DeleteApiAdminCreditKeyDeleteParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteApiAdminCreditKeyDelete(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiAdminCreditKeyDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiAdminCreditKeyDelete>>>
    
    export type DeleteApiAdminCreditKeyDeleteMutationError = AxiosError<unknown>

    export const useDeleteApiAdminCreditKeyDelete = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiAdminCreditKeyDelete>>, TError,{params?: DeleteApiAdminCreditKeyDeleteParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiAdminCreditKeyDelete>>,
        TError,
        {params?: DeleteApiAdminCreditKeyDeleteParams},
        TContext
      > => {

      const mutationOptions = getDeleteApiAdminCreditKeyDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiCreditKeyLinePayPurchase = (
    params?: PostApiCreditKeyLinePayPurchaseParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<LinePayRequestPaymentUrl>> => {
    
    return axios.post(
      `/api/CreditKeyLinePay/Purchase`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getPostApiCreditKeyLinePayPurchaseMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiCreditKeyLinePayPurchase>>, TError,{params?: PostApiCreditKeyLinePayPurchaseParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiCreditKeyLinePayPurchase>>, TError,{params?: PostApiCreditKeyLinePayPurchaseParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiCreditKeyLinePayPurchase>>, {params?: PostApiCreditKeyLinePayPurchaseParams}> = (props) => {
          const {params} = props ?? {};

          return  postApiCreditKeyLinePayPurchase(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiCreditKeyLinePayPurchaseMutationResult = NonNullable<Awaited<ReturnType<typeof postApiCreditKeyLinePayPurchase>>>
    
    export type PostApiCreditKeyLinePayPurchaseMutationError = AxiosError<unknown>

    export const usePostApiCreditKeyLinePayPurchase = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiCreditKeyLinePayPurchase>>, TError,{params?: PostApiCreditKeyLinePayPurchaseParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiCreditKeyLinePayPurchase>>,
        TError,
        {params?: PostApiCreditKeyLinePayPurchaseParams},
        TContext
      > => {

      const mutationOptions = getPostApiCreditKeyLinePayPurchaseMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiCreditKeyLinePayConfirm = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `/api/CreditKeyLinePay/Confirm`,undefined,options
    );
  }



export const getPostApiCreditKeyLinePayConfirmMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiCreditKeyLinePayConfirm>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiCreditKeyLinePayConfirm>>, TError,void, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiCreditKeyLinePayConfirm>>, void> = () => {
          

          return  postApiCreditKeyLinePayConfirm(axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiCreditKeyLinePayConfirmMutationResult = NonNullable<Awaited<ReturnType<typeof postApiCreditKeyLinePayConfirm>>>
    
    export type PostApiCreditKeyLinePayConfirmMutationError = AxiosError<unknown>

    export const usePostApiCreditKeyLinePayConfirm = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiCreditKeyLinePayConfirm>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiCreditKeyLinePayConfirm>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getPostApiCreditKeyLinePayConfirmMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getLinepayCreditkeyCallback = (
    params?: GetLinepayCreditkeyCallbackParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.get(
      `/linepay/creditkey/callback`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetLinepayCreditkeyCallbackQueryKey = (params?: GetLinepayCreditkeyCallbackParams,) => {
    return [`/linepay/creditkey/callback`, ...(params ? [params]: [])] as const;
    }

    
export const getGetLinepayCreditkeyCallbackQueryOptions = <TData = Awaited<ReturnType<typeof getLinepayCreditkeyCallback>>, TError = AxiosError<unknown>>(params?: GetLinepayCreditkeyCallbackParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getLinepayCreditkeyCallback>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetLinepayCreditkeyCallbackQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getLinepayCreditkeyCallback>>> = ({ signal }) => getLinepayCreditkeyCallback(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getLinepayCreditkeyCallback>>, TError, TData> & { queryKey: QueryKey }
}

export type GetLinepayCreditkeyCallbackQueryResult = NonNullable<Awaited<ReturnType<typeof getLinepayCreditkeyCallback>>>
export type GetLinepayCreditkeyCallbackQueryError = AxiosError<unknown>



export function useGetLinepayCreditkeyCallback<TData = Awaited<ReturnType<typeof getLinepayCreditkeyCallback>>, TError = AxiosError<unknown>>(
 params?: GetLinepayCreditkeyCallbackParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getLinepayCreditkeyCallback>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetLinepayCreditkeyCallbackQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getApiAdminLicenseGetLicense = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<LicenseModel>> => {
    
    return axios.get(
      `/api/admin/License/GetLicense`,options
    );
  }


export const getGetApiAdminLicenseGetLicenseQueryKey = () => {
    return [`/api/admin/License/GetLicense`] as const;
    }

    
export const getGetApiAdminLicenseGetLicenseQueryOptions = <TData = Awaited<ReturnType<typeof getApiAdminLicenseGetLicense>>, TError = AxiosError<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAdminLicenseGetLicense>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiAdminLicenseGetLicenseQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiAdminLicenseGetLicense>>> = ({ signal }) => getApiAdminLicenseGetLicense({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiAdminLicenseGetLicense>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiAdminLicenseGetLicenseQueryResult = NonNullable<Awaited<ReturnType<typeof getApiAdminLicenseGetLicense>>>
export type GetApiAdminLicenseGetLicenseQueryError = AxiosError<unknown>



export function useGetApiAdminLicenseGetLicense<TData = Awaited<ReturnType<typeof getApiAdminLicenseGetLicense>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAdminLicenseGetLicense>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiAdminLicenseGetLicenseQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const deleteApiAdminLicenseDeleteLicense = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<LicenseModel>> => {
    
    return axios.delete(
      `/api/admin/License/DeleteLicense`,options
    );
  }



export const getDeleteApiAdminLicenseDeleteLicenseMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiAdminLicenseDeleteLicense>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiAdminLicenseDeleteLicense>>, TError,void, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiAdminLicenseDeleteLicense>>, void> = () => {
          

          return  deleteApiAdminLicenseDeleteLicense(axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiAdminLicenseDeleteLicenseMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiAdminLicenseDeleteLicense>>>
    
    export type DeleteApiAdminLicenseDeleteLicenseMutationError = AxiosError<unknown>

    export const useDeleteApiAdminLicenseDeleteLicense = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiAdminLicenseDeleteLicense>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiAdminLicenseDeleteLicense>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getDeleteApiAdminLicenseDeleteLicenseMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getApiAdminLicenseLicenseStatus = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<LicenseValidationModel>> => {
    
    return axios.get(
      `/api/admin/License/LicenseStatus`,options
    );
  }


export const getGetApiAdminLicenseLicenseStatusQueryKey = () => {
    return [`/api/admin/License/LicenseStatus`] as const;
    }

    
export const getGetApiAdminLicenseLicenseStatusQueryOptions = <TData = Awaited<ReturnType<typeof getApiAdminLicenseLicenseStatus>>, TError = AxiosError<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAdminLicenseLicenseStatus>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiAdminLicenseLicenseStatusQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiAdminLicenseLicenseStatus>>> = ({ signal }) => getApiAdminLicenseLicenseStatus({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiAdminLicenseLicenseStatus>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiAdminLicenseLicenseStatusQueryResult = NonNullable<Awaited<ReturnType<typeof getApiAdminLicenseLicenseStatus>>>
export type GetApiAdminLicenseLicenseStatusQueryError = AxiosError<unknown>



export function useGetApiAdminLicenseLicenseStatus<TData = Awaited<ReturnType<typeof getApiAdminLicenseLicenseStatus>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAdminLicenseLicenseStatus>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiAdminLicenseLicenseStatusQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const putApiAdminLicenseUpdateLicense = (
    licenseModel: LicenseModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<LicenseData>> => {
    
    return axios.put(
      `/api/admin/License/UpdateLicense`,
      licenseModel,options
    );
  }



export const getPutApiAdminLicenseUpdateLicenseMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiAdminLicenseUpdateLicense>>, TError,{data: LicenseModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof putApiAdminLicenseUpdateLicense>>, TError,{data: LicenseModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiAdminLicenseUpdateLicense>>, {data: LicenseModel}> = (props) => {
          const {data} = props ?? {};

          return  putApiAdminLicenseUpdateLicense(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiAdminLicenseUpdateLicenseMutationResult = NonNullable<Awaited<ReturnType<typeof putApiAdminLicenseUpdateLicense>>>
    export type PutApiAdminLicenseUpdateLicenseMutationBody = LicenseModel
    export type PutApiAdminLicenseUpdateLicenseMutationError = AxiosError<unknown>

    export const usePutApiAdminLicenseUpdateLicense = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiAdminLicenseUpdateLicense>>, TError,{data: LicenseModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof putApiAdminLicenseUpdateLicense>>,
        TError,
        {data: LicenseModel},
        TContext
      > => {

      const mutationOptions = getPutApiAdminLicenseUpdateLicenseMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getApiLinePayPaymentList = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<LinePayPayment[]>> => {
    
    return axios.get(
      `/api/LinePayPayment/List`,options
    );
  }


export const getGetApiLinePayPaymentListQueryKey = () => {
    return [`/api/LinePayPayment/List`] as const;
    }

    
export const getGetApiLinePayPaymentListQueryOptions = <TData = Awaited<ReturnType<typeof getApiLinePayPaymentList>>, TError = AxiosError<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiLinePayPaymentList>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiLinePayPaymentListQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiLinePayPaymentList>>> = ({ signal }) => getApiLinePayPaymentList({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiLinePayPaymentList>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiLinePayPaymentListQueryResult = NonNullable<Awaited<ReturnType<typeof getApiLinePayPaymentList>>>
export type GetApiLinePayPaymentListQueryError = AxiosError<unknown>



export function useGetApiLinePayPaymentList<TData = Awaited<ReturnType<typeof getApiLinePayPaymentList>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiLinePayPaymentList>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiLinePayPaymentListQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const deleteApiLinePayPaymentCancel = (
    params?: DeleteApiLinePayPaymentCancelParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/api/LinePayPayment/Cancel`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getDeleteApiLinePayPaymentCancelMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiLinePayPaymentCancel>>, TError,{params?: DeleteApiLinePayPaymentCancelParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiLinePayPaymentCancel>>, TError,{params?: DeleteApiLinePayPaymentCancelParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiLinePayPaymentCancel>>, {params?: DeleteApiLinePayPaymentCancelParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteApiLinePayPaymentCancel(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiLinePayPaymentCancelMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiLinePayPaymentCancel>>>
    
    export type DeleteApiLinePayPaymentCancelMutationError = AxiosError<unknown>

    export const useDeleteApiLinePayPaymentCancel = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiLinePayPaymentCancel>>, TError,{params?: DeleteApiLinePayPaymentCancelParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiLinePayPaymentCancel>>,
        TError,
        {params?: DeleteApiLinePayPaymentCancelParams},
        TContext
      > => {

      const mutationOptions = getDeleteApiLinePayPaymentCancelMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getApiAdminLinePayPaymentSearchDetails = (
    params?: GetApiAdminLinePayPaymentSearchDetailsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<LinePayPaymentDetailsViewModel>> => {
    
    return axios.get(
      `/api/admin/LinePayPayment/SearchDetails`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetApiAdminLinePayPaymentSearchDetailsQueryKey = (params?: GetApiAdminLinePayPaymentSearchDetailsParams,) => {
    return [`/api/admin/LinePayPayment/SearchDetails`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiAdminLinePayPaymentSearchDetailsQueryOptions = <TData = Awaited<ReturnType<typeof getApiAdminLinePayPaymentSearchDetails>>, TError = AxiosError<unknown>>(params?: GetApiAdminLinePayPaymentSearchDetailsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAdminLinePayPaymentSearchDetails>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiAdminLinePayPaymentSearchDetailsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiAdminLinePayPaymentSearchDetails>>> = ({ signal }) => getApiAdminLinePayPaymentSearchDetails(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiAdminLinePayPaymentSearchDetails>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiAdminLinePayPaymentSearchDetailsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiAdminLinePayPaymentSearchDetails>>>
export type GetApiAdminLinePayPaymentSearchDetailsQueryError = AxiosError<unknown>



export function useGetApiAdminLinePayPaymentSearchDetails<TData = Awaited<ReturnType<typeof getApiAdminLinePayPaymentSearchDetails>>, TError = AxiosError<unknown>>(
 params?: GetApiAdminLinePayPaymentSearchDetailsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAdminLinePayPaymentSearchDetails>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiAdminLinePayPaymentSearchDetailsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getApiAdminLinePayPaymentListPaymentsByUser = (
    params?: GetApiAdminLinePayPaymentListPaymentsByUserParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<LinePayPayment[]>> => {
    
    return axios.get(
      `/api/admin/LinePayPayment/ListPaymentsByUser`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetApiAdminLinePayPaymentListPaymentsByUserQueryKey = (params?: GetApiAdminLinePayPaymentListPaymentsByUserParams,) => {
    return [`/api/admin/LinePayPayment/ListPaymentsByUser`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiAdminLinePayPaymentListPaymentsByUserQueryOptions = <TData = Awaited<ReturnType<typeof getApiAdminLinePayPaymentListPaymentsByUser>>, TError = AxiosError<unknown>>(params?: GetApiAdminLinePayPaymentListPaymentsByUserParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAdminLinePayPaymentListPaymentsByUser>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiAdminLinePayPaymentListPaymentsByUserQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiAdminLinePayPaymentListPaymentsByUser>>> = ({ signal }) => getApiAdminLinePayPaymentListPaymentsByUser(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiAdminLinePayPaymentListPaymentsByUser>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiAdminLinePayPaymentListPaymentsByUserQueryResult = NonNullable<Awaited<ReturnType<typeof getApiAdminLinePayPaymentListPaymentsByUser>>>
export type GetApiAdminLinePayPaymentListPaymentsByUserQueryError = AxiosError<unknown>



export function useGetApiAdminLinePayPaymentListPaymentsByUser<TData = Awaited<ReturnType<typeof getApiAdminLinePayPaymentListPaymentsByUser>>, TError = AxiosError<unknown>>(
 params?: GetApiAdminLinePayPaymentListPaymentsByUserParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAdminLinePayPaymentListPaymentsByUser>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiAdminLinePayPaymentListPaymentsByUserQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const patchApiAdminLinePayPaymentRefund = (
    params?: PatchApiAdminLinePayPaymentRefundParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.patch(
      `/api/admin/LinePayPayment/Refund`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getPatchApiAdminLinePayPaymentRefundMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiAdminLinePayPaymentRefund>>, TError,{params?: PatchApiAdminLinePayPaymentRefundParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof patchApiAdminLinePayPaymentRefund>>, TError,{params?: PatchApiAdminLinePayPaymentRefundParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiAdminLinePayPaymentRefund>>, {params?: PatchApiAdminLinePayPaymentRefundParams}> = (props) => {
          const {params} = props ?? {};

          return  patchApiAdminLinePayPaymentRefund(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchApiAdminLinePayPaymentRefundMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiAdminLinePayPaymentRefund>>>
    
    export type PatchApiAdminLinePayPaymentRefundMutationError = AxiosError<unknown>

    export const usePatchApiAdminLinePayPaymentRefund = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiAdminLinePayPaymentRefund>>, TError,{params?: PatchApiAdminLinePayPaymentRefundParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof patchApiAdminLinePayPaymentRefund>>,
        TError,
        {params?: PatchApiAdminLinePayPaymentRefundParams},
        TContext
      > => {

      const mutationOptions = getPatchApiAdminLinePayPaymentRefundMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const deleteApiAdminLinePayPaymentDelete = (
    params?: DeleteApiAdminLinePayPaymentDeleteParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/api/admin/LinePayPayment/Delete`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getDeleteApiAdminLinePayPaymentDeleteMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiAdminLinePayPaymentDelete>>, TError,{params?: DeleteApiAdminLinePayPaymentDeleteParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiAdminLinePayPaymentDelete>>, TError,{params?: DeleteApiAdminLinePayPaymentDeleteParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiAdminLinePayPaymentDelete>>, {params?: DeleteApiAdminLinePayPaymentDeleteParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteApiAdminLinePayPaymentDelete(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiAdminLinePayPaymentDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiAdminLinePayPaymentDelete>>>
    
    export type DeleteApiAdminLinePayPaymentDeleteMutationError = AxiosError<unknown>

    export const useDeleteApiAdminLinePayPaymentDelete = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiAdminLinePayPaymentDelete>>, TError,{params?: DeleteApiAdminLinePayPaymentDeleteParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiAdminLinePayPaymentDelete>>,
        TError,
        {params?: DeleteApiAdminLinePayPaymentDeleteParams},
        TContext
      > => {

      const mutationOptions = getDeleteApiAdminLinePayPaymentDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getApiPeriodCreditKeyList = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PeriodCreditKey[]>> => {
    
    return axios.get(
      `/api/PeriodCreditKey/List`,options
    );
  }


export const getGetApiPeriodCreditKeyListQueryKey = () => {
    return [`/api/PeriodCreditKey/List`] as const;
    }

    
export const getGetApiPeriodCreditKeyListQueryOptions = <TData = Awaited<ReturnType<typeof getApiPeriodCreditKeyList>>, TError = AxiosError<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiPeriodCreditKeyList>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiPeriodCreditKeyListQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiPeriodCreditKeyList>>> = ({ signal }) => getApiPeriodCreditKeyList({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiPeriodCreditKeyList>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiPeriodCreditKeyListQueryResult = NonNullable<Awaited<ReturnType<typeof getApiPeriodCreditKeyList>>>
export type GetApiPeriodCreditKeyListQueryError = AxiosError<unknown>



export function useGetApiPeriodCreditKeyList<TData = Awaited<ReturnType<typeof getApiPeriodCreditKeyList>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiPeriodCreditKeyList>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiPeriodCreditKeyListQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getApiAdminPeriodCreditKeyList = (
    params?: GetApiAdminPeriodCreditKeyListParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PeriodCreditKey[]>> => {
    
    return axios.get(
      `/api/admin/PeriodCreditKey/List`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetApiAdminPeriodCreditKeyListQueryKey = (params?: GetApiAdminPeriodCreditKeyListParams,) => {
    return [`/api/admin/PeriodCreditKey/List`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiAdminPeriodCreditKeyListQueryOptions = <TData = Awaited<ReturnType<typeof getApiAdminPeriodCreditKeyList>>, TError = AxiosError<unknown>>(params?: GetApiAdminPeriodCreditKeyListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAdminPeriodCreditKeyList>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiAdminPeriodCreditKeyListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiAdminPeriodCreditKeyList>>> = ({ signal }) => getApiAdminPeriodCreditKeyList(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiAdminPeriodCreditKeyList>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiAdminPeriodCreditKeyListQueryResult = NonNullable<Awaited<ReturnType<typeof getApiAdminPeriodCreditKeyList>>>
export type GetApiAdminPeriodCreditKeyListQueryError = AxiosError<unknown>



export function useGetApiAdminPeriodCreditKeyList<TData = Awaited<ReturnType<typeof getApiAdminPeriodCreditKeyList>>, TError = AxiosError<unknown>>(
 params?: GetApiAdminPeriodCreditKeyListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAdminPeriodCreditKeyList>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiAdminPeriodCreditKeyListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getApiAdminPeriodCreditKeyGet = (
    params?: GetApiAdminPeriodCreditKeyGetParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PeriodCreditKey>> => {
    
    return axios.get(
      `/api/admin/PeriodCreditKey/Get`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetApiAdminPeriodCreditKeyGetQueryKey = (params?: GetApiAdminPeriodCreditKeyGetParams,) => {
    return [`/api/admin/PeriodCreditKey/Get`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiAdminPeriodCreditKeyGetQueryOptions = <TData = Awaited<ReturnType<typeof getApiAdminPeriodCreditKeyGet>>, TError = AxiosError<unknown>>(params?: GetApiAdminPeriodCreditKeyGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAdminPeriodCreditKeyGet>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiAdminPeriodCreditKeyGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiAdminPeriodCreditKeyGet>>> = ({ signal }) => getApiAdminPeriodCreditKeyGet(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiAdminPeriodCreditKeyGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiAdminPeriodCreditKeyGetQueryResult = NonNullable<Awaited<ReturnType<typeof getApiAdminPeriodCreditKeyGet>>>
export type GetApiAdminPeriodCreditKeyGetQueryError = AxiosError<unknown>



export function useGetApiAdminPeriodCreditKeyGet<TData = Awaited<ReturnType<typeof getApiAdminPeriodCreditKeyGet>>, TError = AxiosError<unknown>>(
 params?: GetApiAdminPeriodCreditKeyGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAdminPeriodCreditKeyGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiAdminPeriodCreditKeyGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const postApiAdminPeriodCreditKeyAdd = (
    addPeriodCreditKeyViewModel: AddPeriodCreditKeyViewModel,
    params?: PostApiAdminPeriodCreditKeyAddParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `/api/admin/PeriodCreditKey/Add`,
      addPeriodCreditKeyViewModel,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getPostApiAdminPeriodCreditKeyAddMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAdminPeriodCreditKeyAdd>>, TError,{data: AddPeriodCreditKeyViewModel;params?: PostApiAdminPeriodCreditKeyAddParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiAdminPeriodCreditKeyAdd>>, TError,{data: AddPeriodCreditKeyViewModel;params?: PostApiAdminPeriodCreditKeyAddParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiAdminPeriodCreditKeyAdd>>, {data: AddPeriodCreditKeyViewModel;params?: PostApiAdminPeriodCreditKeyAddParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postApiAdminPeriodCreditKeyAdd(data,params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiAdminPeriodCreditKeyAddMutationResult = NonNullable<Awaited<ReturnType<typeof postApiAdminPeriodCreditKeyAdd>>>
    export type PostApiAdminPeriodCreditKeyAddMutationBody = AddPeriodCreditKeyViewModel
    export type PostApiAdminPeriodCreditKeyAddMutationError = AxiosError<unknown>

    export const usePostApiAdminPeriodCreditKeyAdd = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAdminPeriodCreditKeyAdd>>, TError,{data: AddPeriodCreditKeyViewModel;params?: PostApiAdminPeriodCreditKeyAddParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiAdminPeriodCreditKeyAdd>>,
        TError,
        {data: AddPeriodCreditKeyViewModel;params?: PostApiAdminPeriodCreditKeyAddParams},
        TContext
      > => {

      const mutationOptions = getPostApiAdminPeriodCreditKeyAddMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const patchApiAdminPeriodCreditKeyEdit = (
    editPeriodCreditKeyViewModel: EditPeriodCreditKeyViewModel,
    params?: PatchApiAdminPeriodCreditKeyEditParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.patch(
      `/api/admin/PeriodCreditKey/Edit`,
      editPeriodCreditKeyViewModel,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getPatchApiAdminPeriodCreditKeyEditMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiAdminPeriodCreditKeyEdit>>, TError,{data: EditPeriodCreditKeyViewModel;params?: PatchApiAdminPeriodCreditKeyEditParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof patchApiAdminPeriodCreditKeyEdit>>, TError,{data: EditPeriodCreditKeyViewModel;params?: PatchApiAdminPeriodCreditKeyEditParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiAdminPeriodCreditKeyEdit>>, {data: EditPeriodCreditKeyViewModel;params?: PatchApiAdminPeriodCreditKeyEditParams}> = (props) => {
          const {data,params} = props ?? {};

          return  patchApiAdminPeriodCreditKeyEdit(data,params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchApiAdminPeriodCreditKeyEditMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiAdminPeriodCreditKeyEdit>>>
    export type PatchApiAdminPeriodCreditKeyEditMutationBody = EditPeriodCreditKeyViewModel
    export type PatchApiAdminPeriodCreditKeyEditMutationError = AxiosError<unknown>

    export const usePatchApiAdminPeriodCreditKeyEdit = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiAdminPeriodCreditKeyEdit>>, TError,{data: EditPeriodCreditKeyViewModel;params?: PatchApiAdminPeriodCreditKeyEditParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof patchApiAdminPeriodCreditKeyEdit>>,
        TError,
        {data: EditPeriodCreditKeyViewModel;params?: PatchApiAdminPeriodCreditKeyEditParams},
        TContext
      > => {

      const mutationOptions = getPatchApiAdminPeriodCreditKeyEditMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const patchApiAdminPeriodCreditKeyIncreaseBalance = (
    params?: PatchApiAdminPeriodCreditKeyIncreaseBalanceParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.patch(
      `/api/admin/PeriodCreditKey/IncreaseBalance`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getPatchApiAdminPeriodCreditKeyIncreaseBalanceMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiAdminPeriodCreditKeyIncreaseBalance>>, TError,{params?: PatchApiAdminPeriodCreditKeyIncreaseBalanceParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof patchApiAdminPeriodCreditKeyIncreaseBalance>>, TError,{params?: PatchApiAdminPeriodCreditKeyIncreaseBalanceParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiAdminPeriodCreditKeyIncreaseBalance>>, {params?: PatchApiAdminPeriodCreditKeyIncreaseBalanceParams}> = (props) => {
          const {params} = props ?? {};

          return  patchApiAdminPeriodCreditKeyIncreaseBalance(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchApiAdminPeriodCreditKeyIncreaseBalanceMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiAdminPeriodCreditKeyIncreaseBalance>>>
    
    export type PatchApiAdminPeriodCreditKeyIncreaseBalanceMutationError = AxiosError<unknown>

    export const usePatchApiAdminPeriodCreditKeyIncreaseBalance = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiAdminPeriodCreditKeyIncreaseBalance>>, TError,{params?: PatchApiAdminPeriodCreditKeyIncreaseBalanceParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof patchApiAdminPeriodCreditKeyIncreaseBalance>>,
        TError,
        {params?: PatchApiAdminPeriodCreditKeyIncreaseBalanceParams},
        TContext
      > => {

      const mutationOptions = getPatchApiAdminPeriodCreditKeyIncreaseBalanceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const deleteApiAdminPeriodCreditKeyDelete = (
    params?: DeleteApiAdminPeriodCreditKeyDeleteParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/api/admin/PeriodCreditKey/Delete`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getDeleteApiAdminPeriodCreditKeyDeleteMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiAdminPeriodCreditKeyDelete>>, TError,{params?: DeleteApiAdminPeriodCreditKeyDeleteParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiAdminPeriodCreditKeyDelete>>, TError,{params?: DeleteApiAdminPeriodCreditKeyDeleteParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiAdminPeriodCreditKeyDelete>>, {params?: DeleteApiAdminPeriodCreditKeyDeleteParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteApiAdminPeriodCreditKeyDelete(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiAdminPeriodCreditKeyDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiAdminPeriodCreditKeyDelete>>>
    
    export type DeleteApiAdminPeriodCreditKeyDeleteMutationError = AxiosError<unknown>

    export const useDeleteApiAdminPeriodCreditKeyDelete = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiAdminPeriodCreditKeyDelete>>, TError,{params?: DeleteApiAdminPeriodCreditKeyDeleteParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiAdminPeriodCreditKeyDelete>>,
        TError,
        {params?: DeleteApiAdminPeriodCreditKeyDeleteParams},
        TContext
      > => {

      const mutationOptions = getDeleteApiAdminPeriodCreditKeyDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiSearchSearch = (
    workspaceSearchQueryViewModel: WorkspaceSearchQueryViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<MultiSourceSearchResult>> => {
    
    return axios.post(
      `/api/Search/Search`,
      workspaceSearchQueryViewModel,options
    );
  }



export const getPostApiSearchSearchMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiSearchSearch>>, TError,{data: WorkspaceSearchQueryViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiSearchSearch>>, TError,{data: WorkspaceSearchQueryViewModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiSearchSearch>>, {data: WorkspaceSearchQueryViewModel}> = (props) => {
          const {data} = props ?? {};

          return  postApiSearchSearch(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiSearchSearchMutationResult = NonNullable<Awaited<ReturnType<typeof postApiSearchSearch>>>
    export type PostApiSearchSearchMutationBody = WorkspaceSearchQueryViewModel
    export type PostApiSearchSearchMutationError = AxiosError<unknown>

    export const usePostApiSearchSearch = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiSearchSearch>>, TError,{data: WorkspaceSearchQueryViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiSearchSearch>>,
        TError,
        {data: WorkspaceSearchQueryViewModel},
        TContext
      > => {

      const mutationOptions = getPostApiSearchSearchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getApiAdminServiceMetricListSource = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<string[]>> => {
    
    return axios.get(
      `/api/admin/ServiceMetric/ListSource`,options
    );
  }


export const getGetApiAdminServiceMetricListSourceQueryKey = () => {
    return [`/api/admin/ServiceMetric/ListSource`] as const;
    }

    
export const getGetApiAdminServiceMetricListSourceQueryOptions = <TData = Awaited<ReturnType<typeof getApiAdminServiceMetricListSource>>, TError = AxiosError<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAdminServiceMetricListSource>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiAdminServiceMetricListSourceQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiAdminServiceMetricListSource>>> = ({ signal }) => getApiAdminServiceMetricListSource({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiAdminServiceMetricListSource>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiAdminServiceMetricListSourceQueryResult = NonNullable<Awaited<ReturnType<typeof getApiAdminServiceMetricListSource>>>
export type GetApiAdminServiceMetricListSourceQueryError = AxiosError<unknown>



export function useGetApiAdminServiceMetricListSource<TData = Awaited<ReturnType<typeof getApiAdminServiceMetricListSource>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAdminServiceMetricListSource>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiAdminServiceMetricListSourceQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const postApiAdminServiceMetricSearch = (
    params?: PostApiAdminServiceMetricSearchParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ServiceMetric[]>> => {
    
    return axios.post(
      `/api/admin/ServiceMetric/Search`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getPostApiAdminServiceMetricSearchMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAdminServiceMetricSearch>>, TError,{params?: PostApiAdminServiceMetricSearchParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiAdminServiceMetricSearch>>, TError,{params?: PostApiAdminServiceMetricSearchParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiAdminServiceMetricSearch>>, {params?: PostApiAdminServiceMetricSearchParams}> = (props) => {
          const {params} = props ?? {};

          return  postApiAdminServiceMetricSearch(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiAdminServiceMetricSearchMutationResult = NonNullable<Awaited<ReturnType<typeof postApiAdminServiceMetricSearch>>>
    
    export type PostApiAdminServiceMetricSearchMutationError = AxiosError<unknown>

    export const usePostApiAdminServiceMetricSearch = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAdminServiceMetricSearch>>, TError,{params?: PostApiAdminServiceMetricSearchParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiAdminServiceMetricSearch>>,
        TError,
        {params?: PostApiAdminServiceMetricSearchParams},
        TContext
      > => {

      const mutationOptions = getPostApiAdminServiceMetricSearchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getApiSocialAuthGoogle = (
    params?: GetApiSocialAuthGoogleParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.get(
      `/api/SocialAuth/google`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetApiSocialAuthGoogleQueryKey = (params?: GetApiSocialAuthGoogleParams,) => {
    return [`/api/SocialAuth/google`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiSocialAuthGoogleQueryOptions = <TData = Awaited<ReturnType<typeof getApiSocialAuthGoogle>>, TError = AxiosError<unknown>>(params?: GetApiSocialAuthGoogleParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiSocialAuthGoogle>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiSocialAuthGoogleQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiSocialAuthGoogle>>> = ({ signal }) => getApiSocialAuthGoogle(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiSocialAuthGoogle>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiSocialAuthGoogleQueryResult = NonNullable<Awaited<ReturnType<typeof getApiSocialAuthGoogle>>>
export type GetApiSocialAuthGoogleQueryError = AxiosError<unknown>



export function useGetApiSocialAuthGoogle<TData = Awaited<ReturnType<typeof getApiSocialAuthGoogle>>, TError = AxiosError<unknown>>(
 params?: GetApiSocialAuthGoogleParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiSocialAuthGoogle>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiSocialAuthGoogleQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getCallbackGoogle = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.get(
      `/callback/google`,options
    );
  }


export const getGetCallbackGoogleQueryKey = () => {
    return [`/callback/google`] as const;
    }

    
export const getGetCallbackGoogleQueryOptions = <TData = Awaited<ReturnType<typeof getCallbackGoogle>>, TError = AxiosError<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCallbackGoogle>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCallbackGoogleQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCallbackGoogle>>> = ({ signal }) => getCallbackGoogle({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCallbackGoogle>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCallbackGoogleQueryResult = NonNullable<Awaited<ReturnType<typeof getCallbackGoogle>>>
export type GetCallbackGoogleQueryError = AxiosError<unknown>



export function useGetCallbackGoogle<TData = Awaited<ReturnType<typeof getCallbackGoogle>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCallbackGoogle>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetCallbackGoogleQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const postApiSttUsageLogSearch = (
    params?: PostApiSttUsageLogSearchParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SearchSttUsageLogGroup[]>> => {
    
    return axios.post(
      `/api/SttUsageLog/Search`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getPostApiSttUsageLogSearchMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiSttUsageLogSearch>>, TError,{params?: PostApiSttUsageLogSearchParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiSttUsageLogSearch>>, TError,{params?: PostApiSttUsageLogSearchParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiSttUsageLogSearch>>, {params?: PostApiSttUsageLogSearchParams}> = (props) => {
          const {params} = props ?? {};

          return  postApiSttUsageLogSearch(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiSttUsageLogSearchMutationResult = NonNullable<Awaited<ReturnType<typeof postApiSttUsageLogSearch>>>
    
    export type PostApiSttUsageLogSearchMutationError = AxiosError<unknown>

    export const usePostApiSttUsageLogSearch = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiSttUsageLogSearch>>, TError,{params?: PostApiSttUsageLogSearchParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiSttUsageLogSearch>>,
        TError,
        {params?: PostApiSttUsageLogSearchParams},
        TContext
      > => {

      const mutationOptions = getPostApiSttUsageLogSearchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiAdminSttUsageLogSearch = (
    params?: PostApiAdminSttUsageLogSearchParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SearchSttUsageLogGroup[]>> => {
    
    return axios.post(
      `/api/admin/SttUsageLog/Search`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getPostApiAdminSttUsageLogSearchMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAdminSttUsageLogSearch>>, TError,{params?: PostApiAdminSttUsageLogSearchParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiAdminSttUsageLogSearch>>, TError,{params?: PostApiAdminSttUsageLogSearchParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiAdminSttUsageLogSearch>>, {params?: PostApiAdminSttUsageLogSearchParams}> = (props) => {
          const {params} = props ?? {};

          return  postApiAdminSttUsageLogSearch(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiAdminSttUsageLogSearchMutationResult = NonNullable<Awaited<ReturnType<typeof postApiAdminSttUsageLogSearch>>>
    
    export type PostApiAdminSttUsageLogSearchMutationError = AxiosError<unknown>

    export const usePostApiAdminSttUsageLogSearch = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAdminSttUsageLogSearch>>, TError,{params?: PostApiAdminSttUsageLogSearchParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiAdminSttUsageLogSearch>>,
        TError,
        {params?: PostApiAdminSttUsageLogSearchParams},
        TContext
      > => {

      const mutationOptions = getPostApiAdminSttUsageLogSearchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getLicense = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<LicenseStatus>> => {
    
    return axios.get(
      `/license`,options
    );
  }


export const getGetLicenseQueryKey = () => {
    return [`/license`] as const;
    }

    
export const getGetLicenseQueryOptions = <TData = Awaited<ReturnType<typeof getLicense>>, TError = AxiosError<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getLicense>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetLicenseQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getLicense>>> = ({ signal }) => getLicense({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getLicense>>, TError, TData> & { queryKey: QueryKey }
}

export type GetLicenseQueryResult = NonNullable<Awaited<ReturnType<typeof getLicense>>>
export type GetLicenseQueryError = AxiosError<unknown>



export function useGetLicense<TData = Awaited<ReturnType<typeof getLicense>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getLicense>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetLicenseQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getStatus = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SystemStatus>> => {
    
    return axios.get(
      `/status`,options
    );
  }


export const getGetStatusQueryKey = () => {
    return [`/status`] as const;
    }

    
export const getGetStatusQueryOptions = <TData = Awaited<ReturnType<typeof getStatus>>, TError = AxiosError<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getStatus>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetStatusQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getStatus>>> = ({ signal }) => getStatus({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getStatus>>, TError, TData> & { queryKey: QueryKey }
}

export type GetStatusQueryResult = NonNullable<Awaited<ReturnType<typeof getStatus>>>
export type GetStatusQueryError = AxiosError<unknown>



export function useGetStatus<TData = Awaited<ReturnType<typeof getStatus>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getStatus>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetStatusQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getInfo = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SystemInfo>> => {
    
    return axios.get(
      `/info`,options
    );
  }


export const getGetInfoQueryKey = () => {
    return [`/info`] as const;
    }

    
export const getGetInfoQueryOptions = <TData = Awaited<ReturnType<typeof getInfo>>, TError = AxiosError<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getInfo>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetInfoQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getInfo>>> = ({ signal }) => getInfo({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getInfo>>, TError, TData> & { queryKey: QueryKey }
}

export type GetInfoQueryResult = NonNullable<Awaited<ReturnType<typeof getInfo>>>
export type GetInfoQueryError = AxiosError<unknown>



export function useGetInfo<TData = Awaited<ReturnType<typeof getInfo>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getInfo>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetInfoQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getApiAdminSystemTotalMediaSize = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<number>> => {
    
    return axios.get(
      `/api/admin/System/TotalMediaSize`,options
    );
  }


export const getGetApiAdminSystemTotalMediaSizeQueryKey = () => {
    return [`/api/admin/System/TotalMediaSize`] as const;
    }

    
export const getGetApiAdminSystemTotalMediaSizeQueryOptions = <TData = Awaited<ReturnType<typeof getApiAdminSystemTotalMediaSize>>, TError = AxiosError<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAdminSystemTotalMediaSize>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiAdminSystemTotalMediaSizeQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiAdminSystemTotalMediaSize>>> = ({ signal }) => getApiAdminSystemTotalMediaSize({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiAdminSystemTotalMediaSize>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiAdminSystemTotalMediaSizeQueryResult = NonNullable<Awaited<ReturnType<typeof getApiAdminSystemTotalMediaSize>>>
export type GetApiAdminSystemTotalMediaSizeQueryError = AxiosError<unknown>



export function useGetApiAdminSystemTotalMediaSize<TData = Awaited<ReturnType<typeof getApiAdminSystemTotalMediaSize>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAdminSystemTotalMediaSize>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiAdminSystemTotalMediaSizeQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getApiAdminSystemSettingGet = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SystemSetting>> => {
    
    return axios.get(
      `/api/admin/SystemSetting/Get`,options
    );
  }


export const getGetApiAdminSystemSettingGetQueryKey = () => {
    return [`/api/admin/SystemSetting/Get`] as const;
    }

    
export const getGetApiAdminSystemSettingGetQueryOptions = <TData = Awaited<ReturnType<typeof getApiAdminSystemSettingGet>>, TError = AxiosError<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAdminSystemSettingGet>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiAdminSystemSettingGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiAdminSystemSettingGet>>> = ({ signal }) => getApiAdminSystemSettingGet({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiAdminSystemSettingGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiAdminSystemSettingGetQueryResult = NonNullable<Awaited<ReturnType<typeof getApiAdminSystemSettingGet>>>
export type GetApiAdminSystemSettingGetQueryError = AxiosError<unknown>



export function useGetApiAdminSystemSettingGet<TData = Awaited<ReturnType<typeof getApiAdminSystemSettingGet>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAdminSystemSettingGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiAdminSystemSettingGetQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const putApiAdminSystemSettingUpdate = (
    systemSetting: SystemSetting, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<boolean>> => {
    
    return axios.put(
      `/api/admin/SystemSetting/Update`,
      systemSetting,options
    );
  }



export const getPutApiAdminSystemSettingUpdateMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiAdminSystemSettingUpdate>>, TError,{data: SystemSetting}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof putApiAdminSystemSettingUpdate>>, TError,{data: SystemSetting}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiAdminSystemSettingUpdate>>, {data: SystemSetting}> = (props) => {
          const {data} = props ?? {};

          return  putApiAdminSystemSettingUpdate(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiAdminSystemSettingUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof putApiAdminSystemSettingUpdate>>>
    export type PutApiAdminSystemSettingUpdateMutationBody = SystemSetting
    export type PutApiAdminSystemSettingUpdateMutationError = AxiosError<unknown>

    export const usePutApiAdminSystemSettingUpdate = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiAdminSystemSettingUpdate>>, TError,{data: SystemSetting}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof putApiAdminSystemSettingUpdate>>,
        TError,
        {data: SystemSetting},
        TContext
      > => {

      const mutationOptions = getPutApiAdminSystemSettingUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiTextTranscriptionCorrection = (
    postApiTextTranscriptionCorrectionBody: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<string>> => {
    
    return axios.post(
      `/api/Text/TranscriptionCorrection`,
      postApiTextTranscriptionCorrectionBody,options
    );
  }



export const getPostApiTextTranscriptionCorrectionMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiTextTranscriptionCorrection>>, TError,{data: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiTextTranscriptionCorrection>>, TError,{data: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiTextTranscriptionCorrection>>, {data: string}> = (props) => {
          const {data} = props ?? {};

          return  postApiTextTranscriptionCorrection(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiTextTranscriptionCorrectionMutationResult = NonNullable<Awaited<ReturnType<typeof postApiTextTranscriptionCorrection>>>
    export type PostApiTextTranscriptionCorrectionMutationBody = string
    export type PostApiTextTranscriptionCorrectionMutationError = AxiosError<unknown>

    export const usePostApiTextTranscriptionCorrection = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiTextTranscriptionCorrection>>, TError,{data: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiTextTranscriptionCorrection>>,
        TError,
        {data: string},
        TContext
      > => {

      const mutationOptions = getPostApiTextTranscriptionCorrectionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiTextSummarization = (
    postApiTextSummarizationBody: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<string>> => {
    
    return axios.post(
      `/api/Text/Summarization`,
      postApiTextSummarizationBody,options
    );
  }



export const getPostApiTextSummarizationMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiTextSummarization>>, TError,{data: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiTextSummarization>>, TError,{data: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiTextSummarization>>, {data: string}> = (props) => {
          const {data} = props ?? {};

          return  postApiTextSummarization(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiTextSummarizationMutationResult = NonNullable<Awaited<ReturnType<typeof postApiTextSummarization>>>
    export type PostApiTextSummarizationMutationBody = string
    export type PostApiTextSummarizationMutationError = AxiosError<unknown>

    export const usePostApiTextSummarization = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiTextSummarization>>, TError,{data: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiTextSummarization>>,
        TError,
        {data: string},
        TContext
      > => {

      const mutationOptions = getPostApiTextSummarizationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiTextTranslation = (
    postApiTextTranslationBody: string,
    params?: PostApiTextTranslationParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<string>> => {
    
    return axios.post(
      `/api/Text/Translation`,
      postApiTextTranslationBody,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getPostApiTextTranslationMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiTextTranslation>>, TError,{data: string;params?: PostApiTextTranslationParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiTextTranslation>>, TError,{data: string;params?: PostApiTextTranslationParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiTextTranslation>>, {data: string;params?: PostApiTextTranslationParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postApiTextTranslation(data,params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiTextTranslationMutationResult = NonNullable<Awaited<ReturnType<typeof postApiTextTranslation>>>
    export type PostApiTextTranslationMutationBody = string
    export type PostApiTextTranslationMutationError = AxiosError<unknown>

    export const usePostApiTextTranslation = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiTextTranslation>>, TError,{data: string;params?: PostApiTextTranslationParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiTextTranslation>>,
        TError,
        {data: string;params?: PostApiTextTranslationParams},
        TContext
      > => {

      const mutationOptions = getPostApiTextTranslationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiTextExtractKeyword = (
    postApiTextExtractKeywordBody: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<string[]>> => {
    
    return axios.post(
      `/api/Text/ExtractKeyword`,
      postApiTextExtractKeywordBody,options
    );
  }



export const getPostApiTextExtractKeywordMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiTextExtractKeyword>>, TError,{data: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiTextExtractKeyword>>, TError,{data: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiTextExtractKeyword>>, {data: string}> = (props) => {
          const {data} = props ?? {};

          return  postApiTextExtractKeyword(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiTextExtractKeywordMutationResult = NonNullable<Awaited<ReturnType<typeof postApiTextExtractKeyword>>>
    export type PostApiTextExtractKeywordMutationBody = string
    export type PostApiTextExtractKeywordMutationError = AxiosError<unknown>

    export const usePostApiTextExtractKeyword = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiTextExtractKeyword>>, TError,{data: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiTextExtractKeyword>>,
        TError,
        {data: string},
        TContext
      > => {

      const mutationOptions = getPostApiTextExtractKeywordMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const putApiUserChangeDisplayName = (
    params?: PutApiUserChangeDisplayNameParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<boolean>> => {
    
    return axios.put(
      `/api/User/ChangeDisplayName`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getPutApiUserChangeDisplayNameMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiUserChangeDisplayName>>, TError,{params?: PutApiUserChangeDisplayNameParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof putApiUserChangeDisplayName>>, TError,{params?: PutApiUserChangeDisplayNameParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiUserChangeDisplayName>>, {params?: PutApiUserChangeDisplayNameParams}> = (props) => {
          const {params} = props ?? {};

          return  putApiUserChangeDisplayName(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiUserChangeDisplayNameMutationResult = NonNullable<Awaited<ReturnType<typeof putApiUserChangeDisplayName>>>
    
    export type PutApiUserChangeDisplayNameMutationError = AxiosError<unknown>

    export const usePutApiUserChangeDisplayName = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiUserChangeDisplayName>>, TError,{params?: PutApiUserChangeDisplayNameParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof putApiUserChangeDisplayName>>,
        TError,
        {params?: PutApiUserChangeDisplayNameParams},
        TContext
      > => {

      const mutationOptions = getPutApiUserChangeDisplayNameMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiUserChangePassword = (
    changePasswordModel: ChangePasswordModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<boolean>> => {
    
    return axios.post(
      `/api/User/ChangePassword`,
      changePasswordModel,options
    );
  }



export const getPostApiUserChangePasswordMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiUserChangePassword>>, TError,{data: ChangePasswordModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiUserChangePassword>>, TError,{data: ChangePasswordModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiUserChangePassword>>, {data: ChangePasswordModel}> = (props) => {
          const {data} = props ?? {};

          return  postApiUserChangePassword(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiUserChangePasswordMutationResult = NonNullable<Awaited<ReturnType<typeof postApiUserChangePassword>>>
    export type PostApiUserChangePasswordMutationBody = ChangePasswordModel
    export type PostApiUserChangePasswordMutationError = AxiosError<unknown>

    export const usePostApiUserChangePassword = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiUserChangePassword>>, TError,{data: ChangePasswordModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiUserChangePassword>>,
        TError,
        {data: ChangePasswordModel},
        TContext
      > => {

      const mutationOptions = getPostApiUserChangePasswordMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getApiAdminUserManagerListUsers = (
    params?: GetApiAdminUserManagerListUsersParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<UserPagingList>> => {
    
    return axios.get(
      `/api/admin/UserManager/ListUsers`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetApiAdminUserManagerListUsersQueryKey = (params?: GetApiAdminUserManagerListUsersParams,) => {
    return [`/api/admin/UserManager/ListUsers`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiAdminUserManagerListUsersQueryOptions = <TData = Awaited<ReturnType<typeof getApiAdminUserManagerListUsers>>, TError = AxiosError<unknown>>(params?: GetApiAdminUserManagerListUsersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAdminUserManagerListUsers>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiAdminUserManagerListUsersQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiAdminUserManagerListUsers>>> = ({ signal }) => getApiAdminUserManagerListUsers(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiAdminUserManagerListUsers>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiAdminUserManagerListUsersQueryResult = NonNullable<Awaited<ReturnType<typeof getApiAdminUserManagerListUsers>>>
export type GetApiAdminUserManagerListUsersQueryError = AxiosError<unknown>



export function useGetApiAdminUserManagerListUsers<TData = Awaited<ReturnType<typeof getApiAdminUserManagerListUsers>>, TError = AxiosError<unknown>>(
 params?: GetApiAdminUserManagerListUsersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAdminUserManagerListUsers>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiAdminUserManagerListUsersQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const postApiAdminUserManagerCreateUser = (
    userPasswordViewModel: UserPasswordViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `/api/admin/UserManager/CreateUser`,
      userPasswordViewModel,options
    );
  }



export const getPostApiAdminUserManagerCreateUserMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAdminUserManagerCreateUser>>, TError,{data: UserPasswordViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiAdminUserManagerCreateUser>>, TError,{data: UserPasswordViewModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiAdminUserManagerCreateUser>>, {data: UserPasswordViewModel}> = (props) => {
          const {data} = props ?? {};

          return  postApiAdminUserManagerCreateUser(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiAdminUserManagerCreateUserMutationResult = NonNullable<Awaited<ReturnType<typeof postApiAdminUserManagerCreateUser>>>
    export type PostApiAdminUserManagerCreateUserMutationBody = UserPasswordViewModel
    export type PostApiAdminUserManagerCreateUserMutationError = AxiosError<unknown>

    export const usePostApiAdminUserManagerCreateUser = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAdminUserManagerCreateUser>>, TError,{data: UserPasswordViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiAdminUserManagerCreateUser>>,
        TError,
        {data: UserPasswordViewModel},
        TContext
      > => {

      const mutationOptions = getPostApiAdminUserManagerCreateUserMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const patchApiAdminUserManagerChangeUserPassword = (
    userIdPasswordViewModel: UserIdPasswordViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.patch(
      `/api/admin/UserManager/ChangeUserPassword`,
      userIdPasswordViewModel,options
    );
  }



export const getPatchApiAdminUserManagerChangeUserPasswordMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiAdminUserManagerChangeUserPassword>>, TError,{data: UserIdPasswordViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof patchApiAdminUserManagerChangeUserPassword>>, TError,{data: UserIdPasswordViewModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiAdminUserManagerChangeUserPassword>>, {data: UserIdPasswordViewModel}> = (props) => {
          const {data} = props ?? {};

          return  patchApiAdminUserManagerChangeUserPassword(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchApiAdminUserManagerChangeUserPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiAdminUserManagerChangeUserPassword>>>
    export type PatchApiAdminUserManagerChangeUserPasswordMutationBody = UserIdPasswordViewModel
    export type PatchApiAdminUserManagerChangeUserPasswordMutationError = AxiosError<unknown>

    export const usePatchApiAdminUserManagerChangeUserPassword = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiAdminUserManagerChangeUserPassword>>, TError,{data: UserIdPasswordViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof patchApiAdminUserManagerChangeUserPassword>>,
        TError,
        {data: UserIdPasswordViewModel},
        TContext
      > => {

      const mutationOptions = getPatchApiAdminUserManagerChangeUserPasswordMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const deleteApiAdminUserManagerDeleteUser = (
    params?: DeleteApiAdminUserManagerDeleteUserParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/api/admin/UserManager/DeleteUser`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getDeleteApiAdminUserManagerDeleteUserMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiAdminUserManagerDeleteUser>>, TError,{params?: DeleteApiAdminUserManagerDeleteUserParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiAdminUserManagerDeleteUser>>, TError,{params?: DeleteApiAdminUserManagerDeleteUserParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiAdminUserManagerDeleteUser>>, {params?: DeleteApiAdminUserManagerDeleteUserParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteApiAdminUserManagerDeleteUser(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiAdminUserManagerDeleteUserMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiAdminUserManagerDeleteUser>>>
    
    export type DeleteApiAdminUserManagerDeleteUserMutationError = AxiosError<unknown>

    export const useDeleteApiAdminUserManagerDeleteUser = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiAdminUserManagerDeleteUser>>, TError,{params?: DeleteApiAdminUserManagerDeleteUserParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiAdminUserManagerDeleteUser>>,
        TError,
        {params?: DeleteApiAdminUserManagerDeleteUserParams},
        TContext
      > => {

      const mutationOptions = getDeleteApiAdminUserManagerDeleteUserMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getApiWorkspaceList = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Workspace[]>> => {
    
    return axios.get(
      `/api/Workspace/List`,options
    );
  }


export const getGetApiWorkspaceListQueryKey = () => {
    return [`/api/Workspace/List`] as const;
    }

    
export const getGetApiWorkspaceListQueryOptions = <TData = Awaited<ReturnType<typeof getApiWorkspaceList>>, TError = AxiosError<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiWorkspaceList>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiWorkspaceListQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiWorkspaceList>>> = ({ signal }) => getApiWorkspaceList({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiWorkspaceList>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiWorkspaceListQueryResult = NonNullable<Awaited<ReturnType<typeof getApiWorkspaceList>>>
export type GetApiWorkspaceListQueryError = AxiosError<unknown>



export function useGetApiWorkspaceList<TData = Awaited<ReturnType<typeof getApiWorkspaceList>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiWorkspaceList>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiWorkspaceListQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getApiWorkspaceMediaSize = (
    params?: GetApiWorkspaceMediaSizeParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<number>> => {
    
    return axios.get(
      `/api/Workspace/MediaSize`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetApiWorkspaceMediaSizeQueryKey = (params?: GetApiWorkspaceMediaSizeParams,) => {
    return [`/api/Workspace/MediaSize`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiWorkspaceMediaSizeQueryOptions = <TData = Awaited<ReturnType<typeof getApiWorkspaceMediaSize>>, TError = AxiosError<unknown>>(params?: GetApiWorkspaceMediaSizeParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiWorkspaceMediaSize>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiWorkspaceMediaSizeQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiWorkspaceMediaSize>>> = ({ signal }) => getApiWorkspaceMediaSize(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiWorkspaceMediaSize>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiWorkspaceMediaSizeQueryResult = NonNullable<Awaited<ReturnType<typeof getApiWorkspaceMediaSize>>>
export type GetApiWorkspaceMediaSizeQueryError = AxiosError<unknown>



export function useGetApiWorkspaceMediaSize<TData = Awaited<ReturnType<typeof getApiWorkspaceMediaSize>>, TError = AxiosError<unknown>>(
 params?: GetApiWorkspaceMediaSizeParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiWorkspaceMediaSize>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiWorkspaceMediaSizeQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getApiWorkspaceGet = (
    params?: GetApiWorkspaceGetParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Workspace>> => {
    
    return axios.get(
      `/api/Workspace/Get`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetApiWorkspaceGetQueryKey = (params?: GetApiWorkspaceGetParams,) => {
    return [`/api/Workspace/Get`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiWorkspaceGetQueryOptions = <TData = Awaited<ReturnType<typeof getApiWorkspaceGet>>, TError = AxiosError<unknown>>(params?: GetApiWorkspaceGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiWorkspaceGet>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiWorkspaceGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiWorkspaceGet>>> = ({ signal }) => getApiWorkspaceGet(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiWorkspaceGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiWorkspaceGetQueryResult = NonNullable<Awaited<ReturnType<typeof getApiWorkspaceGet>>>
export type GetApiWorkspaceGetQueryError = AxiosError<unknown>



export function useGetApiWorkspaceGet<TData = Awaited<ReturnType<typeof getApiWorkspaceGet>>, TError = AxiosError<unknown>>(
 params?: GetApiWorkspaceGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiWorkspaceGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiWorkspaceGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const postApiWorkspaceCreate = (
    params?: PostApiWorkspaceCreateParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<string>> => {
    
    return axios.post(
      `/api/Workspace/Create`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getPostApiWorkspaceCreateMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiWorkspaceCreate>>, TError,{params?: PostApiWorkspaceCreateParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiWorkspaceCreate>>, TError,{params?: PostApiWorkspaceCreateParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiWorkspaceCreate>>, {params?: PostApiWorkspaceCreateParams}> = (props) => {
          const {params} = props ?? {};

          return  postApiWorkspaceCreate(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiWorkspaceCreateMutationResult = NonNullable<Awaited<ReturnType<typeof postApiWorkspaceCreate>>>
    
    export type PostApiWorkspaceCreateMutationError = AxiosError<unknown>

    export const usePostApiWorkspaceCreate = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiWorkspaceCreate>>, TError,{params?: PostApiWorkspaceCreateParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiWorkspaceCreate>>,
        TError,
        {params?: PostApiWorkspaceCreateParams},
        TContext
      > => {

      const mutationOptions = getPostApiWorkspaceCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const putApiWorkspaceUpdatePublicPermission = (
    params?: PutApiWorkspaceUpdatePublicPermissionParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.put(
      `/api/Workspace/UpdatePublicPermission`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getPutApiWorkspaceUpdatePublicPermissionMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiWorkspaceUpdatePublicPermission>>, TError,{params?: PutApiWorkspaceUpdatePublicPermissionParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof putApiWorkspaceUpdatePublicPermission>>, TError,{params?: PutApiWorkspaceUpdatePublicPermissionParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiWorkspaceUpdatePublicPermission>>, {params?: PutApiWorkspaceUpdatePublicPermissionParams}> = (props) => {
          const {params} = props ?? {};

          return  putApiWorkspaceUpdatePublicPermission(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiWorkspaceUpdatePublicPermissionMutationResult = NonNullable<Awaited<ReturnType<typeof putApiWorkspaceUpdatePublicPermission>>>
    
    export type PutApiWorkspaceUpdatePublicPermissionMutationError = AxiosError<unknown>

    export const usePutApiWorkspaceUpdatePublicPermission = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiWorkspaceUpdatePublicPermission>>, TError,{params?: PutApiWorkspaceUpdatePublicPermissionParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof putApiWorkspaceUpdatePublicPermission>>,
        TError,
        {params?: PutApiWorkspaceUpdatePublicPermissionParams},
        TContext
      > => {

      const mutationOptions = getPutApiWorkspaceUpdatePublicPermissionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const putApiWorkspaceRename = (
    params?: PutApiWorkspaceRenameParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.put(
      `/api/Workspace/Rename`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getPutApiWorkspaceRenameMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiWorkspaceRename>>, TError,{params?: PutApiWorkspaceRenameParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof putApiWorkspaceRename>>, TError,{params?: PutApiWorkspaceRenameParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiWorkspaceRename>>, {params?: PutApiWorkspaceRenameParams}> = (props) => {
          const {params} = props ?? {};

          return  putApiWorkspaceRename(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiWorkspaceRenameMutationResult = NonNullable<Awaited<ReturnType<typeof putApiWorkspaceRename>>>
    
    export type PutApiWorkspaceRenameMutationError = AxiosError<unknown>

    export const usePutApiWorkspaceRename = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiWorkspaceRename>>, TError,{params?: PutApiWorkspaceRenameParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof putApiWorkspaceRename>>,
        TError,
        {params?: PutApiWorkspaceRenameParams},
        TContext
      > => {

      const mutationOptions = getPutApiWorkspaceRenameMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const deleteApiWorkspaceDelete = (
    params?: DeleteApiWorkspaceDeleteParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/api/Workspace/Delete`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getDeleteApiWorkspaceDeleteMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiWorkspaceDelete>>, TError,{params?: DeleteApiWorkspaceDeleteParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiWorkspaceDelete>>, TError,{params?: DeleteApiWorkspaceDeleteParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiWorkspaceDelete>>, {params?: DeleteApiWorkspaceDeleteParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteApiWorkspaceDelete(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiWorkspaceDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiWorkspaceDelete>>>
    
    export type DeleteApiWorkspaceDeleteMutationError = AxiosError<unknown>

    export const useDeleteApiWorkspaceDelete = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiWorkspaceDelete>>, TError,{params?: DeleteApiWorkspaceDeleteParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiWorkspaceDelete>>,
        TError,
        {params?: DeleteApiWorkspaceDeleteParams},
        TContext
      > => {

      const mutationOptions = getDeleteApiWorkspaceDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getApiWorkspaceUserGetRole = (
    params?: GetApiWorkspaceUserGetRoleParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<WorkspaceUserRole>> => {
    
    return axios.get(
      `/api/WorkspaceUser/GetRole`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetApiWorkspaceUserGetRoleQueryKey = (params?: GetApiWorkspaceUserGetRoleParams,) => {
    return [`/api/WorkspaceUser/GetRole`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiWorkspaceUserGetRoleQueryOptions = <TData = Awaited<ReturnType<typeof getApiWorkspaceUserGetRole>>, TError = AxiosError<unknown>>(params?: GetApiWorkspaceUserGetRoleParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiWorkspaceUserGetRole>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiWorkspaceUserGetRoleQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiWorkspaceUserGetRole>>> = ({ signal }) => getApiWorkspaceUserGetRole(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiWorkspaceUserGetRole>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiWorkspaceUserGetRoleQueryResult = NonNullable<Awaited<ReturnType<typeof getApiWorkspaceUserGetRole>>>
export type GetApiWorkspaceUserGetRoleQueryError = AxiosError<unknown>



export function useGetApiWorkspaceUserGetRole<TData = Awaited<ReturnType<typeof getApiWorkspaceUserGetRole>>, TError = AxiosError<unknown>>(
 params?: GetApiWorkspaceUserGetRoleParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiWorkspaceUserGetRole>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiWorkspaceUserGetRoleQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getApiWorkspaceUserListUsers = (
    params?: GetApiWorkspaceUserListUsersParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<WorkspaceUser[]>> => {
    
    return axios.get(
      `/api/WorkspaceUser/ListUsers`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetApiWorkspaceUserListUsersQueryKey = (params?: GetApiWorkspaceUserListUsersParams,) => {
    return [`/api/WorkspaceUser/ListUsers`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiWorkspaceUserListUsersQueryOptions = <TData = Awaited<ReturnType<typeof getApiWorkspaceUserListUsers>>, TError = AxiosError<unknown>>(params?: GetApiWorkspaceUserListUsersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiWorkspaceUserListUsers>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiWorkspaceUserListUsersQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiWorkspaceUserListUsers>>> = ({ signal }) => getApiWorkspaceUserListUsers(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiWorkspaceUserListUsers>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiWorkspaceUserListUsersQueryResult = NonNullable<Awaited<ReturnType<typeof getApiWorkspaceUserListUsers>>>
export type GetApiWorkspaceUserListUsersQueryError = AxiosError<unknown>



export function useGetApiWorkspaceUserListUsers<TData = Awaited<ReturnType<typeof getApiWorkspaceUserListUsers>>, TError = AxiosError<unknown>>(
 params?: GetApiWorkspaceUserListUsersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiWorkspaceUserListUsers>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiWorkspaceUserListUsersQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const putApiWorkspaceUserSetUserRole = (
    params?: PutApiWorkspaceUserSetUserRoleParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<WorkspaceUser>> => {
    
    return axios.put(
      `/api/WorkspaceUser/SetUserRole`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getPutApiWorkspaceUserSetUserRoleMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiWorkspaceUserSetUserRole>>, TError,{params?: PutApiWorkspaceUserSetUserRoleParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof putApiWorkspaceUserSetUserRole>>, TError,{params?: PutApiWorkspaceUserSetUserRoleParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiWorkspaceUserSetUserRole>>, {params?: PutApiWorkspaceUserSetUserRoleParams}> = (props) => {
          const {params} = props ?? {};

          return  putApiWorkspaceUserSetUserRole(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiWorkspaceUserSetUserRoleMutationResult = NonNullable<Awaited<ReturnType<typeof putApiWorkspaceUserSetUserRole>>>
    
    export type PutApiWorkspaceUserSetUserRoleMutationError = AxiosError<unknown>

    export const usePutApiWorkspaceUserSetUserRole = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiWorkspaceUserSetUserRole>>, TError,{params?: PutApiWorkspaceUserSetUserRoleParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof putApiWorkspaceUserSetUserRole>>,
        TError,
        {params?: PutApiWorkspaceUserSetUserRoleParams},
        TContext
      > => {

      const mutationOptions = getPutApiWorkspaceUserSetUserRoleMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const putApiWorkspaceUserSetUserOwnerRole = (
    params?: PutApiWorkspaceUserSetUserOwnerRoleParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<boolean>> => {
    
    return axios.put(
      `/api/WorkspaceUser/SetUserOwnerRole`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getPutApiWorkspaceUserSetUserOwnerRoleMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiWorkspaceUserSetUserOwnerRole>>, TError,{params?: PutApiWorkspaceUserSetUserOwnerRoleParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof putApiWorkspaceUserSetUserOwnerRole>>, TError,{params?: PutApiWorkspaceUserSetUserOwnerRoleParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiWorkspaceUserSetUserOwnerRole>>, {params?: PutApiWorkspaceUserSetUserOwnerRoleParams}> = (props) => {
          const {params} = props ?? {};

          return  putApiWorkspaceUserSetUserOwnerRole(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiWorkspaceUserSetUserOwnerRoleMutationResult = NonNullable<Awaited<ReturnType<typeof putApiWorkspaceUserSetUserOwnerRole>>>
    
    export type PutApiWorkspaceUserSetUserOwnerRoleMutationError = AxiosError<unknown>

    export const usePutApiWorkspaceUserSetUserOwnerRole = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiWorkspaceUserSetUserOwnerRole>>, TError,{params?: PutApiWorkspaceUserSetUserOwnerRoleParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof putApiWorkspaceUserSetUserOwnerRole>>,
        TError,
        {params?: PutApiWorkspaceUserSetUserOwnerRoleParams},
        TContext
      > => {

      const mutationOptions = getPutApiWorkspaceUserSetUserOwnerRoleMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const deleteApiWorkspaceUserDeleteUser = (
    params?: DeleteApiWorkspaceUserDeleteUserParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<WorkspaceUser[]>> => {
    
    return axios.delete(
      `/api/WorkspaceUser/DeleteUser`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getDeleteApiWorkspaceUserDeleteUserMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiWorkspaceUserDeleteUser>>, TError,{params?: DeleteApiWorkspaceUserDeleteUserParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiWorkspaceUserDeleteUser>>, TError,{params?: DeleteApiWorkspaceUserDeleteUserParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiWorkspaceUserDeleteUser>>, {params?: DeleteApiWorkspaceUserDeleteUserParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteApiWorkspaceUserDeleteUser(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiWorkspaceUserDeleteUserMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiWorkspaceUserDeleteUser>>>
    
    export type DeleteApiWorkspaceUserDeleteUserMutationError = AxiosError<unknown>

    export const useDeleteApiWorkspaceUserDeleteUser = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiWorkspaceUserDeleteUser>>, TError,{params?: DeleteApiWorkspaceUserDeleteUserParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiWorkspaceUserDeleteUser>>,
        TError,
        {params?: DeleteApiWorkspaceUserDeleteUserParams},
        TContext
      > => {

      const mutationOptions = getDeleteApiWorkspaceUserDeleteUserMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getApiWorkspaceUserListPendingUsers = (
    params?: GetApiWorkspaceUserListPendingUsersParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<WorkspacePendingUser[]>> => {
    
    return axios.get(
      `/api/WorkspaceUser/ListPendingUsers`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetApiWorkspaceUserListPendingUsersQueryKey = (params?: GetApiWorkspaceUserListPendingUsersParams,) => {
    return [`/api/WorkspaceUser/ListPendingUsers`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiWorkspaceUserListPendingUsersQueryOptions = <TData = Awaited<ReturnType<typeof getApiWorkspaceUserListPendingUsers>>, TError = AxiosError<unknown>>(params?: GetApiWorkspaceUserListPendingUsersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiWorkspaceUserListPendingUsers>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiWorkspaceUserListPendingUsersQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiWorkspaceUserListPendingUsers>>> = ({ signal }) => getApiWorkspaceUserListPendingUsers(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiWorkspaceUserListPendingUsers>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiWorkspaceUserListPendingUsersQueryResult = NonNullable<Awaited<ReturnType<typeof getApiWorkspaceUserListPendingUsers>>>
export type GetApiWorkspaceUserListPendingUsersQueryError = AxiosError<unknown>



export function useGetApiWorkspaceUserListPendingUsers<TData = Awaited<ReturnType<typeof getApiWorkspaceUserListPendingUsers>>, TError = AxiosError<unknown>>(
 params?: GetApiWorkspaceUserListPendingUsersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiWorkspaceUserListPendingUsers>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiWorkspaceUserListPendingUsersQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const postApiWorkspaceUserProcessPendingUser = (
    params?: PostApiWorkspaceUserProcessPendingUserParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `/api/WorkspaceUser/ProcessPendingUser`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getPostApiWorkspaceUserProcessPendingUserMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiWorkspaceUserProcessPendingUser>>, TError,{params?: PostApiWorkspaceUserProcessPendingUserParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiWorkspaceUserProcessPendingUser>>, TError,{params?: PostApiWorkspaceUserProcessPendingUserParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiWorkspaceUserProcessPendingUser>>, {params?: PostApiWorkspaceUserProcessPendingUserParams}> = (props) => {
          const {params} = props ?? {};

          return  postApiWorkspaceUserProcessPendingUser(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiWorkspaceUserProcessPendingUserMutationResult = NonNullable<Awaited<ReturnType<typeof postApiWorkspaceUserProcessPendingUser>>>
    
    export type PostApiWorkspaceUserProcessPendingUserMutationError = AxiosError<unknown>

    export const usePostApiWorkspaceUserProcessPendingUser = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiWorkspaceUserProcessPendingUser>>, TError,{params?: PostApiWorkspaceUserProcessPendingUserParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiWorkspaceUserProcessPendingUser>>,
        TError,
        {params?: PostApiWorkspaceUserProcessPendingUserParams},
        TContext
      > => {

      const mutationOptions = getPostApiWorkspaceUserProcessPendingUserMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiWorkspaceUserRequestPendingUser = (
    params?: PostApiWorkspaceUserRequestPendingUserParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `/api/WorkspaceUser/RequestPendingUser`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getPostApiWorkspaceUserRequestPendingUserMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiWorkspaceUserRequestPendingUser>>, TError,{params?: PostApiWorkspaceUserRequestPendingUserParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiWorkspaceUserRequestPendingUser>>, TError,{params?: PostApiWorkspaceUserRequestPendingUserParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiWorkspaceUserRequestPendingUser>>, {params?: PostApiWorkspaceUserRequestPendingUserParams}> = (props) => {
          const {params} = props ?? {};

          return  postApiWorkspaceUserRequestPendingUser(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiWorkspaceUserRequestPendingUserMutationResult = NonNullable<Awaited<ReturnType<typeof postApiWorkspaceUserRequestPendingUser>>>
    
    export type PostApiWorkspaceUserRequestPendingUserMutationError = AxiosError<unknown>

    export const usePostApiWorkspaceUserRequestPendingUser = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiWorkspaceUserRequestPendingUser>>, TError,{params?: PostApiWorkspaceUserRequestPendingUserParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiWorkspaceUserRequestPendingUser>>,
        TError,
        {params?: PostApiWorkspaceUserRequestPendingUserParams},
        TContext
      > => {

      const mutationOptions = getPostApiWorkspaceUserRequestPendingUserMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getApiWorkspaceUserCheckPendingUser = (
    params?: GetApiWorkspaceUserCheckPendingUserParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<boolean>> => {
    
    return axios.get(
      `/api/WorkspaceUser/CheckPendingUser`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetApiWorkspaceUserCheckPendingUserQueryKey = (params?: GetApiWorkspaceUserCheckPendingUserParams,) => {
    return [`/api/WorkspaceUser/CheckPendingUser`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiWorkspaceUserCheckPendingUserQueryOptions = <TData = Awaited<ReturnType<typeof getApiWorkspaceUserCheckPendingUser>>, TError = AxiosError<unknown>>(params?: GetApiWorkspaceUserCheckPendingUserParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiWorkspaceUserCheckPendingUser>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiWorkspaceUserCheckPendingUserQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiWorkspaceUserCheckPendingUser>>> = ({ signal }) => getApiWorkspaceUserCheckPendingUser(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiWorkspaceUserCheckPendingUser>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiWorkspaceUserCheckPendingUserQueryResult = NonNullable<Awaited<ReturnType<typeof getApiWorkspaceUserCheckPendingUser>>>
export type GetApiWorkspaceUserCheckPendingUserQueryError = AxiosError<unknown>



export function useGetApiWorkspaceUserCheckPendingUser<TData = Awaited<ReturnType<typeof getApiWorkspaceUserCheckPendingUser>>, TError = AxiosError<unknown>>(
 params?: GetApiWorkspaceUserCheckPendingUserParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiWorkspaceUserCheckPendingUser>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiWorkspaceUserCheckPendingUserQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




