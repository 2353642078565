import dayjs from "dayjs";
import { useDeleteApiLinePayPaymentCancel, useGetApiLinePayPaymentList, usePostApiCreditKeyLinePayConfirm } from "@/API"
import { Button, Text } from "@mantine/core"
import { DataTable } from "mantine-datatable"
import { notifications } from "@mantine/notifications";
import { useQueryClient } from "@tanstack/react-query";
import { modals } from "@mantine/modals";
import { useState } from "react";

export function PaymentManager() {
    const queryClient = useQueryClient()
    const payments = useGetApiLinePayPaymentList()
    const paymentCancel_Mutation = useDeleteApiLinePayPaymentCancel()
    const paymentConfirm_Mutation = usePostApiCreditKeyLinePayConfirm()
    const [comfirmPaymentLoading, setComfirmPaymentLoading] = useState(false)
    return <>
        <Button loading={comfirmPaymentLoading} variant="gradient" gradient={{ from: 'blue', to: 'cyan', deg: 90 }} onClick={async () => {
            setComfirmPaymentLoading(true)
            try {
                await paymentConfirm_Mutation.mutateAsync()
                queryClient.invalidateQueries({ queryKey: payments.queryKey })
            } catch (ex) {
                notifications.show({ message: "確認訂單失敗", color: 'red' })
            }
            setComfirmPaymentLoading(false)
        }}>
            確認訂單
        </Button>
        {!payments.data?.data || payments.data.data.length == 0 ? "目前尚無資料" :
            <DataTable
                withTableBorder
                columns={[
                    {
                        accessor: "id", width: 150, title: "ID"
                    },
                    {
                        accessor: "name", width: 100, title: "名稱", render: (data) => (data.packages?.at(0)?.name),
                    },
                    {
                        accessor: "amount", width: 80, title: "價格", render: (data) => (`${data.amount}元`),
                    },
                    {
                        accessor: "timestamp", width: 150, title: "創建時間", render: (data) => (new Date(Date.parse(data.timestamp!)).toLocaleString()),
                    },
                    {
                        accessor: "status", width: 150, title: "訂單狀態", render: (data) => {
                            switch (data.status) {
                                case "created":
                                    return <>
                                        未付款
                                        {dayjs().diff(dayjs(data.timestamp!), "minutes") < 20 ?
                                            <Button size="compact-xs" variant="outline" ml={5} onClick={() => {
                                                if (data.paymentUrl?.web)
                                                    window.open(data.paymentUrl?.web, '_blank')?.focus();
                                            }}>付款</Button> :
                                            <Button size="compact-xs" variant="outline" ml={5} onClick={async () => {
                                                modals.openConfirmModal({
                                                    title: '取消訂單',
                                                    children: (
                                                        <Text size="sm">
                                                            是否要取消訂單?
                                                        </Text>
                                                    ),
                                                    labels: { confirm: '取消訂單', cancel: '離開' },
                                                    confirmProps: { color: 'red' },
                                                    onConfirm: async () => {
                                                        try {
                                                            await paymentCancel_Mutation.mutateAsync({ params: { id: data.id! } })
                                                            queryClient.invalidateQueries({ queryKey: payments.queryKey })
                                                        }
                                                        catch (e) {
                                                            notifications.show({ message: "訂單取消失敗", color: "red" })
                                                        }
                                                    },
                                                });

                                            }}>取消</Button>}

                                    </>
                                case "canceled":
                                    return "交易取消"
                                case "completed":
                                    return "交易完成"
                                case "refund":
                                    return "已退款"
                            }
                            return "錯誤"
                        }
                    }

                ]}
                records={payments?.data?.data}
            />
        }
    </>

}