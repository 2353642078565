export function SecondToString(second: number) {
    return second < 3600 ? new Date(second * 1000).toISOString().slice(14, 19) : new Date(second * 1000).toISOString().slice(11, 19)
}

export function BytesToString(bytes: number): string {
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB'];
    let index = 0;

    while (bytes >= 1024 && index < units.length - 1) {
        bytes /= 1024;
        index++;
    }

    return `${bytes.toFixed(2)} ${units[index]}`;
}