import { Text, Box, Breadcrumbs, Flex, Tabs, ActionIcon, Group } from "@mantine/core";
import { AudioRecordItem } from "./AudioRecordItem/AudioRecordItem";
import { Allotment } from "allotment";
import "allotment/dist/style.css";
import { TextDocumentList } from "./TextDocument/TextDocumentList";
import { Link, useNavigate, useParams, useRouterState } from "@tanstack/react-router";
import { getGetApiAudioRecordGetQueryKey, useGetApiAudioRecordGet, useGetApiWorkspaceGet } from "@/API";
import { useEffect, useState } from "react";
import { useAudioRecordStateStore } from "./AudioRecordStateStore";
import { useAuthStore } from "@/Stores/AuthStore";
import { UseWorkspaceAuth } from "../UseWorkspaceAuth";
import { IconEdit } from "@tabler/icons-react";
import { EditAudioRecordNameButton } from "./EditAudioRecordNameButton";

export function AudioRecordIndex() {
    const navigate = useNavigate()
    const router = useRouterState();
    const auth = useAuthStore()
    const { workspaceId, audioRecordId } = useParams({ from: "/workspace/$workspaceId/audioRecord/$audioRecordId" })
    const workspaceEditorAuth = UseWorkspaceAuth(workspaceId, "Editor")
    const workspace = useGetApiWorkspaceGet({ id: workspaceId })
    const audioRecord = useGetApiAudioRecordGet({ workspaceId: workspaceId, audioRecordId: audioRecordId })
    const audioRecordState = useAudioRecordStateStore()
    const [activeTab, setActiveTab] = useState<string | null>('AudioRecord');

    useEffect(() => {
        if (audioRecordState.targetTime >= 0) {
            setActiveTab("AudioRecord")
        }
    }, [audioRecordState.targetTime])

    useEffect(() => {
        if (audioRecord.status == "error" && audioRecord.error.status == 401) {
            navigate({ to: "/", search: { redirect: router.location.pathname } })
        }
    }, [audioRecord.status])

    return <Flex h="100%" direction="column">
        <Group align="center" gap={5}>
            <Breadcrumbs mb={5} className="no-underline">
                {auth.data.userName &&
                    <Link to="/workspace">
                        <Text c="gray">
                            工作空間列表
                        </Text>
                    </Link>
                }
                {workspace.data &&
                    <Link to="/workspace/$workspaceId" params={{ workspaceId: workspaceId }} >
                        <Text c="gray">
                            {workspace.data?.data.name}
                        </Text>
                    </Link>
                }
                <Text fw={500} c="var(--mantine-primary-color-filled)">
                    {audioRecord.data?.data.name}
                </Text>
            </Breadcrumbs>
            {workspaceEditorAuth && audioRecord.data?.data && <EditAudioRecordNameButton audioRecord={audioRecord.data?.data}>
                <ActionIcon variant="subtle" aria-label="Settings">
                    <IconEdit style={{ width: '70%', height: '70%' }} stroke={1.5} />
                </ActionIcon>
            </EditAudioRecordNameButton>
            }
        </Group>
        <Box h="100%" visibleFrom="sm">
            <Allotment >
                <Box w="100%" h="100%" pr={10}>
                    <AudioRecordItem />
                </Box>
                <TextDocumentList />
            </Allotment>
        </Box>
        <Box h="100%" hiddenFrom="sm">
            <Tabs h="calc(100% - 40px)" variant="outline" defaultValue="AudioRecord" value={activeTab} onChange={setActiveTab}>
                <Tabs.List mb={5}>
                    <Tabs.Tab value="AudioRecord">錄音</Tabs.Tab>
                    <Tabs.Tab value="TextDocument">文件</Tabs.Tab>
                </Tabs.List>
                <Tabs.Panel w="100%" h="100%" value="AudioRecord">
                    <AudioRecordItem />
                </Tabs.Panel>
                <Tabs.Panel value="TextDocument">
                    <TextDocumentList />
                </Tabs.Panel>
            </Tabs>
        </Box>
    </Flex>
}