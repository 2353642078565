import * as R from "remeda";
import { AudioRecordEntry } from "@/API";

export function MergeAudioRecordEntry(source: AudioRecordEntry, target: AudioRecordEntry) {
    var result = R.clone(source)

    result.text += "\n" + target.text;
    if (!result.translatedTexts) result.translatedTexts = []
    R.forEach(target.translatedTexts ?? [], text => {
        var i = result.translatedTexts?.findIndex(x => x.language == text.language)
        if (i != undefined && i >= 0)
            result.translatedTexts![i].text += "\n" + text.text
        else
            result.translatedTexts?.push(text)
    })
    result.speakers = R.unique((result.speakers ?? []).concat(target.speakers ?? []))
    if (!result.duration) result.duration = 0

    var endTime = Math.max((result.time ?? 0) + (result.duration ?? 0), (target.time ?? 0) + (target.duration ?? 0))
    result.time = Math.min(result.time ?? 0, target.time ?? 0)
    result.duration = endTime - result.time
    return result;
}
