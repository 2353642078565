import { Center, Text, Image } from "@mantine/core";
import Icon from "Images/icon.svg"
import { useGetLicense } from "./API";
export function Index() {
  const license = useGetLicense()
  return (
    <>
      <Center>
        {/* <Text fw={700} style={{ fontSize: "60px" }} variant="gradient" gradient={{ from: 'blue', to: 'cyan', deg: 90 }}>
          快可錄
        </Text> */}
        <Image
          radius="md"
          w={500}
          src={Icon}
        />
      </Center>
      <Center>
        <Text fw={700} style={{ fontSize: "25px" }} variant="gradient" gradient={{ from: 'blue', to: 'cyan', deg: 90 }}>
          {license.data?.data.subject}
          {/* Tempest Digital Co., Ltd. */}
        </Text>
      </Center>
    </>
  )
}