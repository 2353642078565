import { AuthState } from '@/API'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

export interface AuthStoreState {
    data: AuthState
    isUpdating: boolean
    SetUpdating: (value: boolean) => void
    Update: (data: AuthState) => void
    Reset: () => void
}
export const useAuthStore = create<AuthStoreState>()(
    devtools(
        immer((set) => ({
            data: {},
            isUpdating: false,
            SetUpdating: (value: boolean) => set((state) => {
                state.isUpdating = value
            }),
            Update: (data: AuthState) =>
                set((state) => {
                    state.data = data
                    state.isUpdating = false
                }),
            Reset: () =>
                set(state => {
                    state.data = {}
                }),
        }))),
)