import { getGetApiAudioRecordTextDocumentImageListQueryKey, putApiAudioRecordTextDocumentImageUpload, useDeleteApiAudioRecordTextDocumentImageDelete, useGetApiAudioRecordTextDocumentImageList } from "@/API";
import { SimpleGrid, Image, Box, Pagination, ActionIcon, Text, Button, Center, Stack, FileButton, rem, Group } from "@mantine/core";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconPhotoOff, IconPhotoPlus, IconTrash, IconX } from "@tabler/icons-react";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { GetTextDocumentImageUrl } from "@/APIURL";
import { v4 as uuidv4 } from 'uuid';

export function TextDocumentImageBox(props: { workspaceId: string, audioRecordId: string, onClickFile?: (fileName: string) => void }) {
    const queryClient = useQueryClient()
    const images = useGetApiAudioRecordTextDocumentImageList({ workspaceId: props.workspaceId, audioRecordId: props.audioRecordId })
    const delete_Mutation = useDeleteApiAudioRecordTextDocumentImageDelete()
    const pageSize = 12
    const [activePage, setPage] = useState(1);
    return <>
        <Pagination mb={10} value={activePage} onChange={setPage} flex={1} total={Math.ceil((images.data?.data.length ?? 0) / pageSize)} />
        <FileButton onChange={async imageFile => {
            const id = notifications.show({
                loading: true,
                title: '上傳圖片',
                message: '正在上傳圖片...',
                autoClose: false,
                withCloseButton: false,
            });
            try {
                if (imageFile) {
                    var file = new File([imageFile], uuidv4() + "." + imageFile.name.split('.').pop(), { type: imageFile.type })
                    await putApiAudioRecordTextDocumentImageUpload({ imageFile: file }, { workspaceId: props.workspaceId, audioRecordId: props.audioRecordId })
                    queryClient.invalidateQueries({
                        queryKey: images.queryKey
                    })
                    notifications.update({
                        id,
                        color: 'teal',
                        title: '上傳圖片',
                        message: '上傳圖片成功',
                        icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
                        loading: false,
                        autoClose: 2000,
                    });
                }
            }
            catch (e) {
                notifications.update({
                    id,
                    color: 'red',
                    title: '上傳圖片',
                    message: '上傳圖片失敗',
                    icon: <IconX style={{ width: rem(18), height: rem(18) }} />,
                    loading: false,
                    autoClose: 2000,
                });
            }
        }} accept="image/png,image/jpeg">
            {(props) => <Button {...props} >上傳圖片</Button>}
        </FileButton>

        {images.data?.data && images.data?.data.length > 0 ?
            <SimpleGrid mt={10} cols={4}>
                {images.data?.data.slice((activePage - 1) * pageSize, activePage * pageSize,).map(d => {
                    var url = GetTextDocumentImageUrl(props.workspaceId, props.audioRecordId, d)
                    return <Box pos="relative" w="100px" h="100px">
                        <ActionIcon variant="light" color="red" pos="absolute" top={0} right={0} onClick={() => {
                            modals.openConfirmModal({
                                title: '刪除圖片',
                                centered: true,
                                children: (
                                    <>
                                        <Text size="sm">
                                            是否要刪除圖片?
                                        </Text>
                                        <Image
                                            w="400px"
                                            h="400px"
                                            fit="contain"
                                            radius="md"
                                            src={url}
                                        />
                                    </>
                                ),
                                labels: { confirm: '確定刪除', cancel: "取消" },
                                confirmProps: { color: 'red' },
                                onConfirm: async () => {
                                    try {
                                        await delete_Mutation.mutateAsync({ params: { workspaceId: props.workspaceId, audioRecordId: props.audioRecordId, fileName: d } })
                                        queryClient.invalidateQueries({ queryKey: images.queryKey })
                                        notifications.show({ message: "刪除成功" })
                                    } catch {
                                        notifications.show({ message: "刪除失敗", color: "red" })
                                    }
                                },
                            });

                        }} >
                            <IconTrash style={{ width: '70%', height: '70%' }} stroke={1.5} />
                        </ActionIcon>
                        <Image
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                props.onClickFile?.(d)
                            }}
                            w="100px"
                            h="100px"
                            fit="contain"
                            radius="md"
                            src={url}
                        />
                    </Box>
                })}
            </SimpleGrid> : <Center><Stack gap={0} align="center"><IconPhotoOff size="50px" color="gray" /><Text c="gray">暫無圖片</Text></Stack></Center>}
    </>
}