import { AudioRecord, AudioRecordEntry, getGetApiAudioRecordGetQueryKey, usePutApiAudioRecordUpdate } from "@/API"
import { languageCodeData } from "@/Helper/languageCodeData"
import { Button, ButtonProps, Text, ComboboxItem, Group, Modal, Select, SelectProps, TextInput, ActionIcon, ActionIconProps, rem } from "@mantine/core"
import { useForm } from "@mantine/form"
import { useDisclosure } from "@mantine/hooks"
import { modals } from "@mantine/modals"
import { notifications } from "@mantine/notifications"
import { IconTrash } from "@tabler/icons-react"
import { useQueryClient } from "@tanstack/react-query"
import { useMemo, useState } from "react"
import * as R from "remeda"

export function TranslatedLanguageSelect(props: { selectProps?: SelectProps, deleteButtonProps?: ActionIconProps, audioRecord: AudioRecord, onSelectLanguage?: (language: string | null) => void }) {
    const queryClient = useQueryClient()
    const update_Mutation = usePutApiAudioRecordUpdate()
    const [language, setLanguage] = useState<string | null>(null)
    const [deleteLoading, setDeleteLoading] = useState(false)
    var translatedTexts: ComboboxItem[] = useMemo(() => {
        var data = R.uniqueBy(R.flatMap(props.audioRecord.recordEntries ?? [], x => x.translatedTexts).map<ComboboxItem>(x => {
            return {
                label: languageCodeData[x?.language!]?.name ?? "",
                value: x?.language!
            }
        }), x => x.value).filter(x => !!x.value)
        data = data.sort((a, b) => {
            var a1 = Object.keys(languageCodeData).findIndex(x => x == a.value) ?? -1
            var b1 = Object.keys(languageCodeData).findIndex(x => x == b.value) ?? -1
            return a1 - b1
        })
        return data;
    }, [props.audioRecord])
    return <>
        <Select {...props.selectProps} clearable variant="filled" data={translatedTexts} value={language} onChange={x => {
            setLanguage(x)
            props.onSelectLanguage?.(x)
        }} />
        {language &&
            <ActionIcon {...props.deleteButtonProps} loading={deleteLoading} variant="subtle" size="sm" color="red" onClick={() => {
                modals.openConfirmModal({
                    title: '刪除翻譯',
                    centered: true,
                    children: (
                        <Text size="sm">
                            是否刪除"{languageCodeData[language!]?.name}"翻譯
                        </Text>
                    ),
                    labels: { confirm: '刪除', cancel: "取消" },
                    confirmProps: { color: 'red' },
                    onConfirm: async () => {
                        setDeleteLoading(true)
                        try {
                            var record = R.clone(props.audioRecord)
                            record.recordEntries?.forEach(x => {
                                x.translatedTexts = x.translatedTexts?.filter(x => x.language != language)
                            })
                            await update_Mutation.mutateAsync({
                                params: {
                                    workspaceId: props.audioRecord.workspaceId!,
                                }, data: record
                            })
                            queryClient.invalidateQueries({ queryKey: getGetApiAudioRecordGetQueryKey({ workspaceId: record.workspaceId!, audioRecordId: record.id! }) })
                            notifications.show({ message: "刪除成功" })
                            setLanguage("")
                        } catch (e) {
                            notifications.show({ message: "刪除失敗", color: "red" })
                        }
                        setDeleteLoading(false)
                    },
                });
            }}>
                <IconTrash />
            </ActionIcon>
        }
    </>
}