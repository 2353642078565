import * as R from "remeda";
import { AudioRecord, getApiAudioRecordGet, getGetApiAudioRecordGetQueryKey, getGetApiAudioRecordListQueryKey, usePutApiAudioRecordUpdate } from '@/API';
import { Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { PropsWithChildren, useState } from 'react';
import { notifications } from "@mantine/notifications";
import { useQueryClient } from "@tanstack/react-query";
import { EditAudioRecordNameForm } from "../Workspace/EditAudioRecordNameForm";

export function EditAudioRecordNameButton({ audioRecord, children }: { audioRecord: AudioRecord } & PropsWithChildren) {
    const queryClient = useQueryClient()
    const [editName_opened, { open: editName_open, close: editName_close }] = useDisclosure(false);
    const [updateAudioRecordLoading, setUpdateAudioRecordLoading] = useState(false)
    const updateAudioRecord_Mutation = usePutApiAudioRecordUpdate()

    return <>
        <div onClick={editName_open}>
            {children}
        </div>
        <Modal title="修改名稱" opened={editName_opened} onClose={editName_close}>
            <EditAudioRecordNameForm loading={updateAudioRecordLoading} initValue={audioRecord.name ?? ""} onSubmit={async v => {
                editName_close()
                setUpdateAudioRecordLoading(true)
                try {
                    if (audioRecord.workspaceId && audioRecord.id) {
                        var record = await getApiAudioRecordGet({ workspaceId: audioRecord.workspaceId, audioRecordId: audioRecord.id })
                        var data = R.clone(record.data)
                        data.name = v
                        if (data.workspaceId) {
                            try {
                                await updateAudioRecord_Mutation.mutateAsync({ params: { workspaceId: data.workspaceId }, data: data })
                                notifications.show({ message: "修改成功" })
                                queryClient.invalidateQueries({ queryKey: getGetApiAudioRecordListQueryKey() })
                                queryClient.invalidateQueries({ queryKey: getGetApiAudioRecordGetQueryKey({ workspaceId: audioRecord.workspaceId, audioRecordId: audioRecord.id }) })
                            } catch (e) {
                                notifications.show({ message: "修改失敗", color: "red" })
                            }
                        }
                    }
                }
                catch (e) {

                }
                setUpdateAudioRecordLoading(false)
            }} />
        </Modal>
    </>
}