import { postApiAdminAuthLogin } from "@/API";
import { useAuthStore } from "@/Stores/AuthStore";
import { Button, Group, PasswordInput, Stack, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { useNavigate } from "@tanstack/react-router";

export function AdminLoginPage() {
    const navigate = useNavigate()
    const auth = useAuthStore()
    const form = useForm({
        initialValues: {
            userName: '',
            password: ''
        },
        validate: {
            // password: (val) => (val.length <= 6 ? 'Password should include at least 6 characters' : null),
        },
    });

    return (
        <>
            <form onSubmit={form.onSubmit(async (x) => {
                auth.SetUpdating(true)
                try {
                    var result = await postApiAdminAuthLogin(x)
                    if (result.data.userName && result.data.role) {
                        auth.Update(result.data)
                        notifications.show({
                            message: '登入成功',
                        })
                        navigate({ to: "/" })
                    }
                } catch (e) {
                    notifications.show({
                        message: '登入失敗', color: "red"
                    })
                }
                auth.SetUpdating(false)
            })}>
                <Stack>
                    <TextInput
                        required
                        label="管理者名稱"
                        placeholder="Your username"
                        value={form.values.userName}
                        onChange={(event) => form.setFieldValue('userName', event.currentTarget.value)}
                        radius="md"
                    />

                    <PasswordInput
                        required
                        label="管理者密碼"
                        placeholder="Your password"
                        value={form.values.password}
                        onChange={(event) => form.setFieldValue('password', event.currentTarget.value)}
                        error={form.errors.password && 'Password should include at least 6 characters'}
                        radius="md"
                    />
                </Stack>

                <Group justify="space-between" mt="xl">
                    <Button type="submit" radius="xl">
                        登入
                    </Button>
                </Group>
            </form>
        </>
    );
}